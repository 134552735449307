import phoneImage1 from '../../../assets/images/phone-1.png';
import phoneImage2 from '../../../assets/images/phone-2.png';
import huckleImage from '../../../assets/images/huckle.png';
import foxAnvilImage from '../../../assets/images/fox_anvil.png';
import { Heading, Button } from '@rabbit/elements/shared-components';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAME from 'apps/olive/src/utils/url-constants';

export function WhyCompleteHoldingInfoCTA({
  id,
  isSRV,
}: {
  id: string;
  isSRV: boolean;
}) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full px-4 pt-10 pb-8 gap-12 rounded-t-2xl bg-gray-50">
      <Heading kind="h2" className="mx-auto">
        Why should you complete your product’s information?
      </Heading>
      <div className="grid grid-cols-1 gap-[10px] md:grid-cols-3 ">
        <ImageCard
          imgSrc={phoneImage1}
          imgAlt="iwarranty app on product information screen"
        >
          <div className="flex flex-col gap-4">
            <img
              src={huckleImage}
              alt="huckle on a skateboard"
              className="w-[80px]"
            />
            <Heading kind="h4">Peace of mind</Heading>
            <p className="font-nunito text-[14px] text-gray-500">
              Upload your products and keep track of the details, warranties and
              receipts in one place. Accessible anywhere, anytime.
            </p>
          </div>
        </ImageCard>
        <ImageCard
          imgSrc={phoneImage2}
          imgAlt="iwarranty app on product information screen"
        >
          <div className="flex flex-col gap-4">
            <Heading kind="h4">Warranties at your fingertips</Heading>
            <p className="font-nunito text-[14px] text-gray-500">
              Get the important parts summarised, so you can focus on more
              important things.
            </p>
          </div>
        </ImageCard>
        <ImageCard
          imgSrc={phoneImage1}
          imgAlt="iwarranty app on product information screen"
        >
          <div className="flex flex-col gap-4">
            <Heading kind="h4">Product care made simple</Heading>
            <p className="font-nunito text-[14px] text-gray-500">
              In-warranty claims and out-of-warranty repairs are only a few taps
              away.
            </p>
            <img
              src={foxAnvilImage}
              alt="fox working on anvil"
              className="ml-auto h-[80px]"
            />
          </div>
        </ImageCard>
      </div>
    </div>
  );
}

function ImageCard({
  children,
  imgSrc,
  imgAlt,
}: {
  children: any;
  imgSrc: string;
  imgAlt: string;
}) {
  return (
    <div className="flex items-center p-4 bg-white rounded-lg shadow-md gap-6">
      <div className="">{children}</div>
      <img
        src={imgSrc}
        alt={imgAlt}
        className="h-full max-w-[116px] object-contain"
      />
    </div>
  );
}
