import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import { useConsumerHoldingEditor } from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { Money, VehicleInfo } from '@rabbit/data/types';
import {
  formatUnixTime,
  getCurrencyFormat,
  Button,
  Modal,
} from '@rabbit/elements/shared-components';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import Skeleton from 'react-loading-skeleton';
import carImagePlaceholder from '../../../assets/images/car-placeholder.jpg';
import { OliveFileUploader } from '../../organisms/upload-wrapper/OliveFileUploader';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getUnixTime } from 'date-fns';

interface RecordType {
  label: string;
  value: string;
  ValueElement?: JSX.Element;
}
export default function CarHoldingDetailsSection({
  holding,
}: {
  holding: ConsumerHoldingSummaryShape;
}) {
  const { t } = useTranslation();
  const productInfo: VehicleInfo | undefined = holding?.srvInfo
    ?.productInfo as VehicleInfo;
  const purchasePrice = holding?.purchase_price as Money;
  const { personaId } = useMyConsumerPersona();

  const [proofOfPurchaseModal, setProofOfPurchaseModal] =
    useState<boolean>(false);
  const { body } = useConsumerHoldingEditor(
    personaId || '',
    holding.holdingId.split('_')[0]
  );

  const warrantyInfo = BL_Warranty.getLatestWarranty(holding.warranties);

  const primaryInfo: RecordType[] = [
    { label: t('general.make'), value: productInfo?.brand || '-' },
    { label: t('general.model'), value: productInfo?.model || '-' },
    { label: t('general.version'), value: productInfo?.version || '-' },
    {
      label: t('general.purchaseDate'),
      value: holding.purchase_time
        ? formatUnixTime(holding.purchase_time, 'dd/MM/yyyy')
        : '-',
    },
    {
      label: t('CFG_DEFAULT_COUNTRY') === 'GB' ? 'MOT date' : 'NCT date',
      value: productInfo?.techCheckDate || '-',
    },
    { label: t('general.vin'), value: productInfo?.chassisNo || '-' },
    {
      label: t('general.store'),
      value: warrantyInfo?.retailerName || '-',
    },
    ...(productInfo?.cherishedRegistrationNo
      ? [{ label: t('general.registrationNo'), value: productInfo?.registrationNo || '-' }]
      : []),
  ];

  const secondaryInfo: RecordType[] = [
    {
      label: t('general.purchaseCountry'),
      value: holding.purchase_country || holding.purchase_location_other || '-',
    },
    {
      label: t('general.amountPaid'),
      value: purchasePrice?.amount
        ? getCurrencyFormat(purchasePrice.amount, purchasePrice.currency)
        : '-',
    },
    // {
    //   label: 'Proof of purchase',
    //   value: '',
    //   ValueElement: (
    //     <RenderPoP
    //       onClick={() => setProofOfPurchaseModal(true)}
    //       receipt={body?.private?.receipt}
    //     />
    //   ),
    // },
  ];

  return (
    <div className="flex w-full flex-col rounded-md bg-gray-50">
      {!holding.holdingId ? (
        <RenderLoadingState />
      ) : (
        <>
          <div className="flex w-full items-center gap-4 bg-gray-50 p-4">
            <div className="h-[84px] w-[84px]">
              <img
                src={holding?.img || carImagePlaceholder}
                alt="registered car"
                className="h-full w-full object-contain"
              />
            </div>
            <div className="font-nunito flex flex-col gap-1">
              <div className="font-bold">
                {productInfo?.brand} {productInfo?.model} {productInfo?.version}{' '}
                {productInfo?.yearOfManufacture}
              </div>
              <p className="text-gray-400">{BL_Warranty.getVehicleRegistrationNo(productInfo)}</p>
            </div>
          </div>
          <div className="p-4">
            <div className="grid grid-cols-2 gap-4 md:grid md:grid-cols-6">
              {primaryInfo.map((info) => renderLabelValue(info))}
              {secondaryInfo.map((info) => renderLabelValue(info))}
            </div>
          </div>
        </>
      )}
      {proofOfPurchaseModal && (
        <Modal
          kind="generic"
          settings={{
            title: 'Proof of purchase',
            handleClose: () => setProofOfPurchaseModal(false),
          }}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <OliveFileUploader
              label="Proof of purchase"
              identifiers={{
                category: UploadedFileCategories.ConsumerProofPurchase,
                docType: {
                  docid: holding.holdingId.split('_')[0],
                  type: DocTypeShapeTypes.Case,
                },
                personaId: personaId ?? '',
              }}
              shouldAutoUpdateDocs={true}
              //alterCaseFacts={alterCaseFacts} //No need - it's working without it
              accepts={['image/*', '.pdf']}
              //onUploadCompleted={onProofOfPurchaseUpdated} //No need - it's working without it
              currentFiles={body?.private?.receipt ?? []}
            />
            <div className="mt-4 flex w-full gap-2">
              <Button
                kind="primary"
                onClick={() => setProofOfPurchaseModal(false)}
              >
                OK
              </Button>
              <Button kind="red" onClick={() => setProofOfPurchaseModal(false)}>
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

const renderLabelValue = (info: RecordType) => (
  <div className="font-nunito flex flex-col gap-1 ">
    <div className="text-sm text-gray-500">{info.label}</div>
    {!info.ValueElement && (
      <div className="overflow-hidden text-ellipsis text-base text-black">
        {info.value}
      </div>
    )}
    {info.ValueElement && info.ValueElement}
  </div>
);

const RenderLoadingState = () => (
  <div className="flex flex-col gap-6 p-5">
    <div className="flex w-full grow">
      <div>
        <div className="h-[90px] w-[90px]">
          {' '}
          <Skeleton
            count={1}
            baseColor="#fff"
            highlightColor="#f1f1f1"
            className="h-[90px] w-[90px]"
          />
        </div>
      </div>
      <div className="ml-5 flex w-full flex-col items-start justify-start">
        <div className="w-full">
          <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
        </div>
      </div>
    </div>
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
  </div>
);
