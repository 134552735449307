import {
  Heading,
  SearchImage,
  ShowMore,
} from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../../utils/url-constants';
import { DTVendable } from '@rabbit/data/types';
import { useNavigate } from 'react-router-dom';

export interface SliderProps {
  title: string;
  actionButtonText: string;
  key?: string;
  data?: DTVendable[];
  onClick: (value: string) => void;
  onItemSelect?: (id: string, data: any) => void;
}

export function SearchSlider({
  title,
  actionButtonText,
  data,
  onClick,
  onItemSelect,
}: SliderProps) {
  const navigate = useNavigate();
  return data ? (
    <div className="w-full max-w-screen-xl">
      <div className="mt-[23px] flex justify-between capitalize">
        <Heading kind="h3" children={title} />
        {data.length > 5 ? (
          <p
            className="text-primary-800 cursor-pointer text-base"
            onClick={() => onClick(title)}
          >
            {actionButtonText}
          </p>
        ) : null}
      </div>
      <div className="flex gap-1 overflow-auto pb-3">
        {data &&
          data.slice(0, 5).map((item: any, index: number) => (
            <div
              key={index}
              className="font-nunito mr-[19px] mt-3 flex-col items-center justify-center gap-2 rounded-lg"
            >
              <SearchImage
                imageUrl={item.img[0]}
                size="sm"
                subtitle={item.brand}
                title={item.title}
                onClick={
                  !onItemSelect
                    ? () =>
                        navigate(`${ROUTE_NAME.PRODUCTS_PREVIEW}/${item.docid}`)
                    : () => onItemSelect(item.docid, item)
                }
              />
            </div>
          ))}
        {data.length > 5 ? (
          <div className="mr-5 mt-3 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg">
            <ShowMore
              actionText="Show More"
              onClick={() => onClick(title)}
              category={title}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
}

export default SearchSlider;
