import { Toggle } from '@rabbit/elements/shared-components';
import classNames from 'classnames';

export interface AccountListItemToggleProps {
  title: string;
  state: boolean;
  onChange: () => void;
  disabled?: boolean;
}

export function AccountListItemToggle({
  title,
  state,
  onChange,
  disabled = false,
}: AccountListItemToggleProps) {
  return (
    <div className="flex w-full items-center justify-between">
      <div className="font-nunito flex w-full items-center p-[16.5px_0px_16.5px_20px]">
        <p className="font-nunito text-base">{title}</p>
      </div>
      <div
        className={classNames('ml-[6px] flex items-center pr-[18.5px]', {
          ['pointer-events-none cursor-not-allowed opacity-20']: disabled,
        })}
      >
        <Toggle checked={state} onChange={onChange} />
      </div>
    </div>
  );
}

export default AccountListItemToggle;
