import {
  Heading,
  ProductSuggestionsItem,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';

export interface ProductSuggestionsProps {
  submitSearchCallback?: (value: string) => void;
}

export function ProductSuggestions({
  submitSearchCallback,
}: ProductSuggestionsProps) {
  const { t } = useTranslation();

  const JSONSuggestions = t('CFG_PRODUCT_SUGGESTIONS');
  const suggestions = JSONSuggestions.length ? JSONSuggestions.split(',') : [];

  return suggestions.length > 0 ? (
    <div>
      <Heading
        kind="h3"
        children="Here are some suggestions to help you get started"
      />
      <div className="mt-4 w-full rounded-lg border border-gray-200 bg-gray-50">
        {suggestions.map((item: string, index: number) => (
          <div
            key={index}
            className={`border-black ${
              index !== suggestions.length - 1 ? 'border-b-[0.3px]' : ''
            } `}
          >
            <ProductSuggestionsItem
              option={item}
              key={index}
              submitSearchCallback={submitSearchCallback}
            />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default ProductSuggestions;
