import { Dispatch, SetStateAction, useState } from "react";
import ServiceRequestAdditionalInfo from "./service-request/ServiceRequestAdditionalInfo";
import ServiceRequestSearchProduct from "./service-request/ServiceRequestAddProduct";
import ServiceRequestAddProductManually from "./service-request/ServiceRequestAddProductManually";
import ServiceRequestAuthView from "./service-request/ServiceRequestAuthView";
import ServiceRequestIssueReport from "./service-request/ServiceRequestIssueReport";
import ServiceRequestSelectProduct from "./service-request/ServiceRequestSelectProduct";

export interface SRStepProps {
  setStep: Dispatch<SetStateAction<{ current: number, data?: any }>>;
  data?: any;
}
export default function ServiceRequestView() {

  const [step, setStep] = useState<{ current: number, data?: any }>({
    current: window.location.pathname.match('start') ? 1 : 0
  });

  // Startup position
  // Continue with email / Social login
  if (step.current === 0) return <ServiceRequestAuthView setStep={setStep} />

  // Start page with select product
  if (step?.current === 1) return <ServiceRequestSelectProduct setStep={setStep} />

  // Search and select product
  if (step?.current === 1.1) return <ServiceRequestSearchProduct setStep={setStep} />

  // Add product manually
  if (step?.current === 1.2) return <ServiceRequestAddProductManually setStep={setStep} />

  // Selected product additional information
  if (step?.current === 1.3) return <ServiceRequestAdditionalInfo setStep={setStep} data={step.data} />

  // Issue report request
  if (step?.current === 2) return <ServiceRequestIssueReport setStep={setStep} data={step.data} />

  return (
    <></>
  )
}
