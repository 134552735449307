import { ReactNode } from 'react';
import {
  ChevronButton,
  Heading,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';

export interface DesktopContainerProps {
  /**
   * Callback function for the back chevron button. If none is set, button is not shown.
   */
  children: ReactNode;
  goBack?: null | (() => any);
  title?: string;
  type?: 'titleWithoutChevron';
  loading?: boolean;
  withRoundShadows?: boolean;
}

const dynamicStyles = {
  base: 'w-full lg:max-w-[536px] lg:shadow-xl lg:m-auto shrink-0 rounded-xl relative',
  titleCard:
    'w-full lg:w-[536px] lg:shadow-lg lg:shadow-xl lg:m-[82px_auto] lg:h-[722px] rounded-xl',
  roundShadows: 'lg:shadow-lg',
};

export function DesktopContainer({
  goBack,
  title,
  children,
  type,
  loading,
}: DesktopContainerProps) {
  const BackAndTitle = (
    <div className="relative mb-6 flex w-full flex-row ">
      {goBack && (
        <ChevronButton
          className="absolute flex h-full cursor-pointer flex-wrap content-center"
          kind="back"
          onClick={goBack}
        />
      )}

      {title && (
        <div className="mx-auto">
          <Heading kind="h2">{title}</Heading>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex w-full shrink-0 grow flex-col justify-center lg:content-center">
      {type === 'titleWithoutChevron' ? (
        <>
          <LoadingSpinner overlay={true} size="sm" />
          <div className="md:pt-8">{(goBack || title) && BackAndTitle}</div>
          <div className={dynamicStyles.titleCard}>
            <div className="h-full pb-52 lg:px-8">{children}</div>
          </div>{' '}
        </>
      ) : (
        <div className={dynamicStyles.base}>
          {loading && <LoadingSpinner overlay={true} size="sm" />}
          <div className="lg:px-8 lg:py-8">
            {(goBack || title) && BackAndTitle}
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

export default DesktopContainer;
