import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import {
  DesktopContainer,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import MarketingPreferencesContainer from '../../../../templates/Account/MarketingPreferencesContainer';

function MarketingPreferencesView() {
  const { consumerPersonaData, consumerPersonaId } =
    useContext(UserContext) ?? {};

  const navigate = useNavigate();

  if (
    consumerPersonaData === null ||
    !consumerPersonaData ||
    !consumerPersonaId
  )
    return <LoadingSpinner size="md" isFullScreen />;

  return (
    <DesktopContainer title="Marketing Preferences" goBack={() => navigate(-1)}>
      <MarketingPreferencesContainer
        id={consumerPersonaId}
        data={consumerPersonaData?.marketing}
      />
    </DesktopContainer>
  );
}

export default MarketingPreferencesView;
