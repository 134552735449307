import { Heading } from '@rabbit/elements/shared-components';

export interface ArticleViewProps {
  data: {
    image: string;
    title: string;
    content: any;
  };
}

export function ArticleView({ data }: ArticleViewProps) {
  return (
    <div className="flex flex-col">
      <Heading kind={'h1'} className="mb-[22px] text-center">
        {data.title}
      </Heading>
      <img
        src={data.image}
        alt={'article-img'}
        className="mx-auto max-h-[191px] max-w-[191px]"
      ></img>
      <div>{data.content}</div>
    </div>
  );
}

export default ArticleView;
