import { SetupGreaseGateway } from '@rabbit/grease/gateway';
import { GreaseCodex } from '@rabbit/grease/elements';

const BakedCodex: GreaseCodex = [
  {
    domain: 'localhost',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'hot-olive.web.app',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'launchpad-olive.web.app',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'eulaunchpad-olive.web.app',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'eudemo-olive.web.app',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_demo',
  },
  {
    domain: 'eusandbox-olive.web.app',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_shelta',
  },
];

SetupGreaseGateway(BakedCodex);
