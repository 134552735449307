import { APICallParamsBase } from '@rabbit/elements/shared-types';
import { useAPI } from './useAPI';
import {
  Auth_CheckSagePersonaExistence_WithEmail,
  CaseFlow_InitiateCase,
  Holding_Register_Olive,
  Warranty_UpdateFromCommercialStatus,
} from '@rabbit/bizproc/client';
import {
  ICF_CheckSagePersonaExistence_WithEmail,
  ICF_CommercialUseStatusWarrantyUpdate,
  ICF_RegisterCase,
  OCF_CheckSagePersonaExistence_WithEmail,
  OCF_CommercialUseStatusWarrantyUpdate,
  OCF_OliveRegisterConsumerHolding,
  OCF_RegisterCase,
  OliveRegisterConsumerHolding_Payload,
} from '@rabbit/bizproc/core';
import { z } from 'zod';

export function useOliveAPI() {
  const {
    // requestId,
    // apiVersion,
    isReady,
    callAPI,
  } = useAPI();

  // Import the functions, wrap them in callAPI, and return them

  /* -------------------------------------------------------------------------- */
  /*                    Sage persona existence check on login                   */
  /* -------------------------------------------------------------------------- */
  async function checkSagePersonaExistenceWithEmail(
    params: Omit<
      z.infer<typeof ICF_CheckSagePersonaExistence_WithEmail>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_CheckSagePersonaExistence_WithEmail>> {
    return await callAPI(Auth_CheckSagePersonaExistence_WithEmail, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                            Holding registration                            */
  /* -------------------------------------------------------------------------- */

  async function registerConsumerHolding(
    params: OliveRegisterConsumerHolding_Payload
  ): Promise<z.infer<typeof OCF_OliveRegisterConsumerHolding>> {
    return await callAPI(Holding_Register_Olive, params);
  }

  async function initiateCaseFlowCase(
    params: Omit<z.infer<typeof ICF_RegisterCase>, keyof APICallParamsBase>
  ): Promise<z.infer<typeof OCF_RegisterCase>> {
    return await callAPI(CaseFlow_InitiateCase, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                    Commercial use status warranty update                   */
  /* -------------------------------------------------------------------------- */
  async function commercialUseStatusUpdateWarranties(
    params: Omit<
      z.infer<typeof ICF_CommercialUseStatusWarrantyUpdate>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_CommercialUseStatusWarrantyUpdate>> {
    return await callAPI(Warranty_UpdateFromCommercialStatus, params);
  }

  return {
    isReady,
    // Sage persona existence check on login
    checkSagePersonaExistenceWithEmail,
    // Holding registration
    registerConsumerHolding,
    // Caseflow case creation
    initiateCaseFlowCase,
    // Commercial use status warranty generation
    commercialUseStatusUpdateWarranties,
  };
}
