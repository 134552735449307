import { useNavigate, useParams } from 'react-router-dom';
import {
  ChevronAndTitle,
  TabsGeneric,
  Chat,
  TimelineUpdates,
  Heading,
  CardRepair,
  LoadingSpinner,
  Button,
  Modal,
} from '@rabbit/elements/shared-components';
import {
  CaseFlowCaseEditingID,
  CaseFlow_Utils_AlterCaseFacts,
  CaseFlow_Utils_RegisterFileUpload,
  FileStorageContext,
  useWindowDimensions,
} from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { useCaseFlowCase } from '@rabbit/bizproc/react';
import ROUTE_NAME from '../../utils/url-constants';
import { useAppInfo } from '../../utils/helpers';
import {
  OneEachOptionalPersonaLink,
  PersonaTypeFullKeyValues,
  PersonaTypeSingleLetter,
  UserUploadedDocument,
} from '@rabbit/data/types';
import { useContext, useEffect, useState } from 'react';
import LogPostageModal from '../../components/organisms/LogPostageModal/LogPostageModal';
import { getStoneImages } from '@rabbit/sage/utils/helpers';
import { OliveFileUploader } from '@rabbit/olive/components/organisms/upload-wrapper/OliveFileUploader';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { CaseFlowStations_Fatbikes } from '@rabbit/bizproc/core';

/* eslint-disable-next-line */
export interface RepairsSingleViewProps {}

export function RepairsSingleView(props: RepairsSingleViewProps) {
  const [isDivVisible, setIsDivVisible] = useState(true);
  const { personaId } = useMyConsumerPersona();
  const { id } = useParams();
  const { moveCompletedUploadsToAttached, setShouldRefetch, uploadQueueState } =
    useContext(FileStorageContext) ?? {};

  const caseId: CaseFlowCaseEditingID = {
    type: 'cfc' as const,
    case: id ?? ('' as const),
    operating_persona: personaId ?? '',
    principal_persona: personaId ?? '', // DEV-502, consumer is representing themselves here in all actions.
    isNewCase: false,
  };
  const { caseFlowCase, isLoading } = useCaseFlowCase(caseId);

  const chatLog = caseFlowCase?.GetAllChat();
  const [lastMessage] = chatLog?.slice(-1) ?? [];
  const consumerChatLastSeen =
    !isLoading && (caseFlowCase?.GetMyChatLastSeen() ?? 0);
  const hasChatUpdate = lastMessage?.t > (consumerChatLastSeen as number);
  const caseActors = caseFlowCase?.GetActors();
  const caseSpotlight = caseFlowCase?.GetCaseSpotlight();
  const caseState = caseFlowCase?.GetCaseState();
  const caseFacts = caseFlowCase?.GetAllFacts();
  const hasActivityUpdate = caseSpotlight
    ? caseSpotlight[0]?.[0] === PersonaTypeSingleLetter.Consumer
    : false;

  const [showPostageModal, setShowPostageModal] = useState(false);
  const [showConfirmPostageModal, setShowConfirmPostageModal] = useState(false);
  const appInfo = useAppInfo();

  const sendChatMessage = async (message: string, attachment?: string) => {
    await caseFlowCase?.Alter_Chat(message, attachment ?? '');
    await caseFlowCase?.Commit();
    await caseFlowCase?.UpdateChatLastSeen(PersonaTypeFullKeyValues.consumer);
  };

  const alterCaseFacts = async (facts: { [key: string]: any }) => {
    if (caseFlowCase) await CaseFlow_Utils_AlterCaseFacts(caseFlowCase, facts);
  };

  const updateChatLastSeen = (actor: keyof OneEachOptionalPersonaLink) => {
    void caseFlowCase?.UpdateChatLastSeen(actor);
  };

  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const renderCardRepair = () => {
    return (
      <CardRepair
        id={caseId}
        showProgressDetails={true}
        getStoneImages={getStoneImages}
      />
    );
  };

  //Remove redirect from NLIC if it exists
  const NLIC_RedirectTo = window.localStorage.getItem('NLIC_RedirectTo');
  if (NLIC_RedirectTo) window.localStorage.removeItem('NLIC_RedirectTo');

  //Redirect to repairs if the user is not the consumer associated with the case
  if (personaId && caseActors?.consumer && caseActors.consumer !== personaId)
    navigate(ROUTE_NAME.REPAIRS);

  //DISPLAY THE TIMELINE FOR EACH CASEID
  if (!caseFlowCase || isLoading) return <LoadingSpinner size={'xs'} />;

  const updates = caseFlowCase.GetMergedGazette();

  const dismissActionRequired = async () => {
    caseFlowCase?.Alter_CaseSpotlight({ clear: true });
    await caseFlowCase?.Commit();
  };

  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You will overwrite the previously added log postage data. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setShowConfirmPostageModal(false),
      handlePrimaryClick: () => {
        setShowPostageModal(true);
        setShowConfirmPostageModal(false);
      },
      handleOutlineClick: () => setShowConfirmPostageModal(false),
    },
  };

  const logPostageAction = () => {
    const facts = caseFlowCase?.GetAllFacts();
    if (facts?.outbound_carrier && facts?.outbound_tracking_number) {
      setShowConfirmPostageModal(true);
    } else setShowPostageModal(true);
  };

  const renderTimeline = () => {
    return (
      <TimelineUpdates
        updates={updates}
        customer={true}
        appInfo={appInfo}
        state={'All'}
        logPostageAction={logPostageAction}
        caseFlowCase={caseFlowCase}
      />
    );
  };

  const isChatReadOnly = caseState === CaseFlowStations_Fatbikes.CLOSED;

  const renderChat = () => {
    return (
      <Chat
        messageHistory={chatLog}
        onSubmitMessage={sendChatMessage}
        activePersona={personaId}
        id={caseId}
        updateChatLastSeen={async () => updateChatLastSeen('consumer')}
        isReadOnly={isChatReadOnly}
      />
    );
  };

  const renderDismissAction = () => {
    return (
      isDivVisible &&
      hasActivityUpdate && (
        <div className="flex items-center justify-between p-4 gap-3 bg-gray-50 lg:ml-5">
          <div className="flex items-center justify-start">
            <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
            <p className="pl-2 text-base text-red-700 font-nunito">
              {caseState === 'closed' ? 'Claim closed' : 'Action required'}
            </p>
          </div>
          {dismissActionRequired && (
            <div>
              <Button
                kind="primary"
                size="sm"
                onClick={() => {
                  void dismissActionRequired();
                  setIsDivVisible(!isDivVisible);
                }}
              >
                Dismiss
              </Button>
            </div>
          )}
        </div>
      )
    );
  };

  // TODO: Files uploaded

  const renderSupportingMaterials = () => {
    if (uploadQueueState && moveCompletedUploadsToAttached && setShouldRefetch)
      return (
        <div className="-mt-4">
          <OliveFileUploader
            label="Supporting materials"
            labelClasses="font-caudex font-normal text-base md:text-xl"
            identifiers={{
              category: UploadedFileCategories.ConsumerCaseEvidence,
              docType: {
                docid: caseFacts?.consumer_holding ?? '',
                type: DocTypeShapeTypes.Case,
              },
              personaId: personaId ?? '',
            }}
            shouldAutoUpdateDocs={false}
            alterCaseFacts={alterCaseFacts}
            accepts={['image/*', '.pdf']}
            // Todo move to AutoUpdater?
            onUploadCompleted={async () =>
              CaseFlow_Utils_RegisterFileUpload(caseFlowCase, {
                uploadQueueState,
                moveCompletedUploadsToAttached,
                setShouldRefetch,
              })
            }
            currentFiles={caseFacts?.consumer_claim_evidence ?? []}
          />
        </div>
      );
  };

  const tabs = [
    {
      label: 'Status',
      children: renderCardRepair(),
    },
    {
      label: 'Activity',
      children: (
        <div className="flex-col">
          {renderDismissAction()}
          {renderTimeline()}
        </div>
      ),
    },
    {
      label: 'Chat',
      children: renderChat(),
    },
  ];

  return (
    <>
      {width < 1024 ? (
        <div className="flex flex-col">
          <ChevronAndTitle
            title={'Repair information'}
            onClick={() => navigate(-1)}
          />
          <TabsGeneric
            items={tabs}
            hasChatUpdate={hasChatUpdate}
            hasActivityUpdate={hasActivityUpdate}
          ></TabsGeneric>
        </div>
      ) : (
        <div className="flex flex-col mb-11">
          <ChevronAndTitle
            title={'Repair information'}
            onClick={() => navigate('/repairs')}
          />
          <div className="flex flex-col gap-4">
            <Heading kind="h4">Status</Heading>
            {renderCardRepair()}
          </div>
          <div className="flex my-7 gap-5">
            <div className="lg:w-2/3">
              <div className="flex items-center pb-6">
                <div className="flex items-center">
                  <Heading kind={'h4'}>Activity</Heading>
                  {renderDismissAction()}
                </div>
              </div>
              {renderTimeline()}
            </div>
            <div className="lg:w-1/3">
              <div className="flex items-center pb-6 gap-4">
                <Heading kind={'h4'}>Chat</Heading>
                {hasChatUpdate && (
                  <div className="h-[12px] w-[12px] rounded-full bg-red-500"></div>
                )}
              </div>
              {renderChat()}
            </div>
          </div>

          <div className="flex flex-col gap-4">
            {renderSupportingMaterials()}
          </div>
        </div>
      )}
      {showPostageModal && (
        <LogPostageModal handleClose={() => setShowPostageModal(false)} />
      )}
      {showConfirmPostageModal && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={confirmationModalSettings.kind}
          settings={confirmationModalSettings.settings}
        />
      )}
    </>
  );
}

export default RepairsSingleView;
