import { PlusIcon } from '@heroicons/react/24/outline';

export interface SeeMoreProductsProps {
  hasText: boolean;
  text: string;
  submitSearchCallback?: (value: string) => void;
}

const dynamicStyles = {
  container: 'flex flex-col justify-center items-center',
};

export function SeeMoreProducts({
  hasText,
  submitSearchCallback,
  text,
}: SeeMoreProductsProps) {
  return (
    <div className={dynamicStyles.container}>
      <PlusIcon
        className="h-4 w-4"
        onClick={() => submitSearchCallback && submitSearchCallback(text)}
      />
      {hasText && (
        <p className="font-nunito pb-[34.5px] text-base font-normal">
          Show more
        </p>
      )}
    </div>
  );
}

export default SeeMoreProducts;
