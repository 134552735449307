import { useEffect, useState } from 'react';
import { usePortalDocumentEditor } from '@rabbit/data/portal';
import { DR_Consumer_Private } from '@rabbit/data/portal';
import { DRDocType } from '@rabbit/firebase/react';
import { DTConsumer_Private } from '@rabbit/data/types';

/* Updates hasUsedScanner status. TODO: Review type*/
export function UserScannerStatusUpdater({ personaId }: { personaId: string }) {
  const [readyToEdit, setReadyToEdit] = useState(false);

  const { body, update, commit, isReady } = usePortalDocumentEditor(
    DR_Consumer_Private as DRDocType<DTConsumer_Private>,
    personaId
  );

  useEffect(() => {
    if (body && isReady) setReadyToEdit(true);
  }, [body, isReady]);

  useEffect(() => {
    async function updateStatus() {
      if (!body || !isReady) return;
      try {
        update({
          ...body,
          hasUsedScanner: true,
        });
        await commit();
      } catch (err) {
        console.log(err);
      }
    }

    if (isReady && body) void updateStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToEdit]);

  return null;
}
