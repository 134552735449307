import { PillTag } from '@rabbit/elements/shared-components';

export interface PillsContainerProps {
  values: string[];
  onClick: (index: number, value: string) => void;
}

export function PillsContainer({ values, onClick }: PillsContainerProps) {
  return (
    <div className="flex gap-4 overflow-auto">
      {values?.map((item: string, index: number) => (
        <PillTag item={item} key={index} index={index} onClick={onClick} />
      ))}
    </div>
  );
}

export default PillsContainer;
