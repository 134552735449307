import { Dispatch, SetStateAction, useState } from 'react';
import {
  AuthContainer,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import EmailAuth from '../templates/Auth/EmailAuth';
import EmailSent from '../templates/Auth/EmailSent';
import AuthStart from '../templates/Auth/AuthStart';
import { useAppInfo } from '../utils/helpers';

type SwitchProps = {
  currentStep: number;
  data: any;
  onChange?: any;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Switch: React.FC<SwitchProps> = ({
  currentStep,
  data,
  onChange,
  setIsLoading,
}) => {
  switch (currentStep) {
    case 1:
      return <AuthStart onChange={onChange} data={data} />;
    case 2:
      return (
        <EmailAuth
          onChange={(user: any) => onChange(3, { email: user })}
          data={data}
          setIsLoading={setIsLoading}
        />
      );
    case 3:
      return <EmailSent onChange={() => onChange(2)} data={data} />;
    default:
      return null;
  }
};

export function AuthView() {
  const [currentStep, setCurrentStep] = useState({ step: 1, data: null });
  const [isLoading, setIsLoading] = useState(false);
  const appInfo = useAppInfo();

  const onChangeStep = (step_in: number, data: any = null) => {
    setIsLoading(false);
    setCurrentStep({ ...currentStep, step: step_in, data: data });
  };

  const onGoBack = () => {
    setIsLoading(false);
    if (currentStep.step === 2 || currentStep.step === 3)
      onChangeStep(currentStep.step - 1);
    if (currentStep.step === 4) onChangeStep(2);
  };

  return (
    <AuthContainer
      title={
        currentStep.step === 2
          ? 'Welcome'
          : currentStep.step === 3
          ? 'Confirm your email'
          : ''
      }
      loading={isLoading && currentStep.step === 2}
      goBack={currentStep.step !== 1 ? () => onGoBack() : null}
      currentStep={currentStep.step}
      appInfo={appInfo}
    >
      <Switch
        currentStep={currentStep.step}
        data={currentStep.data}
        onChange={onChangeStep}
        setIsLoading={setIsLoading}
      />
    </AuthContainer>
  );
}

export default AuthView;
