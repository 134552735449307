export interface CarouselImgProps {
  src: string;
  alt: string;
}

export function CarouselImg({ src, alt }: CarouselImgProps) {
  return (
    <img
      src={src}
      className="absolute top-1/2 left-1/2 block max-h-[320px] -translate-x-1/2 -translate-y-1/2 bg-white object-contain"
      alt={alt}
      draggable="false"
    />
  );
}

export default CarouselImg;
