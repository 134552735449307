import { DesktopContainer } from '@rabbit/elements/shared-components';
import PrivacyContainer from '../../../templates/Account/PrivacyContainer';
import { useNavigate } from 'react-router-dom';

function AccountPrivacyView() {
  const navigate = useNavigate();
  return (
    <DesktopContainer title="Privacy" goBack={() => navigate(-1)}>
      <PrivacyContainer />
    </DesktopContainer>
  );
}

export default AccountPrivacyView;
