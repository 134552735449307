import {
  DesktopContainer,
  Button,
  Input,
  Heading,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useCreateSRVHolding, useFileStorage } from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { toast } from 'react-toastify';
import ROUTE_NAME from '../../../utils/url-constants';

interface FormValuesShape {
  product_name: string;
  product_brand: string;
  product_category: string;
  product_image: File | null;
}

const validationSchema = Yup.object().shape({
  product_name: Yup.string().required('Please enter a product name'),
  product_brand: Yup.string().required('Please enter a brand name'),
  product_category: Yup.string().required('Please enter a product category'),
  product_image: Yup.mixed(),
});

const initialValues: FormValuesShape = {
  product_name: '',
  product_brand: '',
  product_category: '',
  product_image: null,
};

export function AddSRVHoldingView() {
  const navigate = useNavigate();
  const [submiting, setSubmitting] = useState(false);
  const {
    uploadFiles,
    uploadedTempFiles,
    deleteUnusedTempFiles,
    deleteFile,
    uploadTaskCat,
    clearFileFromState,
    clearAllTempFilesFromState,
  } = useFileStorage();
  const { createUserSRVHolding } = useCreateSRVHolding();
  const persona = useMyConsumerPersona();
  const formikRef = useRef<FormikProps<FormValuesShape> | null>(null);
  const userHasHoldings =
    persona?.data?.consumer_private?.holdings &&
    persona?.data?.consumer_private?.holdings?.length > 0;

  useEffect(() => {
    return () => {
      (async () => await deleteUnusedTempFiles())().catch((err) =>
        console.log(err)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    try {
      if (!persona?.personaId) throw new Error('Persona not found');
      const hId = await createUserSRVHolding(values);
      toast.success('Product created successfully.');
      clearAllTempFilesFromState();
      !userHasHoldings
        ? navigate(ROUTE_NAME.PRODUCTS + '/' + hId + '/details?new-add-success')
        : navigate(ROUTE_NAME.PRODUCTS + '/' + hId + '/details');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again later');
    } finally {
      setSubmitting(false);
    }
  };

  if (!persona?.personaId) return <LoadingSpinner size="sm" isFullScreen />;

  return (
    <DesktopContainer
      title={'New product'}
      goBack={() => navigate(-1)}
      loading={submiting}
    >
      <div className="flex flex-col gap-6 pb-8">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await onSubmit(values);
          }}
          innerRef={formikRef}
        >
          {({ errors, values }) => {
            return (
              <Form className="font-nunito flex flex-col gap-4">
                <Input
                  type="picture"
                  name="product_image"
                  settings={{
                    personaId: persona?.personaId,
                    uploadHandlers: {
                      category: 'srv_images',
                      uploadFiles,
                      uploadedTempFiles,
                      deleteFile,
                      uploadTaskCat,
                      clearFileFromState,
                    },
                  }}
                />
                <Input
                  label="Product name*"
                  type="text"
                  name="product_name"
                  settings={{
                    placeholder: 'Product name',
                    hint: '*required',
                  }}
                />
                <Input
                  label="Brand*"
                  type="text"
                  name="product_brand"
                  settings={{
                    placeholder: 'Brand name',
                    hint: '*required',
                  }}
                />
                <Input
                  type="text"
                  label="Category*"
                  name="product_category"
                  settings={{
                    id: 'product_category',
                    placeholder: 'Please select an option',
                    hint: '*required',
                  }}
                />
                <Button
                  className="mt-4"
                  kind="primary"
                  type="submit"
                  loading={submiting}
                >
                  Continue
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </DesktopContainer>
  );
}

export default AddSRVHoldingView;
