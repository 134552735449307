import { LoadingSpinner } from '@rabbit/elements/shared-components';
import { UserContext } from '@rabbit/olive/context/UserContext';
import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export default function ServiceRequestRouter() {
  const contextValues = useContext(UserContext);

  if (contextValues?.isLoading || contextValues?.user === undefined) {
    return <LoadingSpinner size="lg" isFullScreen label="Loading..." />;
  }

  if (contextValues?.user === null) {
    localStorage.setItem('complete_profile_redirect_url', '/service-request');
    return <Outlet />;
  } else {
    return <Navigate to={'/service-request/start'} />;
  }
}
