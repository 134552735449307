
import { useAppInfo } from '@rabbit/olive/utils/helpers';
import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from '@rabbit/elements/shared-components';

interface ClaimInitiationWarningProps {
  onClose: () => void;
}

export function ClaimInitiationWarning({
  onClose,
}: ClaimInitiationWarningProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const tenantName = appInfo.name || '';
  const tenantContactDetails = appInfo.tenant_email_info || '';
  const formattedText = t('claim.claimInitiationWarning', {tenantName, tenantContactDetails})

  const modalSettings: ModalProps = {
    kind: 'pop-up',
    settings: {
      title: t('Claim Initiation'),
      text: formattedText,
      primaryButtonText: t('Okay'),
      handlePrimaryClick: onClose,
      handleClose: onClose,
    },
  };
  return <Modal {...modalSettings} />;
}

export default ClaimInitiationWarning;