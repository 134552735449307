import { Button, ButtonSocial } from '@rabbit/elements/shared-components';
import logoiWarranty from '../../assets/images/logo/logo-iwarranty-black.svg';
import ROUTE_NAME from '../../utils/url-constants';

export function AuthStart(props: any) {
  const { onChange } = props;

  return (
    <div className="flex flex-col gap-[72px]">
      <div className="flex flex-col gap-14">
        <img src={logoiWarranty} alt={'iwarrantyLogo'} className="h-[68px]" />
        <span className="font-nunito text-center text-lg">
          A new way to manage and care for your products and our planet{' '}
          <span role="img" aria-label="plant emoji">
            🌱
          </span>
        </span>
      </div>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-6">
          <Button kind="primary" onClick={() => onChange(2)}>
            Continue with email
          </Button>
          <ButtonSocial type="apple" label="Sign in with Apple" />
          <ButtonSocial type="google" label="Sign in with Google" />
        </div>
        <p className="font-nunito mt-3 text-center text-sm font-medium">
          By continuing, you agree to iWarranty’s{' '}
          <a
            href={ROUTE_NAME.TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a href={ROUTE_NAME.PRIVACY_POLICY} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}

export default AuthStart;
