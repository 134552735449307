import article1 from '../assets/images/article_cover_furniture.png'
import article2 from '../assets/images/article_best_materials.png'
import article3 from '../assets/images/article_revamp_outdoor.png'
import article4 from '../assets/images/article_revamp_patio.jpeg'
import rightMaterial from '../assets/images/right_materials.png'
import shades from '../assets/images/umbrellas_for_shade.png'
import article7 from '../assets/images/article_protect_preserve.png'
import article8 from '../assets/images/article_analyse_garden.png'

export const articles = [
  {
    title: 'Why Should You Cover Your Outdoor Furniture?',
    image: article1,
    content: (
       <div className='py-[23px] mt-8 px-0 lg:px-8 bg-gray-50 rounded-t-[40px]'>
        <p className='font-nunito text-lg'>Outdoor furniture is expensive, and a dining set may cost as much as $1,300. All of this cost and effort may be in vain, if you fail to protect it against harmful elements, such as rain or pests. Thankfully, outdoor furniture covers, such as the Easireach Cover, serve as saviours. Here’s why you should get them:</p>

        <p className='font-nunito text-lg font-bold pt-4'>Protection in Moisture-Rich Conditions</p>
        <p className='font-nunito text-lg'>Moisture-rich conditions, especially prevalent during spring and summer, are your outdoor furniture’s absolute enemy. Without appropriate covers:</p>
        <ul className='font-nunito text-lg'>
          <li>Water may seep inside your aluminium furniture pieces and damage them from the inside out. Moisture may even lead to mushroom growth!</li>
          <li>Wood furniture may expand over time and get damaged.</li>
          <li>Your iron furniture may rust.</li>
          <li>It may even crack due to trapped moisture if left uncovered overnight in freezing conditions.</li>
        </ul>

        <p className='font-nunito text-lg font-bold pt-4'>Protection Against the Sun</p>
        <p className='font-nunito text-lg'>UV rays and sun exposure can fade your furniture’s original colour and design and deteriorate the materials. If it’s plastic, then it may crack under extreme heat.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Don’t Let Dust, Debris, Mould or Pests Destroy the Aesthetic</p>
        <p className='font-nunito text-lg'> Dust, debris, mould, pests and other such, often neglected hassles may damage the aesthetics and durability of your furniture over time. Dust discolours furniture, mould impacts durability, debris is tortuous when it’s time to clean and pests may turn into an infestation.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Don’t Ignore the Pillows!</p>
        <p className='font-nunito text-lg'>Some of the outdoor pillows are extremely prone to fading, mould and water damage. If you don’t want them to become colourless or be soggy after a downpour, then ensure you cover your outdoor furniture with a cover or store them in a safe spot.</p>
      </div>
    )
  },
  {
    title: 'What Materials are Best for Outdoor Furniture? A Guide to Selecting Suitable Materials for Your Outdoor Furniture',
    image: article2,
    content: (
      <div className='py-[23px] mt-8 px-0 lg:px-8 bg-gray-50 rounded-t-[40px]'>
        <p className='font-nunito text-lg'>Are you planning to turn your patio into your personal holiday spot?
          If yes, then your first instinct would be to get some fancy outdoor furniture. Keep the following materials in mind, when choosing your perfect patio furniture.
        </p>

        <p className='font-nunito text-lg font-bold pt-4'>Synthetic Resin Wickers</p>
        <p className='font-nunito text-lg'>Synthetic resin wicker patio furniture is stylish, easy to maintain, lightweight and durable. Opt for High-Density Polyethylene (HDPE) Wicker instead of the inferior PVC variety, which tends to unravel, become brittle and crack. These furnishings often come with aluminium frames, adding extra strength and resilience to the mix.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Composite</p>
        <p className='font-nunito text-lg'>Composite wood, also known as "poly-wood," is a blend of synthetic resin materials and real wood fibres, offering a waterproof and weatherproof solution. Composite outdoor furniture has numerous advantages, including moisture resistance, no need for staining or sealing, resistance to pests and splintering and cost-effectiveness in the long run.
          However, it doesn't look quite the same as ‘real wood’ in appearance!
        </p>

        <p className='font-nunito text-lg font-bold pt-4'>Teak</p>
        <p className='font-nunito text-lg'>Teak is the ultimate choice for patio furniture due to its weather resistance, strength and beautiful grain finish. Unlike other woods, it naturally produces its own oil, making it highly durable and resistant to dings and dents.
            With a lifespan of up to 50 years, teak surpasses other woods and is perfect for outdoor dining or as armrests. However, proper care is essential to prevent moisture absorption and maintain its original appearance.
        </p>

        <p className='font-nunito text-lg font-bold pt-4'>Cast Aluminium</p>
        <p className='font-nunito text-lg'>Cast aluminium patio furniture combines a lightweight design with remarkable durability. It is surprisingly strong, making it ideal for outdoor chairs that require frequent movement. However, it may not be suitable for areas prone to high wind gusts.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Wrought Iron</p>
        <p className='font-nunito text-lg'>Wrought iron, meaning hand-worked iron, has a rich historical significance as an outdoor furnishing material. It offers unique, handcrafted designs, making it an excellent choice for outdoor furniture. However, it's worth noting that wrought iron is significantly heavier compared to aluminium.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Steel</p>
        <p className='font-nunito text-lg'>Steel is the heaviest and most expensive option. Also, it's not suitable for frequently moved furniture like dining chairs or side tables, as it can rust without proper coating.
          Unless you require extreme support, aluminium is a sufficient choice, offering strength and cost-effectiveness.
          All in all, our verdict is:
        </p>
         <ul className='font-nunito text-lg'>
          <li>For a lightweight, chic, and low-maintenance option, synthetic resin wicker furniture is your best bet.</li>
          <li>If you prefer wooden furniture for outdoor use, teak is the way to go.</li>
          <li>When it comes to metal furniture, aluminium is the recommended choice unless you desire an eclectic design, in which case wrought iron provides more ornate options.</li>
          <li>Avoid plastic resin, PVC wicker and bare metal furniture at all costs.</li>
        </ul>
      </div>
    )
  },
  {
    title: 'Revamp Your Outdoor Space: Embrace the Hottest Summer Furniture Trends of 2023',
    image: article3,
    content: (
      <div className='py-[23px] mt-8 px-0 lg:px-8 bg-gray-50 rounded-t-[40px]'>
        <p className='font-nunito text-lg'>Do you plan on giving your outdoor space a stunning makeover with the hottest summer furniture trends of 2023?
          We’ve got it covered for you. From embracing sustainability to avoiding cold finishes, follow these trends to create a stylish and inviting outdoor space.
        </p>

        <p className='font-nunito text-lg font-bold pt-4'>Embrace Nature</p>
        <p className='font-nunito text-lg'>2023 is all about bringing nature and sustainability into your indoor and outdoor decor. Get ready to be inspired by the beauty of the natural world as it effortlessly merges with your outdoor spaces through furniture, lighting, and cool colour schemes.
          Go eco-friendly with materials like wood, rattan, wicker, and jute – they're not only sustainable but also recycled and recyclable, showing your love for the environment.
        </p>

        <p className='font-nunito text-lg font-bold pt-4'>Go for Viva Magento in Colour Selection</p>
        <p className='font-nunito text-lg'>In 2023, the vibrant hue of Viva Magenta, Pantone Colour of the Year, is a must-have for outdoor furniture. This energetic shade, with hints of red, pink and purple, beautifully complements wooden garden furniture and decorative metal elements.
          From fabrics to furniture and accessories, Viva Magenta adds a bold and unforgettable touch to any space.
        </p>

        <p className='font-nunito text-lg font-bold pt-4'>Go for Rounded Shapes and Sober Lines</p>
        <p className='font-nunito text-lg'>Opt for a lightweight and sober style, featuring soft, rounded shapes, with a focus on comfortable and functional materials that create a cosy and aesthetically pleasing outdoor space.
          Forget about those cold finishes, it's all about creating an inviting and enveloping space that will have everyone feeling right at home.
        </p>

        <p className='font-nunito text-lg font-bold pt-4'>Add Plants to the Mix! </p>
        <p className='font-nunito text-lg'>Plants serve as the centrepiece In the garden furniture trends of 2023. From tall tree-like plants to succulents, flowering plants and plants in hanging pots or planters, aim to create a lush and vibrant ambience reminiscent of a jungle-style setting.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Try Scandinavian Minimalism</p>
        <p className='font-nunito text-lg'>Embrace a minimalist approach, drawing inspiration from the simplicity and softness of the Scandinavian style. Create cosy and intimate spaces that are both functional and inviting for gatherings with loved ones.
          Trying using materials like wood and stone, neutral and light colours and a mix of low and tall plants, all contributing to a harmonious and serene atmosphere.
        </p>
      </div>
    )
  },
  {
    title: 'Revamping Your Outdoor Patio: 10 Steps to Enhance and Enjoy Your Furniture Setup',
    image: article4,
    content: (
      <div className='py-[23px] mt-8 px-0 lg:px-8 bg-gray-50 rounded-t-[40px]'>
        <p className='font-nunito text-lg'>Do you want to spice up your outdoor patio setup without investing too much in new pieces of furniture and by maximising the joys of relaxing and dining?!
          Follow these ten steps to get the best out of your current items or to get the most suitable future pieces!
        </p>

        <ul className='font-nunito text-lg pt-2'>
          <li><b>Have Only as Much as You Can Protect Against Weather:</b> Only have as many pieces of furniture as you can protect during unfavourable weather conditions. You can do this either through our <a className='text-primary-800' href={'https://shelta.com.au/products/easireach-cover/'} target='blank'>Easireach Cover</a> or by storing it in a safe location.</li>
          <li><b>Ease of Mobility:</b> The key to making mobility easier is to keep your walkways clear and have enough space to move around the furniture.</li>
          <li><b>Optimise Seating Organisation:</b> Place seating groups close to each other without being overcrowded and let the chatter begin.</li>
          <li><b>Purchase Only as Much as You Can Maintain Easily:</b> Don’t go overboard with your outdoor furniture just for the sake of the looks if handling its maintenance is going to be tricky for you. It demands considerable attention!</li>
          <li><b>Extras for Aesthetics:</b> Add foliage, flowers and planters into the mix to enhance the aesthetics.</li>
          <li><b>Gauge Material Pros and Cons:</b> Add foliage, flowers and planters into the mix to enhance the aesthetics.</li>
          <li><b>Don’t Neglect the Underneath Surface:</b> Analyse your garden surface before choosing outdoor furniture to avoid balance and compatibility issues.</li>
        </ul>
      </div>
    )
  },
  {
    title: 'How to Protect Your Outdoor Furniture from Sun? Sunproof Your Outdoor Furniture: 5 Strategies to Defend Against Solar Damage',
    image: article2,
    content: (
      <div className='py-[23px] mt-8 px-0 lg:px-8 bg-gray-50 rounded-t-[40px]'>
        <p className='font-nunito text-lg'>Regardless of whether your patio furniture is high-end or not, if it is left exposed to the sun for extended periods without proper care, it will fade away and crack!
          Follow these 4 strategies to make sure this doesn’t happen with your furniture:
        </p>

        <p className='font-nunito text-lg font-bold py-4'>Choose the right material</p>
        <div className='max-w-[500]'>
          <img className='mx-auto' src={rightMaterial} alt={'right-materials'}></img>
        </div>
        <p className='font-nunito text-lg pt-4'>While choosing materials for your furniture, go for items made from UV or fade-resistant materials such as O’bravia fabric or Sunbrella. Also, keep an eye on the material UVE tests conducted by the manufacturer.
          Shelta takes pride in making high-quality furniture pieces tested with the Australian Standard AS4174:2018 Knitted and Woven Shade Fabrics. Have a look at the range of our <a className='text-primary-800' href='https://shelta.com.au/furniture/' target='blank'>products</a> to choose the one best suited for your needs.
        </p>

        <p className='font-nunito text-lg font-bold py-4'>Use umbrellas for shade</p>
        <div className='max-w-[500]'>
          <img className='mx-auto' src={shades} alt='shades'></img>
        </div>
        <p className='font-nunito text-lg pt-4'>Another great way to protect your furniture from the scorching sun is by having a fade-resistant shade. Here, at Shelta, all our umbrellas are made from O’Bravia fabric, an earth-friendly, recyclable, fade and mildew-resistant fabric. Have a look at the range of <a className='text-primary-800' href='https://shelta.com.au/shade/' target='blank'>shade options</a> available to find the design that vibes best with your outdoor furniture aesthetic.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Store it in a Safe Shaded Location</p>
        <p className='font-nunito text-lg'>If your outdoor furniture is lightweight, easily portable, and you have extra indoor storage space available, you can save yourself the hassle of buying a shade. Instead, store it indoors during extremely sunny periods or when the furniture is not in use.</p>

        <p className='font-nunito text-lg font-bold py-4'>Get furniture covers</p>
        <div className='max-w-[500]'>
          <img className='mx-auto' src={article1} alt={'cover-furniture'}></img>
        </div>
        <p className='font-nunito text-lg pt-2'>Keep your outdoor furniture safe from sun damage by investing in top-notch furniture covers. These covers add an extra shield against harmful UV rays, pesky dust and annoying debris. Just make sure you choose covers that are specially designed for outdoor furniture and are made from tough, UV-resistant materials.</p>
      </div>
    )
  },
  {
    title: 'Rainproofing Your Outdoor Oasis: 4 Essential Tips to Safeguard Your Furniture from Rain',
    image: article1,
    content: (
       <div className='py-[23px] mt-8 px-0 lg:px-8 bg-gray-50 rounded-t-[40px]'>
        <p className='font-nunito text-lg'>Creating an outdoor oasis is a wonderful way to enjoy nature, relax and entertain guests. However, it’s expensive and if not taken care of properly can get damaged easily, even with a single downpour. Here’s what you can do to avoid this:</p>

        <p className='font-nunito text-lg font-bold pt-4'>Purchase Water Resistant Furniture</p>
        <p className='font-nunito text-lg'>Before investing in outdoor furniture, do in-depth research on different furniture materials. Opt for options that are rain and mould resistant, such as treated wood, aluminium or synthetic wicker.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Don’t Forget to Cover Your Furniture During the Rainy Season!</p>
        <p className='font-nunito text-lg pt-2'>Invest in high-quality, waterproof furniture covers or traps, specifically designed for outdoor conditions. They may seem like an extra cost, but they are crucial for saving your expensive outdoor furniture from water damage, which can either rust or expand it, damaging its aesthetics over time.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Place Your Furniture in Shade</p>
        <p className='font-nunito text-lg'>Choose a naturally-shaded location on your patio to place your outdoor furniture. If there isn’t enough space, then go with an umbrella or canopy which you can choose from a list of <a className='text-primary-800' href='https://shelta.com.au/shade/' target='blank'>shades</a> made from the fade and mildew-resistant - such as the O’Bravia fabric - to find the best one that suits your outdoor design aesthetic!</p>

        <p className='font-nunito text-lg font-bold pt-4'>Store Your Furniture in a Safe Location</p>
        <p className='font-nunito text-lg pt-2'>If your outdoor furniture is made from lightweight materials, is easily movable, and you have some extra indoor storage space, then save yourself the trouble of purchasing a cover. Instead, store it in your shed or inside whenever rain is due or when you are otherwise not using it.</p>
      </div>
    )
  },
  {
    title: 'Preserve, Protect, Prolong: Mastering the Art of Outdoor Furniture Longevity',
    image: article7,
    content: (
      <div className='py-[23px] mt-8 px-0 lg:px-8 bg-gray-50 rounded-t-[40px]'>
        <p className='font-nunito text-lg'>From the scorching sun to the severe rainstorms, outdoor furniture goes through a lot throughout the year. Sometimes, it may even face temperatures below 0 and battle snow. Here are 5 ways in which you can make sure your outdoor furniture stands the test of time:</p>

        <p className='font-nunito text-lg font-bold pt-4'>Invest in Waterproof Materials</p>
        <p className='font-nunito text-lg'>While choosing materials for outdoor furniture, make sure you keep these tips in mind:</p>
        <ul>
          <li>Choose weather and fungus-resistant materials, such as galvanised steel, concrete composite, synthetic wicker and teak.</li>
          <li>Teak may develop a silvery grey patina over time, but this adds to its aesthetic appeal.</li>
          <li>Acacia wood is also suitable but requires more maintenance, including covering to prevent excess moisture.</li>
        </ul>

        <p className='font-nunito text-lg font-bold pt-4'>Don’t Underestimate the Power of Covers</p>
        <p className='font-nunito text-lg'>If you want your outdoor furniture to last for a long period, don’t shy from investing in water-resistant or waterproof covers. They’ll do wonders in protecting your furniture from rain, humidity or when left unattended for long periods.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Ensure the Furniture is Suitable for Fluctuating Temperatures</p>
        <p className='font-nunito text-lg'>If you want your outdoor furniture to stand strong against fluctuating temperatures, then choose items made with a galvanised steel frame or aluminium, or synthetic rattan.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Keep Your Outdoor Furniture Clean</p>
        <p className='font-nunito text-lg'>Follow these tips to keep your furniture pristine:</p>
         <ul>
          <li>Keep the furniture frame clean by wiping it with a damp cloth.</li>
          <li>Wash the cushions now and then with a gentle detergent and a soft bristle brush combo.</li>
          <li>If your furniture is close to a pool or a hot tub, then hose it either weekly or bi-weekly to save it from chlorine impacts.</li>
        </ul>

        <p className='font-nunito text-lg font-bold pt-4'>Don’t Neglect the Pillows and Cushions</p>
        <p className='font-nunito text-lg'>While choosing pillows and cushions, opt for outdoor-friendly materials such as durable Sunbrella, with mould and mildew-resistant fillers. You can clean them easily by wiping them with a damp cloth or by using a combination of a gentle detergent and a soft bristle brush.</p>
      </div>
    )
  },
  {
    title: 'How to Analyse Your Garden Surface When Investing in Outdoor Furniture? 5 Essential Steps to Analyse Your Garden Surface for Outdoor Furniture',
    image: article8,
    content: (
      <div className='py-[23px] mt-8 px-0 lg:px-8 bg-gray-50 rounded-t-[40px]'>
        <p className='font-nunito text-lg'>Garden furniture costs a pretty penny, which could all go to waste if the pieces of furniture you choose don’t go along with your garden’s surface.<br></br>
          Here are 5 ways to ensure this doesn’t happen and you and your outdoor furniture live in harmony with your garden surface:
        </p>

        <p className='font-nunito text-lg font-bold pt-4'>Assess the Terrain</p>
        <p className='font-nunito text-lg'>Gardens have different surfaces and knowing which one’s yours (flat, sloped or uneven) is key to choosing the right furniture. Go for sturdy furniture with adjustable legs for sloped surfaces, lightweight and flexible designs for uneven terrain and stable options for even surfaces, considering comfort and style. Don’t forget to check for stability when purchasing!</p>

        <p className='font-nunito text-lg font-bold pt-4'>Analyse the Ground Material</p>
        <p className='font-nunito text-lg'>Have a thorough look at the type of your garden’s ground material, and choose furniture accordingly. In the case of soft ground like grass, go for pieces with wider legs or supportive bases that distribute the weight evenly to avoid sinking.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Ensure the Furniture is Suitable for Fluctuating Temperatures</p>
        <p className='font-nunito text-lg'>If you want your outdoor furniture to stand strong against fluctuating temperatures, then choose items made with a galvanised steel frame or aluminium, or synthetic rattan.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Avoid Overcrowding Through Thorough Space Analysis</p>
        <p className='font-nunito text-lg'>No one wants their garden to look overcrowded with furniture. You can avoid this by measuring the dimensions of the area where you plan to place the furniture and purchasing appropriately sized pieces accordingly.</p>

        <p className='font-nunito text-lg font-bold pt-4'>Avoid Water Pooling</p>
        <p className='font-nunito text-lg'>Water pooling around furniture may drown all your efforts in setting up your garden design as excess moisture can damage certain materials and lead to deterioration over time. Ensure proper water drainage!</p>

        <p className='font-nunito text-lg font-bold pt-4'>Test Your Garden’s Stability</p>
        <p className='font-nunito text-lg'>Take a stroll in your garden and keep an eye out for any sinking or sneaky shifting. This will help you identify potential areas of concern and make necessary adjustments before placing heavy furniture.</p>
      </div>
    )
  },
]
