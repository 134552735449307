import { ButtonIcon, Heading } from '@rabbit/elements/shared-components';

export interface HomeTitleWithCTAProps {
  hasTitle: boolean;
  hasSubtitle: boolean;
  title?: string;
  subTitle?: string;
  onClick?: () => any;
}

export function HomeTitleWithCTA({
  hasTitle,
  hasSubtitle,
  title,
  subTitle,
  onClick,
}: HomeTitleWithCTAProps) {
  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        {hasTitle && <Heading kind={'h5'}>{title}</Heading>}
        {onClick && (
          <div className="">
            <ButtonIcon label={'Add'} onClick={onClick} />
          </div>
        )}
      </div>
      {hasSubtitle && (
        <div className="font-nunito mt-3 text-base font-light">{subTitle}</div>
      )}
    </div>
  );
}

export default HomeTitleWithCTA;
