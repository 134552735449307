import {
  Input,
  Button,
  getAuthProviderName,
} from '@rabbit/elements/shared-components';
import { subtractYears, useNLICAccountCreator } from '@rabbit/bizproc/react';
import { Formik } from 'formik';
import { Form } from 'formik';
import { Navigate, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CompleteProfileFormValuesShape } from 'libs/bizproc/react/src/types';
import { FBD_Identity_Private } from '@rabbit/data/types';
import ROUTE_NAME from '../../utils/url-constants';

const schema = Yup.object({
  first_name: Yup.string()
    .required('Please enter a first name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'First name must start with a capital letter and contain only letters.'
    ),
  last_name: Yup.string()
    .trim()
    .required('Please enter a last name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'Last name must start with a capital letter and contain only letters.'
    ),
  // dob: Yup.date()
  //   .required('Date of birth is required')
  //   .max(new Date(), 'Invalid date'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
});

interface NLICAccountCreatorProps {
  onChangeStep: (data: string) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

function NLICAccountCreator({
  onChangeStep,
  setIsLoading,
}: NLICAccountCreatorProps) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { createNewIdentityAndConsumerPersona } = useNLICAccountCreator();

  const NLIC_VendableId = window.localStorage.getItem('NLIC_VendableId');
  const NLIC_UserRaw = window.localStorage.getItem('NLIC_User');
  const NLIC_User = NLIC_UserRaw ? JSON.parse(NLIC_UserRaw) : null;

  const checkIfUserHasAccount = async () => {
    const user = await FBD_Identity_Private.get(NLIC_User?.uid);
    return user ? true : false;
  };

  useEffect(() => {
    checkIfUserHasAccount()
      .then((hasAccount) => {
        if (hasAccount) {
          window.localStorage.removeItem('NLIC_User');
          window.localStorage.removeItem('NLIC_StopGetIdentity');
          window.localStorage.removeItem('NLIC_VendableId');
          window.localStorage.removeItem('NLIC_VendableName');
          navigate(`/products/preview/${NLIC_VendableId}/register`);
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const authProvider = getAuthProviderName(
    NLIC_User?.providerData?.[0].providerId
  );

  if (!NLIC_User || !NLIC_VendableId) return <Navigate to={'/'} />;

  // Form section begins here

  const initialValues: CompleteProfileFormValuesShape = {
    first_name: '',
    last_name: '',
    //dob: null,
    email: NLIC_User?.email ?? '',
    opt_out_marketing: false,
  };

  const onSubmit = async (values: CompleteProfileFormValuesShape) => {
    setLoading(true);
    setIsLoading(true);
    try {
      const user = await createNewIdentityAndConsumerPersona(
        values,
        NLIC_User.uid
      );
      // If successful, clear user from localstorage and move to next stage
      window.localStorage.removeItem('NLIC_User');
      window.localStorage.removeItem('NLIC_StopGetIdentity');
      if (NLIC_VendableId) onChangeStep(NLIC_VendableId);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-10 flex flex-col">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ errors, values, touched }) => (
          <Form className="flex flex-col gap-3">
            <div>
              <Input
                type="text"
                name="first_name"
                settings={{
                  id: 'first_name',
                  placeholder: 'First name',
                  hint: '*required',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            <div>
              <Input
                type="text"
                name="last_name"
                settings={{
                  id: 'last_name',
                  placeholder: 'Last name',
                  hint: '*required',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            {/* <div>
              <Input
                type="datepicker"
                name="dob"
                settings={{
                  id: 'dob',
                  maxDate: subtractYears(new Date(), 18),
                  initialDate: subtractYears(new Date(), 18),
                  placeholder: 'Date of birth',
                }}
              />
            </div> */}
            <div>
              <Input
                type="email"
                name="email"
                settings={{
                  id: 'email',
                  disabled: true,
                  hint: '*required',
                }}
              />
            </div>
            <div>
              <p className="font-nunito font-medium">
                iWarranty will occasionally send you promotional emails. You can
                opt out at any time in your account settings or directly from
                the marketing email.
              </p>
            </div>
            <div className="font-nunito">
              <Input
                type="checkbox"
                name="opt_out_marketing"
                settings={{
                  checkboxLabel: 'Please don’t send me promotional emails.',
                }}
              />
            </div>
            <div className="font-nunito fixed bottom-0 left-0 min-w-full rounded-tl-[30px] rounded-tr-[30px] bg-gray-100 font-medium md:relative md:bg-transparent">
              <div className="px-10 py-5 md:px-0 md:py-0">
                <div className="mt-4 flex">
                  <Button
                    kind="primary"
                    type="submit"
                    disabled={Object.keys(errors).length > 0}
                    loading={loading}
                  >
                    {authProvider ? `Sign up with ${authProvider}` : 'Sign up'}
                  </Button>
                </div>
                <div className="mt-4 text-center">
                  By continuing, you agree to iWarranty’s <br />
                  <a
                    href={ROUTE_NAME.TERMS_AND_CONDITIONS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href={ROUTE_NAME.PRIVACY_POLICY}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NLICAccountCreator;
