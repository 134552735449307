import {
  ChevronLeftIcon,
  ComputerDesktopIcon,
  InformationCircleIcon,
  ClipboardIcon,
} from '@heroicons/react/24/solid';
import {
  ShadowContainer,
  Heading,
  StepsProgress,
  asyncValidateSchema,
  Button,
  Input,
} from '@rabbit/elements/shared-components';
import { Formik, Form } from 'formik';
import { SRStepProps } from '../ServiceRequestView';
import * as Yup from 'yup';
import {
  ConsumerIssueTypeHookOrigins,
  useConsumerHoldingEditor,
  useCreateCaseAsConsumer,
  useManageConsumerIssueTypes,
  useSendEmail,
} from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { getUnixTime } from 'date-fns';
import { useAppInfo } from '@rabbit/olive/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { OurDateTime } from '@rabbit/utils/ts';

interface FormValuesShape {
  issue: string;
  reason: string;
}

const validationSchema = Yup.object().shape({
  issue: Yup.string().trim().required('Please select a store'),
  reason: Yup.string().trim().required('Please enter reason for contacting us'),
});

export default function ServiceRequestIssueReport({
  setStep,
  data,
}: SRStepProps) {
  const { createCase } = useCreateCaseAsConsumer(data.holdingId);
  const [loading, setLoading] = useState(false);
  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.OLIVE
  );
  const { SE_Olive_Claim_Waiting_For_Review } = useSendEmail();
  const navigate = useNavigate();
  const appInfo = useAppInfo();
  const consumerIssueTypeOptions = !consumerIssueTypeList?.data
    ? []
    : consumerIssueTypeList.data.map((issue) => ({
        id: issue.docid,
        value: issue.docid,
        label: issue.label,
      })) ?? [];
  const persona = useMyConsumerPersona();
  const { body } = useConsumerHoldingEditor(
    data.personaId ?? '',
    data.holdingId
  );

  const initialValues: FormValuesShape = {
    issue: data?.issue || '',
    reason: data?.reason || '',
  };

  const handleSubmit = async (values: FormValuesShape) => {
    setLoading(true);
    try {
      const res = await createCase({
        consumer_issue_type: consumerIssueTypeOptions.filter(
          (option) => option.value === values.issue
        )[0].label,
        consumer_issue_type_ref: values.issue,
        consumer_issue_description: values.reason,
        consumer_proof_of_purchase: body?.private?.receipt?.length
          ? body?.private?.receipt
          : [],
        consumer_claim_evidence: [],
      });
      toast.success('Issue submitted succesfully!');
      const case_time_created = OurDateTime.nowUTCTimestamp();

      if (res) {
        const claimURL = window.location.origin + '/repairs/' + res;
        await SE_Olive_Claim_Waiting_For_Review(
          persona.data.consumer_private?.email ?? '',
          appInfo.email_sender,
          appInfo.email_main_template,
          appInfo.name,
          persona.data.consumer_private?.splitname?.first ??
            persona.data.consumer_private?.fullname ??
            persona.data.consumer_private?.email ??
            '',
          res as string,
          data.holding?.title !== ''
            ? data.holding.title
            : data.holding.self_registration.title,
          case_time_created,
          claimURL,
          appInfo.templateLanguage
        );
      }
      navigate('/products/' + data.holdingId + '/details');
    } catch (e: any) {
      toast.error(e.message || 'Unable to submit issue, please try again!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ShadowContainer loading={loading}>
      <div className="relative h-full px-8 py-6">
        <div className="relative">
          <ChevronLeftIcon
            className="absolute mt-1 h-6 w-6 cursor-pointer"
            onClick={() => setStep({ current: 1 })}
          />
          <Heading kind="h3" className="text-center">
            Issue report
          </Heading>
        </div>
        <div className="mt-6 text-gray-600">
          <StepsProgress
            steps={[
              { Icon: ComputerDesktopIcon, completed: true },
              { Icon: InformationCircleIcon, completed: true },
              { Icon: ClipboardIcon, completed: false },
            ]}
            inline={true}
          />
        </div>
        <div className="mt-6 mb-6 flex flex-col gap-2">
          <Heading kind="h4" className="w-full">
            What’s wrong with your product?
          </Heading>
          <Heading kind="p">
            We’re really sorry if you're having a problem with your product.
            Please select an issue and provide a brief description. This helps
            us to better serve you.
          </Heading>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur
          validate={asyncValidateSchema(validationSchema)}
        >
          {() => (
            <Form>
              <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-4">
                  <Input
                    type="select"
                    name="issue"
                    label="Please select an issue"
                    settings={{
                      placeholder: 'Please select an option',
                      options: consumerIssueTypeOptions,
                    }}
                  />
                  <Input
                    type="textarea"
                    name="reason"
                    label="Reason for contacting us"
                    settings={{
                      placeholder: 'Write text here..',
                    }}
                  />
                </div>
                <Button kind="primary" type="submit">
                  Submit request
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ShadowContainer>
  );
}
