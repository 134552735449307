import ScanTemplate from '../../../templates/Barcode/ScanTemplate';
import VendableScanSingleView from './single/VendableScanSingleView';
import { useState } from 'react';
import { DTVendable } from '@rabbit/data/types';
import Layout from '../../../templates/Layout/Layout';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VendableScanViewProps {}

export function VendableScanView(props: VendableScanViewProps) {
  const [productData, setProductData] = useState<null | DTVendable>(null);

  if (productData)
    return (
      <Layout>
        <VendableScanSingleView data={productData} />
      </Layout>
    );

  return <ScanTemplate setProductData={setProductData} />;
}

export default VendableScanView;
