import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  DesktopContainer,
  LoadingSpinner,
  StepsProgress,
} from '@rabbit/elements/shared-components';
import RegisterHoldingInformationStep from '../../../templates/RegisterHolding/RegisterHoldingInformationStep';
import RegisterHoldingProofStep from '../../../templates/RegisterHolding/RegisterHoldingProofStep';
import { useNavigate, useParams } from 'react-router-dom';
import { DTVendable } from '@rabbit/data/types';
import { OliveHoldingRegistrationFormData } from '@rabbit/elements/shared-types';
import RegisterHoldingCustomerQuestionnaireStep from 'apps/olive/src/templates/RegisterHolding/RegisterHoldingCustomerQuestionnaireStep';
import RegisterHoldingProductQuestionnaireStep from 'apps/olive/src/templates/RegisterHolding/RegisterHoldingProductQuestionnaireStep';
import { DR_Vendable, useMyConsumerPersona } from '@rabbit/data/portal';
import {
  DocumentCheckIcon,
  IdentificationIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { useAppInfo } from 'apps/olive/src/utils/helpers';
import { useCreateHoldingFromVendable } from '@rabbit/bizproc/react';
import { t } from 'i18next';
import { CompleteHoldingRegistration } from '@rabbit/olive/templates/RegisterHolding/CompleteHoldingRegistration';

export function HoldingRegisterView({ props }: any) {
  const { id } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  //TODO TemporaryFixRemovedQuestionnaire
  let totalSteps = 3;
  const [currentStep, setCurrentStep] = useState({
    step: searchParams.get('holdingId') ? -1 : 1,
    holdingId: '',
  });
  const [productRegistrationData, setProductRegistrationData] =
    useState<OliveHoldingRegistrationFormData>(
      {} as OliveHoldingRegistrationFormData
    );
  const navigate = useNavigate();
  const appInfo = useAppInfo();

  const createUserHolding = useCreateHoldingFromVendable(id ?? '');

  const [vendable, setVendable] = useState(DR_Vendable.empty());
  useEffect(() => {
    async function getVendable() {
      const vendable = id
        ? (await DR_Vendable.get(id)) ?? DR_Vendable.empty()
        : DR_Vendable.empty();
      setVendable(vendable);
    }
    getVendable();
  }, []);

  const {
    data: { consumer_private },
  } = useMyConsumerPersona();

  if (
    consumer_private &&
    (consumer_private?.gender ||
      consumer_private?.type_of_dwelling ||
      consumer_private?.living_situation ||
      consumer_private?.work_status)
  )
    totalSteps = 3;

  const onChangeStep = (step_in: number, holdingId: string = '') => {
    setCurrentStep({ ...currentStep, step: step_in, holdingId: holdingId });
  };
  const [isLoading, setIsLoading] = useState(false);

  const onGoBack = () => {
    if (currentStep.step === 2) onChangeStep(currentStep.step - 1);
  };

  if (!vendable.docid) return <LoadingSpinner size="xs" />;

  const steps =
    totalSteps === 4
      ? [
          {
            Icon: InformationCircleIcon,
            completed: currentStep.step > 1,
          },
          {
            Icon: DocumentCheckIcon,
            completed: currentStep.step > 2,
          },
          {
            Icon: IdentificationIcon,
            completed: currentStep.step > 3,
          },
          {
            Icon: InformationCircleIcon,
            completed: currentStep.step > 4,
          },
        ]
      : [
          {
            Icon: InformationCircleIcon,
            completed: currentStep.step > 1,
          },
          {
            Icon: DocumentCheckIcon,
            completed: currentStep.step > 2,
          },
          //TODO TemporaryFixRemovedQuestionnaire
          // {
          //   Icon: QuestionMarkCircleIcon,
          //   completed: currentStep.step > 4,
          // },
        ];

  if (window) window.localStorage.removeItem('LRP_VendableId');

  return (
    <DesktopContainer
      title={
        searchParams.get('holdingId')
          ? t('Complete registration')
          : currentStep.step === 2
          ? t('Proof of purchase')
          : currentStep.step === 3 || currentStep.step === 4
          ? t('Survey')
          : t('Purchase details')
      }
      goBack={currentStep.step === 1 ? () => navigate(-1) : null}
      loading={isLoading}
    >
      <div className="md:my-8">
        {!searchParams.get('holdingId') && <StepsProgress steps={steps} />}
      </div>
      {(currentStep.step === 3 || currentStep.step === 4) && (
        <div className="mb-4">
          Congratulations on your new purchase. {appInfo.name ?? 'Company'}{' '}
          would like to ask you some questions to help make better products and
          serve you and their other customers better. If you do not want to
          answer these questions, please click continue below.
        </div>
      )}
      <Switch
        createUserHolding={createUserHolding}
        productRegistrationData={productRegistrationData}
        setProductRegistrationData={setProductRegistrationData}
        currentStep={currentStep.step}
        holdingId={searchParams.get('holdingId') || currentStep.holdingId}
        onChange={onChangeStep}
        setIsLoading={setIsLoading}
        product={vendable}
        totalSteps={totalSteps}
      />
    </DesktopContainer>
  );
}

export default HoldingRegisterView;

type SwitchProps = {
  currentStep: number;
  createUserHolding: (
    holdingData: OliveHoldingRegistrationFormData
  ) => Promise<string | Error>;
  productRegistrationData: OliveHoldingRegistrationFormData;
  setProductRegistrationData: React.Dispatch<OliveHoldingRegistrationFormData>;
  onChange: (value: number) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  product: DTVendable;
  holdingId: string;
  totalSteps: number;
};

const Switch: React.FC<SwitchProps> = ({
  productRegistrationData,
  setProductRegistrationData,
  createUserHolding,
  currentStep,
  onChange,
  setIsLoading,
  product,
  holdingId,
  totalSteps,
}) => {
  switch (currentStep) {
    case 1:
      return (
        <RegisterHoldingInformationStep
          setProductRegistrationData={setProductRegistrationData}
          onChange={onChange}
          product={product}
          createUserHolding={createUserHolding}
          setIsLoading={setIsLoading}
        />
      );
    case 2:
      return (
        <RegisterHoldingProofStep
          holdingId={holdingId}
          productRegistrationData={productRegistrationData}
          setProductRegistrationData={setProductRegistrationData}
          createUserHolding={createUserHolding}
          onChange={onChange}
          setIsLoading={setIsLoading}
        />
      );
    case 3:
      return totalSteps === 4 ? (
        <RegisterHoldingCustomerQuestionnaireStep
          onChange={onChange}
          holdingId={holdingId}
          setIsLoading={setIsLoading}
        />
      ) : (
        <RegisterHoldingProductQuestionnaireStep
          onChange={onChange}
          holdingId={holdingId}
          product={product}
          setIsLoading={setIsLoading}
        />
      );
    case 4:
      return (
        <RegisterHoldingProductQuestionnaireStep
          onChange={onChange}
          holdingId={holdingId}
          product={product}
          setIsLoading={setIsLoading}
        />
      );
    case -1:
      return (
        <CompleteHoldingRegistration
          holdingId={holdingId}
          setIsLoading={setIsLoading}
        />
      );
    default:
      return null;
  }
};
