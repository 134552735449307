import {
  DR_Consumer_Private,
  usePortalDocumentEditor,
} from '@rabbit/data/portal';
import { DTConsumer_Private } from '@rabbit/data/types';
import { DRDocType } from '@rabbit/firebase/react';
import { Dispatch, SetStateAction, useState } from 'react';
import AccountAddressForm from '../../components/molecules/AccountAddressForm/AccountAddressForm';
import AccountAddressList from '../../components/molecules/AccountAddressList/AccountAddressList';

interface AddressesContainerProps {
  id: string;
  data: DTConsumer_Private;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

interface FormStateShape {
  open: boolean;
  id: string | null;
}

function AddressesContainer({ id, data, setIsLoading }: AddressesContainerProps) {
  const { body, update, commit, isReady } = usePortalDocumentEditor(
    DR_Consumer_Private as DRDocType<DTConsumer_Private>,
    id ?? ''
  );

  const [form, setForm] = useState<FormStateShape>({ open: false, id: null });

  const onOpenForm = (id?: string) => {
    setForm({ open: true, id: id ?? null });
  };

  const onCloseForm = () => {
    setForm({ open: false, id: null });
  };

  return (
    <div>
      {!form.open && body ? (
        <AccountAddressList
          personaId={''}
          body={body}
          onOpenForm={onOpenForm}
        />
      ) : (
        <AccountAddressForm
          personaId={id}
          addressId={form.id}
          onCloseForm={onCloseForm}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
}

export default AddressesContainer;
