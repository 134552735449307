import { useMyConsumerPersona } from '@rabbit/data/portal';
import { DTConsumer_Private } from '@rabbit/data/types';
import React, { useEffect } from 'react';

type ConsumerPersonaCheckerProps = {
  setConsumerPersonaData: React.Dispatch<
    React.SetStateAction<DTConsumer_Private | null>
  >;
  setConsumerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
};

// TO CHECK: isLoading - is it the best option here?
export function ConsumerPersonaChecker({
  setConsumerPersonaData,
  setConsumerPersonaId,
}: ConsumerPersonaCheckerProps) {
  const consumerPersona = useMyConsumerPersona();
  const consumerPrivate = consumerPersona?.data?.consumer_private;

  useEffect(() => {
    if (consumerPersona.personaId === 'NOTALLOWED') {
      setConsumerPersonaId(consumerPersona.personaId);
    }
    if (
      consumerPersona &&
      !consumerPersona.isLoading &&
      !consumerPersona.isLoadingError &&
      !consumerPersona.isError
    ) {
      setConsumerPersonaData(consumerPrivate);
      setConsumerPersonaId(consumerPersona.personaId);
    }
    // NOTE: Every flag that needs to be instantly updated (e.g: t&cs) should be added to dependency array below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    consumerPrivate?.identity,
    consumerPrivate?.acceptedTerms,
    consumerPrivate?.hasUsedScanner,
    consumerPrivate?.isOnboarded,
    consumerPersona?.personaId,
  ]);

  return null;
}
