import { BarcodeReader } from 'dynamsoft-javascript-barcode';

/** LICENSE ALERT - README
 * To use the library, you need to first specify a license key using the API "license" as shown below.
 */

BarcodeReader.license = import.meta.env.VITE_DYNAMSOFT_KEY;
//BarcodeReader.license =
  'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxOTE0MDA4LVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAxOTE0MDA4IiwiY2hlY2tDb2RlIjoyMDkyMzI0MzIwfQ==';

/**
 * You can visit https://www.dynamsoft.com/customer/license/trialLicense?utm_source=github&product=dbr&package=js to get your own trial license good for 30 days.
 * Note that if you downloaded this sample from Dynamsoft while logged in, the above license key may already be your own 30-day trial license.
 * For more information, see https://www.dynamsoft.com/barcode-reader/programming/javascript/user-guide/?ver=9.6.2&utm_source=github#specify-the-license or contact support@dynamsoft.com.
 * LICENSE ALERT - THE END
 */

BarcodeReader.engineResourcePath =
  'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.2/dist/';
