import {
  FBD_Identity,
  FBD_Identity_Private,
  FBD_Consumer_Private,
} from '@rabbit/data/types';
import { CompleteProfileFormValuesShape } from '../../types';
import { firebaseAuth } from '@rabbit/firebase/adapter-react';

//wip
export function useNLICAccountCreator() {
  const authDetails = firebaseAuth.currentUser;
  console.log('authDetails', authDetails);
  console.log('firebaseAuth', firebaseAuth);
  const createNewIdentityAndConsumerPersona = async (
    userFormData: CompleteProfileFormValuesShape,
    authUserId: string
  ) => {
    // Due to the security rules update, we now have to create both identity and persona at the same time
    // As they can't be updated after creation except by their owner

    const existing_id_private = await FBD_Identity_Private.get(authUserId);
    const existing_id_public = await FBD_Identity.get(authUserId);

    if (existing_id_private || existing_id_public)
      throw new Error('Identity already exists');

    // Create a new identity
    try {
      const newIdentity = FBD_Identity.empty();
      let newIdentity_Private = FBD_Identity_Private.empty();

      const newIdentityData = {
        displayname: `${userFormData.first_name} ${userFormData.last_name}`,
        email: userFormData.email,
        photoUrl: '',
        docid: authUserId,
      };

      //TODO: change other similar code to use this cleaner syntax
      newIdentity.docid = newIdentityData.docid;
      newIdentity_Private = {
        ...newIdentity_Private,
        docid: newIdentityData.docid,
        displayname:
          newIdentityData.displayname || newIdentityData.email || 'Anonymous',
        email: newIdentityData.email || '',
        photoUrl: newIdentityData.photoUrl || '',
      };

      // Then create a new consumer persona for the identity

      let newConsumerPrivate = FBD_Consumer_Private.empty();
      newConsumerPrivate = {
        ...newConsumerPrivate,
        splitname: {
          first: userFormData.first_name,
          last: userFormData.last_name,
        },
        fullname: newIdentity_Private.displayname,
        email: newIdentity_Private.email,
        photoUrl: newIdentity_Private.photoUrl,
        identity: newIdentity_Private.docid,
        marketing: userFormData.opt_out_marketing,
        dob: Math.floor(new Date(userFormData?.dob ?? 0).getTime() / 1000),
      };

      // if (
      //   newConsumerPrivate.address &&
      //   Array.isArray(newConsumerPrivate.address)
      // )
      //   newConsumerPrivate.address.push(initialCaseData.consumer_address);
      // else newConsumerPrivate.address = [initialCaseData.consumer_address];

      // Finally, set and return all the relevant documents
      const personaId = await FBD_Consumer_Private.set(newConsumerPrivate);
      console.log('New consumer persona created successfully');
      newIdentity_Private.persona.consumer = personaId;

      await FBD_Identity.set(newIdentity); // TODO: IDENTITY SET ON CLIENT SIDE WILL BE BANNED SOON -- DEV-64
      await FBD_Identity_Private.set(newIdentity_Private); // TODO: IDENTITY SET ON CLIENT SIDE WILL BE BANNED SOON -- DEV-64
      console.log('New identity created successfully');

      return { newIdentity, newIdentity_Private, newConsumerPrivate };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return { createNewIdentityAndConsumerPersona };
}
