import { Button, Heading, Input } from '@rabbit/elements/shared-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  DR_Consumer_Private,
  useMyConsumerPersona,
  usePortalDocumentEditor,
} from '@rabbit/data/portal';
import { CUSTOMER_QUESTIONNAIRE } from '@rabbit/bizproc/react';
import { DTConsumer_Private } from '@rabbit/data/types';
import { DRDocType } from '@rabbit/firebase/react';

interface FormValuesShape {
  type_of_dwelling: string;
  living_situation: string;
  gender: string;
  work_status: string;
}

interface RegisterHoldingCustomerQuestionnaireStepProps {
  onChange: (value: number, holdingId: string) => void;
  holdingId: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const validationSchema = Yup.object().shape({
  type_of_dwelling: Yup.string().trim(),
  living_situation: Yup.string().trim(),
  gender: Yup.string().trim(),
  work_status: Yup.string().trim(),
});

export function RegisterHoldingCustomerQuestionnaireStep({
  onChange,
  holdingId,
  setIsLoading,
}: RegisterHoldingCustomerQuestionnaireStepProps) {
  const [isSubmiting, setSubmiting] = useState(false);
  const persona = useMyConsumerPersona();

  const { body, update, commit, isReady } = usePortalDocumentEditor(
    DR_Consumer_Private,
    persona?.data?.consumer_private?.docid ?? ''
  );

  const initialValues: FormValuesShape = {
    type_of_dwelling: '',
    living_situation: '',
    gender: '',
    work_status: '',
  };

  const onSubmit = async (values: FormValuesShape) => {
    setSubmiting(true);
    setIsLoading(true);

    if (!body || !isReady) return;
    try {
      update({
        ...body,
        gender: values.gender,
        type_of_dwelling: values.type_of_dwelling,
        living_situation: values.living_situation,
        work_status: values.work_status,
      });

      await commit();
      toast.success('Details saved successfully!');
      setIsLoading(false);
      onChange(4, holdingId);
    } catch (error) {
      console.log('error', error);
    }
    setSubmiting(false);
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col gap-4">
      <Heading kind={'h3'}>Personal information</Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values }) => (
          <Form className="font-nunito flex flex-col gap-4">
            {CUSTOMER_QUESTIONNAIRE.map((question, index) => {
              return (
                <div key={index}>
                  <div className="mb-2 font-semibold">{question.label}</div>
                  <div className="font-nunito flex flex-col gap-1">
                    {question.options.map((option, v) => {
                      return (
                        <Input
                          key={question.name + v}
                          type="radio"
                          name={question.name}
                          settings={{ radioLabel: option, values: values }}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <Button
              kind="primary"
              type="submit"
              disabled={Object.keys(errors).length > 0 || isSubmiting}
              loading={isSubmiting}
            >
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RegisterHoldingCustomerQuestionnaireStep;
