import { Heading } from '@rabbit/elements/shared-components';

interface FloatImageCardProps {
  title: string;
  body: string;
  imageSrc?: string;
  imageAlt?: string;
  floatPosition?: 'title' | 'right' | 'body';
}

export default function FloatImageCardView({
  title,
  body,
  imageSrc,
  imageAlt,
  floatPosition,
}: FloatImageCardProps) {
  return (
    <div className="flex w-full flex-col rounded-lg bg-white p-4 shadow-sm">
      <Heading
        kind="h4"
        className={
          floatPosition == 'title'
            ? 'relative mb-6 w-fit font-semibold'
            : 'relative mb-6 font-semibold'
        }
      >
        {title}
        {(floatPosition == 'title' || floatPosition == 'right') && (
          <img
            src={imageSrc}
            alt={imageAlt}
            className={
              floatPosition == 'title'
                ? 'absolute -bottom-2 left-full w-1/2 sm:w-fit'
                : 'absolute -bottom-6 right-10 w-1/2 sm:w-fit'
            }
          />
        )}
      </Heading>
      <Heading
        kind="p"
        className={
          (floatPosition == 'body' ? 'pr-16 sm:pr-16' : '') +
          ' font-nunito relative flex font-normal text-gray-500'
        }
      >
        {body}
        {floatPosition == 'body' && (
          <img
            src={imageSrc}
            alt={imageAlt}
            className="absolute -right-2 -translate-y-[40%] sm:-right-2"
          />
        )}
      </Heading>
    </div>
  );
}
