import { Button, Heading } from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../../utils/url-constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WhyCompleteHoldingInfoCTA } from '../../molecules/WhyCompleteHoldingInfoCTA/WhyCompleteHoldingInfoCTA';
import {
  usePortalMultipleDocumentEditor,
  DR_Holding_Public,
  DR_Holding_Private,
  DR_Holding_Manufacturer,
} from '@rabbit/data/portal';

export function MissingHoldingInfoSection({
  id,
  showCTA,
}: {
  id: string;
  showCTA: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { body, isReady, update, commit } = usePortalMultipleDocumentEditor({
    holding_public: {
      type: DR_Holding_Public,
      docid: id,
    },
    holding_private: {
      type: DR_Holding_Private,
      docid: id,
    },
    holding_manufacturer: {
      type: DR_Holding_Manufacturer,
      docid: id,
    },
  });

  const updateShowCTA = async () => {
    // We update all the documents so that tupdate stays synced
    // TODO: confirm if we need to clone the old document values first.
    // Seems to work well enough like this but I seem to recall having issues when not cloning elsewhere - DC
    const { holding_public, holding_private, holding_manufacturer } = body;
    const { self_registration } = holding_public || {};
    if (holding_public && self_registration && isReady) {
      try {
        self_registration.show_missing_info_cta = false;

        await update({
          holding_public: {
            ...holding_public,
            self_registration,
          },
          holding_private,
          holding_manufacturer,
        });
        await commit();
        setSearchParams({ updated: 'true' });
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <div className="flex w-full flex-col gap-6">
      {showCTA && (
        <div className="mx-auto flex max-w-[560px] flex-col items-center gap-6">
          <Heading kind="h3">Missing product information</Heading>
          <Heading kind="p" className="text-gray-500">
            Please upload further product details to get the best out of
            iWarranty
          </Heading>
          <Button
            kind="primary"
            className="w-full"
            onClick={() =>
              navigate(ROUTE_NAME.PRODUCTS + '/' + id + '/edit-srv')
            }
          >
            Edit product information
          </Button>
          <Button
            kind="outline"
            className="w-full"
            onClick={async () => await updateShowCTA()}
          >
            I'll add later
          </Button>
        </div>
      )}
      <WhyCompleteHoldingInfoCTA
        id={id}
        isSRV={!!body.holding_public?.self_registration}
      />
    </div>
  );
}
