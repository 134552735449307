import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../../components/molecules/NavBar/NavBar';
import ROUTE_NAME from '../../utils/url-constants';
import Footer from '../../components/molecules/Footer/Footer';

type LayoutProps = {
  children: React.ReactNode;
};

export function Layout({ children }: LayoutProps) {
  const location = useLocation();

  const titleMap = [
    { path: ROUTE_NAME.ACCOUNT, title: 'My Account' },
    { path: ROUTE_NAME.ACCOUNT_EDIT, title: 'My Account - Edit' },
    {
      path: ROUTE_NAME.ACCOUNT_PRIVACY,
      title: 'My Account - Privacy settings',
    },
    {
      path: ROUTE_NAME.ACCOUNT_MARKETING,
      title: 'My Account - Marketing settings',
    },
    { path: ROUTE_NAME.AUTH, title: 'Auth' },
    { path: ROUTE_NAME.DASHBOARD, title: 'Dashboard' },
    { path: ROUTE_NAME.COMPLETE_PROFILE, title: 'Complete Profile' },
    { path: ROUTE_NAME.IMPACT, title: 'Impact' },
    { path: ROUTE_NAME.PRODUCTS, title: 'Products' },
    { path: ROUTE_NAME.PRODUCTS_SEARCH, title: 'Search products' },
    { path: ROUTE_NAME.PRODUCTS_SCAN, title: 'Scan product' },
    { path: ROUTE_NAME.PRODUCTS_PREVIEW, title: 'Preview product' },
    { path: ROUTE_NAME.REPAIRS, title: 'Repairs' },
  ];

  useEffect(() => {
    const currTitle = titleMap.find((item) => item.path === location.pathname);
    if (currTitle && currTitle.title) {
      const pageTitle = 'iWarranty - ' + currTitle.title;
      document.title = pageTitle;
    }
  }, [location]);

  return (
    <div className="w-full flex flex-col">
      <NavBar />
      <div className="w-full grow px-4 pb-[70px] pt-8 md:m-[0_auto] md:max-w-[1024px] md:scroll-px-28 lg:py-[25px] lg:px-0 lg:pb-12">
        <div>{children}</div>
      </div>
       <Footer />
    </div>
  );
}

export default Layout;
