import {
  Button,
  DesktopContainer,
  Input,
  LoadingSpinner,
  ProgressBar,
} from '@rabbit/elements/shared-components';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CUSTOMER_QUESTIONNAIRE,
  HOLDING_QUESTIONNAIRE,
  useGetConsumerHoldings,
} from '@rabbit/bizproc/react';
import {
  DR_Consumer_Private,
  DR_Holding_Manufacturer,
  useMyConsumerPersona,
  usePortalMultipleDocumentEditor,
} from '@rabbit/data/portal';
import { useState } from 'react';
import { DTVendable } from '@rabbit/data/types';
import { toast } from 'react-toastify';
import ROUTE_NAME from '@rabbit/olive/utils/url-constants';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SearchVendablesByDocid } from '@rabbit/search/cherchons';
import { useTranslation } from 'react-i18next';

const initialValues: ProductQuestionnaireResponses = {
  purchase_inspiration: '',
  product_to_be_used: '',
  product_to_be_used_other: '',
  purchase_reason: '',
  purchase_reason_other: '',
  product_main_use: '',
  product_main_use_other: '',
  property_shade: '',
  shelta_aware: '',
  purchase_satisfaction: '',
};
const validationSchema = Yup.object().shape({
  purchase_inspiration: Yup.string().trim(),
  product_to_be_used: Yup.string().trim(),
  product_to_be_used_other: Yup.string().trim(),
  purchase_reason: Yup.string().trim(),
  purchase_reason_other: Yup.string().trim(),
  product_main_use: Yup.string().trim(),
  product_main_use_other: Yup.string().trim(),
  property_shade: Yup.string().trim(),
  shelta_aware: Yup.string().trim(),
  purchase_satisfaction: Yup.string().trim(),
});

/* eslint-disable-next-line */
export interface HoldingSingleQuestionnaireViewProps {}

type ProductQuestionnaireResponses = {
  [key: string]: string;
};

const HoldingSingleQuestionnaireView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const holdingId = location.pathname.split('/')[2];
  const {
    data: { consumer_private },
  } = useMyConsumerPersona();
  const [product, setProduct] = useState<DTVendable>();
  const { getSingleHolding } = useGetConsumerHoldings(
    consumer_private?.docid || ''
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responses, setResponses] = useState<ProductQuestionnaireResponses>({});

  // const { body, isReady, update, commit } = useConsumerHoldingEditor(
  //   consumer_private?.docid ?? '',
  //   holdingId
  // );

  const { body, status, isReady, commit, update } =
    usePortalMultipleDocumentEditor({
      consumer_private: {
        type: DR_Consumer_Private,
        docid: consumer_private?.docid || '',
      },
      holding_manufacturer: {
        type: DR_Holding_Manufacturer,
        docid: holdingId || '',
      },
    });

  const CURRENT_QUESTIONS = HOLDING_QUESTIONNAIRE.filter((question) => {
    if (
      question.series_limitation &&
      !question.series_limitation.includes(product?.freeform?.customerCategory)
    ) {
      return false;
    } else {
      return true;
    }
  });

  if (
    !consumer_private?.gender ||
    !consumer_private?.living_situation ||
    !consumer_private?.type_of_dwelling ||
    !consumer_private?.work_status
  ) {
    CURRENT_QUESTIONS.push(...CUSTOMER_QUESTIONNAIRE);
  }

  const initialValues: ProductQuestionnaireResponses = {};
  CURRENT_QUESTIONS.forEach((question) => {
    initialValues[question.name] = '';
  });

  const yupDefinition = Yup.string().trim();
  if (CURRENT_QUESTIONS[currentQuestionIndex]?.options) {
    yupDefinition.required('Please select an option');
  }
  const validationSchema = Yup.object().shape({
    [CURRENT_QUESTIONS[currentQuestionIndex]?.name]: yupDefinition,
  });

  const onSubmit = async (values: ProductQuestionnaireResponses) => {
    setIsSubmitting(true);
    setResponses({ ...responses, ...values });
    if (currentQuestionIndex < CURRENT_QUESTIONS.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // Submit the form
      const formatValues: { [key: string]: { [key: string]: string | null } } =
        {};

      HOLDING_QUESTIONNAIRE.forEach((question) => {
        const value = values[question.name] || '';
        const type = question.type;
        if (formatValues[type]) {
          formatValues[type][question.name] = value;
        } else {
          formatValues[type] = { [question.name]: value };
        }
      });

      CUSTOMER_QUESTIONNAIRE.forEach((question) => {
        const value = values[question.name] || null;
        const type = question.type;
        if (formatValues[type]) {
          formatValues[type][question.name] = value;
        } else {
          formatValues[type] = { [question.name]: value };
        }
      });

      if (isReady && body.consumer_private && body.holding_manufacturer) {
        try {
          await update({
            holding_manufacturer: {
              ...body.holding_manufacturer,
              questionnaire: {
                ...body.holding_manufacturer.questionnaire,
                ...formatValues.manufacturer,
              },
            },
            consumer_private: {
              ...body.consumer_private,
              ...(formatValues?.consumer && {
                gender:
                  formatValues.consumer.gender || body.consumer_private.gender,
                living_situation:
                  formatValues.consumer.living_situation ||
                  body.consumer_private.living_situation,
                type_of_dwelling:
                  formatValues.consumer.type_of_dwelling ||
                  body.consumer_private.type_of_dwelling,
                work_status:
                  formatValues.consumer.work_status ||
                  body.consumer_private.work_status,
              }),
            },
          });
          await commit();

          toast.success('Successfully updated product information.');
          navigate(ROUTE_NAME.PRODUCTS + '/' + holdingId + '/' + 'details');
        } catch (e) {
          console.log(e);
        } finally {
          setIsSubmitting(false);
        }
      }
    }
    setIsSubmitting(false);
  };

  const getProductDetails = async (docid: string) => {
    const facetFilters = [];
    facetFilters.push({
      name: 'mfr',
      value: `M:${t('tenantLink')}`,
    });

    const options = {
      hitsPerPage: 25,
      page: 0,
      facetFilters,
    };
    const data = await SearchVendablesByDocid(docid, options);
    setProduct(data.items[0]);
  };

  const question = CURRENT_QUESTIONS[currentQuestionIndex];

  if (!product) {
    void (async () => {
      const holding = await getSingleHolding(holdingId);
      if (holding instanceof Error) console.log('holding', holding);
      else {
        if (holding.vendableId) await getProductDetails(holding.vendableId);
      }
    })();
  }

  if (!product) return <LoadingSpinner size="xs" extraClasses="mt-5" />;

  return (
    <div className="flex flex-wrap items-start">
      <div className="w-full">
        <DesktopContainer
          title="Product improvement survey"
          loading={isSubmitting}
          //goBack={() => navigate(-1)}
        >
          <div className="mb-4">
            <ProgressBar
              width={
                question
                  ? (
                      (currentQuestionIndex / (CURRENT_QUESTIONS.length - 1)) *
                      100
                    ).toString()
                  : '0'
              }
            />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, values }) =>
              question && (
                <Form className="flex flex-col font-nunito gap-6">
                  <div key={question.name}>
                    <div className="mb-4 text-lg font-semibold font-caudex">
                      {currentQuestionIndex + 1}. {question.label}
                    </div>
                    <div className="flex flex-col font-nunito gap-3">
                      {question?.options &&
                        question.options.map((option, v) => {
                          return (
                            <Input
                              key={question.name + v}
                              type="radio"
                              name={question.name}
                              settings={{
                                radioValue: option,
                                radioLabel: option,
                                values: values,
                                format: 'button',
                              }}
                            />
                          );
                        })}
                    </div>
                    {values[
                      question?.name as keyof ProductQuestionnaireResponses
                    ] === 'Other' && (
                      <div className="mt-2">
                        <Input
                          type="text"
                          name={question.name + '_other'}
                          settings={{}}
                        />
                      </div>
                    )}
                    {question.name === 'purchase_other_details' && (
                      <Input
                        type="textarea"
                        name="purchase_other_details"
                        settings={{}}
                        label="Is there anything you wish to share about this purchase?"
                      />
                    )}
                  </div>
                  <Button
                    kind="primary"
                    type="submit"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      isSubmitting ||
                      (!values[question.name] && question.options !== undefined)
                    }
                    loading={isSubmitting}
                  >
                    {currentQuestionIndex < CURRENT_QUESTIONS.length - 1
                      ? 'Continue'
                      : 'Submit'}
                  </Button>
                </Form>
              )
            }
          </Formik>
        </DesktopContainer>
      </div>
    </div>
  );
};

export default HoldingSingleQuestionnaireView;
