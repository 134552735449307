import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import ROUTE_NAME from './url-constants';

const PublicRoutes = () => {
  const contextValues = useContext(UserContext);

  return contextValues !== null &&
    !contextValues.isLoading &&
    (contextValues.user === null ||
      contextValues.consumerPersonaId === 'BANNED') ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTE_NAME.DASHBOARD} />
  );
};

export default PublicRoutes;
