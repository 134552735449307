import {
  DocumentCheckIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { CaseFlowCaseEditingID, useCaseFlowCase } from '@rabbit/bizproc/react';
import {
  getStationByKey,
  StepsProgress,
} from '@rabbit/elements/shared-components';

export interface CaseStepsProgressProps {
  personaId: any;
  currentCaseId: string;
}

export function CaseStepsProgress(props: CaseStepsProgressProps) {
  const { personaId, currentCaseId } = props;

  const caseId: CaseFlowCaseEditingID = {
    type: 'cfc' as const,
    case: currentCaseId ?? ('' as const),
    operating_persona: personaId ?? '',
    // DEV-502, this is only used in Olive for now and it just fetches the case state to display it to the consumer who's accessing it.
    // ! Warning: if this component is ever used on SAGE, it'll need to be updated to receive the principal_persona from the parent component. -DC
    principal_persona: personaId ?? '',
    isNewCase: false,
  };

  const { caseFlowCase } = useCaseFlowCase(caseId);
  const caseState = caseFlowCase?.GetCaseState();

  const currentStation = getStationByKey(caseState as string);

  const steps = [
    {
      Icon: InformationCircleIcon,
      completed: currentStation && currentStation.id >= 1 ? true : false,
      title: 'Waiting for Review',
      // content: (
      //   <div className="font-nunito mt-2 text-xs text-gray-400">
      //     18/04/2023
      //     <br />
      //     9:30 am
      //   </div>
      // ),
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation && currentStation.id >= 2 ? true : false,
      title: 'Assessment',
      // content: (
      //   <div className="font-nunito mt-2 text-xs text-gray-400">
      //     19/04/2023
      //     <br />
      //     01:27 pm
      //   </div>
      // ),
    },
    {
      Icon: InformationCircleIcon,
      completed: currentStation && currentStation.id >= 6 ? true : false,
      title: 'Repair/Replace',
      //content: '',
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation && currentStation.id >= 10 ? true : false,
      title: 'Complete',
      //content: '',
    },
  ];

  return <StepsProgress steps={steps} />;
}

export default CaseStepsProgress;
