import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import { DTConsumer_Private } from '@rabbit/data/types';
import {
  CONTACT_OPTIONS,
  useManageConsumerProfile,
} from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';

const schema = Yup.object({
  first_name: Yup.string()
    .required('Please enter a first name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'First name must start with a capital letter and contain only letters.'
    ),
  last_name: Yup.string()
    .trim()
    .required('Please enter a last name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'Last name must start with a capital letter and contain only letters.'
    ),
  consumer_telephone: Yup.string(),
  consumer_preferred_contact: Yup.array(),
});

interface PersonalInfoContainerProps {
  id: string;
  data: DTConsumer_Private;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

interface UserInfoValues {
  fullname: string;
  splitname: {
    first: string | undefined;
    last: string | undefined;
  };
  phone?: string;
  phone_e164?: string;
  preferred_contact?: string[];
}

function PersonalInfoContainer({
  id,
  data,
  setIsLoading,
}: PersonalInfoContainerProps) {
  const [buttonLoading, setButtonLoading] = useState(false);

  const { updateProfileData, isReady, status } = useManageConsumerProfile();

  const initialValues = {
    first_name: data ? data.splitname?.first : '',
    last_name: data ? data.splitname?.last : '',
    phone: data ? data.phone : '',
    phone_e164: data ? data.phone : '',
    preferred_contact: data ? data.preferred_contact : [],
  };

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const saveProfile = async (user: UserInfoValues) => {
    if (isReady) {
      try {
        await updateProfileData(user);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        toast.success('Personal information updated successfully!');
      } catch (error) {
        console.log('error', error);
      } finally {
        setHasSubmitted(true);
      }
    }
  };

  //TODO: save telephone and peferred contact method values when submiting
  const onSubmit = async (values: any) => {
    setButtonLoading(true);
    setIsLoading(true);
    try {
      const userInfo = {
        fullname: values.first_name + ' ' + values.last_name,
        splitname: {
          first: values.first_name,
          last: values.last_name,
        },
        phone: values.phone_e164 ?? values.phone,
        preferred_contact: values.preferred_contact,
      };
      userInfo && (await saveProfile(userInfo));
      setButtonLoading(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  // }, [hasSubmitted]);

  return (
    <div className="pt-[38px]">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ values, errors }) => (
          <Form className="flex flex-col gap-3">
            <div className="font-nunito">
              <p className="pb-2 text-base">First name</p>
              <Input
                type="text"
                name="first_name"
                settings={{
                  id: 'first_name',
                  placeholder: data?.splitname?.first,
                  hint: '*required',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>

            <div className="font-nunito">
              <p className="pb-2 text-base">Last name</p>
              <Input
                type="text"
                name="last_name"
                settings={{
                  id: 'last_name',
                  placeholder: data?.splitname?.last,
                  hint: '*required',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            <div className="font-nunito">
              <Input
                type="phone"
                name="phone"
                label="Phone number"
                settings={{
                  id: 'phone',
                  placeholder: 'Phone number',
                }}
              />
            </div>
            <Input
              type="select"
              name="preferred_contact"
              label="Preferred contact method"
              settings={{
                isMulti: true,
                id: 'preferred_contact',
                placeholder: 'Please select',
                options: CONTACT_OPTIONS,
                errors: errors,
              }}
            />
            <div className="flex pt-5">
              <Button
                kind="primary"
                type="submit"
                loading={buttonLoading}
                disabled={Object.keys(errors).length > 0}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PersonalInfoContainer;
