/* -------------------------------------------------------------------------- */
/*                   This entire component needs refactoring! - dc            */
/* -------------------------------------------------------------------------- */

import classNames from 'classnames';
import { BlogCard, CardImageBg } from '@rabbit/elements/shared-components';
//images for bike demo, delete after
import sug1 from '../../assets/images/sug1.jpg';
import sug2 from '../../assets/images/sug2.jpg';
import sug3 from '../../assets/images/sug3.png';
import sug4 from '../../assets/images/sug4.jpg';
import other1 from '../../assets/images/other1.jpg';
import other3 from '../../assets/images/other3.jpg';
import other4 from '../../assets/images/other4.jpg';
import { useNavigate } from 'react-router-dom';

export interface LayoutGridFourProps {
  kind:
    | 'uploads'
    | 'suggestions'
    | 'otherSuggestions'
    | 'inProgress'
    | 'productSuggestions';
  items?: Array<[]>;
}

const dynamicStyles = {
  gridLayoutFour:
    'grid grid-cols-1 gap-5 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-5 lg:grid lg:grid-cols-2 lg:grid-rows-2 lg:gap-5',
  gridLayoutTwo: 'md:grid md:grid-rows-1 md:grid-cols-2 md:gap-5',
  gridFourOrFlexScroll:
    'flex overflow-x-scroll gap-2 md:grid md:grid-cols-2 md:gap-5 lg:overflow-x-hidden',
};

export function LayoutGrid({ kind, items }: LayoutGridFourProps) {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(``, {
        [`${dynamicStyles.gridLayoutFour}`]:
          kind === 'suggestions' || kind === 'otherSuggestions',
        [`${dynamicStyles.gridLayoutTwo}`]:
          kind === 'inProgress' || kind === 'productSuggestions',
        [`${dynamicStyles.gridFourOrFlexScroll}`]: kind === 'uploads',
      })}
    >
      {/* {kind === 'uploads' && (
        <>
          {items?.slice(0, 4).map((item: ConsumerHoldingSummaryShape) => {
            const parseData = {
              prodId: item.ean,
              prodName: item.title,
              category: item.category,
              brand: item.brand,
              img: item.img,
              purchasedFrom: item.full_purchase_location,
              remainingWarrantyDays: 0,
            };
            return (
              <div className="min-w-[290px] md:w-full">
                <CardGeneral
                  onClick={() => console.log('test')}
                  productData={parseData}
                  remainingDays={45}
                  imageUrl={'https://picsum.photos/83/83'}
                />
              </div>
            );
          })}
        </>
      )} */}
      {kind === 'suggestions' && (
        <>
          <CardImageBg
            text={'How to Protect Your Outdoor Furniture from Sun?'}
            tag={'#SunProtection'}
            imageUrl={sug1}
            dailyMsg={false}
            onClick={() => navigate('/article/protect-your-outdoor-furniture')}
          />
          <div className="hidden md:grid">
            <CardImageBg
              text={
                'Revamp Your Outdoor Patio: 10 Steps to Enhance and Enjoy Your Furniture Setup'
              }
              tag={'#Patio'}
              imageUrl={sug2}
              dailyMsg={false}
              onClick={() => navigate('/article/revamp-your-outdoor-patio')}
            />
          </div>
          <div className="hidden md:grid">
            <CardImageBg
              text={'How to Protect Outdoor Furniture for Longevity'}
              tag={'#Protection'}
              imageUrl={sug3}
              dailyMsg={false}
              onClick={() => navigate('/article/preserve-protect-prolong')}
            />
          </div>
          <div className="hidden md:grid">
            <CardImageBg
              text={
                'Revamp Your Outdoor Space: Embrace the Hottest Summer Furniture Trends of 2023'
              }
              tag={'#Trends'}
              imageUrl={sug4}
              dailyMsg={false}
              onClick={() => navigate('/article/revamp-your-outdoor-space')}
            />
          </div>
        </>
      )}
      {kind === 'productSuggestions' && (
        <>
          <CardImageBg
            text={
              'Revamp Your Outdoor Patio: 10 Steps to Enhance and Enjoy Your Furniture Setup'
            }
            tag={'#Patio'}
            imageUrl={sug2}
            dailyMsg={false}
            onClick={() => navigate('/article/revamp-your-outdoor-patio')}
          />
          <div className="hidden md:grid">
            <CardImageBg
              text={'How to Protect Your Outdoor Furniture from Sun?'}
              tag={'#SunProtection'}
              imageUrl={sug1}
              dailyMsg={false}
              onClick={() =>
                navigate('/article/protect-your-outdoor-furniture')
              }
            />
          </div>
        </>
      )}
      {kind === 'otherSuggestions' && (
        <>
          <BlogCard
            imageUrl={other1}
            label={'#Rain'}
            text={
              'Rainproofing Your Outdoor Oasis: 4 Essential Tips to Safeguard Your Furniture from Rain'
            }
            onClick={() => navigate('/article/rainproofing-your-outdoor-oasis')}
          />
          <BlogCard
            imageUrl={other3}
            label={'#Materials'}
            text={
              'A Guide to Selecting Suitable Materials for Your Outdoor Furniture'
            }
            onClick={() =>
              navigate('/article/what-materials-are-best-for-outdoor-furniture')
            }
          />
          <div className="hidden md:grid">
            <BlogCard
              imageUrl={other4}
              label={'#Covers'}
              text={
                'How to Analyse Your Garden Surface When Investing in Outdoor Furniture?'
              }
              onClick={() =>
                navigate('/article/how-analyse-your-garden-surface')
              }
            />
          </div>
          <div className="hidden md:grid">
            <BlogCard
              imageUrl={other1}
              label={'#Protection'}
              text={'Why Should You Cover Your Outdoor Furniture?'}
              onClick={() =>
                navigate('/article/why-should-you-cover-your-outdoor-furniture')
              }
            />
          </div>
        </>
      )}
    </div>
  );
}

export default LayoutGrid;

// Unused

// {kind === 'inProgress' && (
//   <>
//     <CardGeneral
//       onClick={() => console.log('test')}
//       repairData={sampleRepairData[0]}
//       imageUrl={sampleRepairData[0].img}
//     />
//     <div className="hidden md:grid">
//       {/* Hidden for Motorbikes demos, put it back later
//       <CardGeneral
//         onClick={() => console.log('test')}
//         repairData={sampleRepairData[0]}
//         imageUrl={'https://picsum.photos/83/83'}
//       /> */}
//     </div>
//   </>
// )}
