import { SeeMoreProducts } from '@rabbit/elements/shared-components';

export interface ProductSuggestionsItemProps {
  option: string;
  submitSearchCallback?: (value: string) => void;
}

export function ProductSuggestionsItem({
  option,
  submitSearchCallback,
}: ProductSuggestionsItemProps) {
  return (
    <div
      className="flex w-full cursor-pointer items-center justify-between px-4 py-4 hover:bg-gray-200"
      onClick={() => submitSearchCallback && submitSearchCallback(option)}
    >
      <div className="font-nunito w-full">{option}</div>
      <div className="ml-[6px]">
        <SeeMoreProducts hasText={false} text={option} />
      </div>
    </div>
  );
}

export default ProductSuggestionsItem;
