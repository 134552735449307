import { PlusIcon } from '@heroicons/react/24/outline';

export interface ShowMoreProps {
  actionText: string;
  onClick: (value: string) => void;
  category: string;
}

export function ShowMore({ actionText, onClick, category }: ShowMoreProps) {
  return (
    <div>
      <div
        onClick={() => onClick(category)}
        className="flex h-[159px] w-[225px] flex-col items-center justify-center rounded-xl bg-gray-50 bg-cover"
      >
        <PlusIcon className="h-[28px] w-[28px]" />
        <p className="font-nunito mt-5 text-base font-normal text-black">
          {actionText}
        </p>
      </div>
    </div>
  );
}

export default ShowMore;
