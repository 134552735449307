import { useMyConsumerPersona, DR_CaseFlowCase } from '@rabbit/data/portal';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import {
  QueryDocumentSnapshot,
  query,
  where,
  DocumentData,
} from 'firebase/firestore';
import { DTCaseFlowCase } from '@rabbit/data/types';
import {
  MakeCaseEditingID,
  ReactCaseFlowCase,
} from '../../caseflow/react-case-flow-case';

/** For use in Olive. Fetches a list of all the cases associated with a consumer persona and returns their ids. */

export function useGetConsumerCases() {
  const consumerPersona = useMyConsumerPersona();

  const openConsumerCaseList = useFirestoreQuery(
    ['openConsumerCases', consumerPersona.personaId],
    query(
      DR_CaseFlowCase.collection(),
      where('actors.consumer', '==', consumerPersona.personaId),
      where('state', '!=', 'closed')
    ),
    { subscribe: true }
  );

  const closedConsumerCaseList = useFirestoreQuery(
    ['closedConsumerCases', consumerPersona.personaId],
    query(
      DR_CaseFlowCase.collection(),
      where('actors.consumer', '==', consumerPersona.personaId),
      where('state', '==', 'closed')
    ),
    { subscribe: true }
  );

  const getCaseIdsFromDocs = (
    persona: string,
    docs: QueryDocumentSnapshot<DTCaseFlowCase>[]
  ) => {
    // TODO: Case editing ID does not consider principal/delegate in consumer. There is only one level. Will need to be fixed for DEV-502.
    return docs.map((doc) =>
      MakeCaseEditingID(persona, persona, doc.get('docid'))
    );
  };

  const openCaseIdsList = consumerPersona.personaId
    ? getCaseIdsFromDocs(
        consumerPersona.personaId,
        (openConsumerCaseList.data?.docs ||
          []) as QueryDocumentSnapshot<DTCaseFlowCase>[]
      )
    : [];

  const closedCaseIdsList = consumerPersona.personaId
    ? getCaseIdsFromDocs(
        consumerPersona.personaId,
        (closedConsumerCaseList.data?.docs ||
          []) as QueryDocumentSnapshot<DTCaseFlowCase>[]
      )
    : [];

  return { openCaseIds: openCaseIdsList, closedCaseIds: closedCaseIdsList };
}
