import classNames from 'classnames';
import {
  ComputerDesktopIcon,
  WrenchIcon,
  HomeIcon,
  GlobeEuropeAfricaIcon,
} from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import {
  ButtonIconVertical,
  IWarrantyLogoIcon,
} from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../../utils/url-constants';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface NavBarProps {}

const navBarOptions = [
  { id: '1', path: ROUTE_NAME.DASHBOARD, label: 'Home', icon: HomeIcon },
  {
    id: '2',
    path: ROUTE_NAME.PRODUCTS,
    label: 'Products',
    icon: ComputerDesktopIcon,
  },
  { id: '3', path: ROUTE_NAME.REPAIRS, label: 'Repairs', icon: WrenchIcon },
  {
    id: '4',
    path: ROUTE_NAME.IMPACT,
    label: 'Impact',
    icon: GlobeEuropeAfricaIcon,
  },
];

const dynamicStyles = {
  header:
    'z-30 fixed md:relative md:top-0 bottom-0 md:bottom-auto left-0 bg-gray-50 md:bg-white w-full border-b-[1px]',
};

export function NavBar(props: NavBarProps) {
  const navigate = useNavigate();

  //the following piece of code aims to hide the navigation, in case there is a query string where it checks if the open TAB is the CHAT
  const [hide, setHide] = useState(false);
  const [paramValue, setParamValue] = useState<string>('');

  const QUERY_PARAM = 'tab';
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const currentValue = searchParams.get(QUERY_PARAM) || '';
    if (currentValue !== paramValue) {
      setParamValue(currentValue);
      if (currentValue === 'chat') setHide(true);
      else setHide(false);
    }
  }, [window.location.search, QUERY_PARAM, paramValue]);

  return (
    <header
      className={classNames(dynamicStyles.header, {
        ['hidden md:block']: hide,
      })}
    >
      <div className="flex w-full justify-around py-2 px-8 md:m-[0_auto] md:max-w-[1024px] md:items-center md:justify-start md:gap-[40px] md:py-9 lg:gap-[63px] lg:px-0">
        <NavLink to="/" className="hidden md:block">
          <IWarrantyLogoIcon className="" />
        </NavLink>
        {navBarOptions.map((item, index) => (
          <NavLink to={item.path} className="no-underline" key={index}>
            {({ isActive }) => {
              return (
                <ButtonIconVertical
                  kind="navbar"
                  Icon={item.icon}
                  label={item.label}
                  isActive={isActive}
                />
              );
            }}
          </NavLink>
        ))}
        <NavLink
          to={ROUTE_NAME.ACCOUNT}
          className="block no-underline md:hidden"
        >
          {({ isActive }) => {
            return (
              <ButtonIconVertical
                kind="navbar"
                Icon={UserCircleIcon}
                label="Account"
                isActive={isActive}
              />
            );
          }}
        </NavLink>
        <div className="hidden flex-grow justify-end md:flex">
          <UserCircleIcon
            className="text-primary-900 h-8 w-8 cursor-pointer"
            onClick={() => navigate(`${ROUTE_NAME.ACCOUNT}`)}
          />
        </div>
      </div>
    </header>
  );
}

export default NavBar;
