import {
  Button,
  LoadingSpinner,
  ProductInformation,
  AuthContainer,
} from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../../../utils/url-constants';
import { useNavigate } from 'react-router-dom';
import { DTVendable } from '@rabbit/data/types';
import { useAppInfo } from '@rabbit/olive/utils/helpers';

export interface VendableScanSingleViewProps {
  // barcode: string;
  data: DTVendable;
}

export function VendableScanSingleView({ data }: VendableScanSingleViewProps) {
  const navigate = useNavigate();
  const appInfo = useAppInfo();

  if (!data.img || !data.full || !data.series)
    return <LoadingSpinner size="lg" isFullScreen label="Loading..." />;

  return (
    <AuthContainer loading={false} appInfo={appInfo}>
      <div className="rounded-lg md:max-h-[566px]">
        <div className="mb-8 text-center">
          <div className="font-caudex text-xl font-bold md:text-2xl">
            Is this your product?
          </div>
        </div>
        <ProductInformation
          imageUrl={data.img[0]}
          title={data.full}
          subtitle={data.series}
        />
        <div className="mx-auto mt-11 flex flex-col">
          <Button
            children={`Register your product`}
            kind={'primary'}
            onClick={() =>
              navigate(
                ROUTE_NAME.PRODUCTS_PREVIEW + '/' + data.docid + '/register'
              )
            }
          />
          <div className="mt-5 flex">
            <Button
              children={'Scan again'}
              kind={'outline'}
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </AuthContainer>
  );
}

export default VendableScanSingleView;
