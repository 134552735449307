import { Button, Input } from '@rabbit/elements/shared-components';
import { useConsumerHoldingEditor } from '@rabbit/bizproc/react';
import { t } from 'i18next';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import {
  UploadedFileCategories,
  DocTypeShapeTypes,
} from '@rabbit/elements/shared-types';
import { OliveFileUploader } from '@rabbit/olive/components/organisms/upload-wrapper/OliveFileUploader';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import ROUTE_NAME from '@rabbit/olive/utils/url-constants';
import { useCallback, useState } from 'react';

interface FormValuesShape {
  serial_number: string;
}

export function CompleteHoldingRegistration({
  setIsLoading,
  holdingId,
}: {
  holdingId: string;
  setIsLoading: (loading: boolean) => void;
}) {
  const navigate = useNavigate();
  const persona = useMyConsumerPersona();
  const { body, update, commit } = useConsumerHoldingEditor(
    persona?.data?.consumer_private?.docid ?? '',
    holdingId
  );
  const [fileUploaded, setFileUploaded] = useState(false);

  const {
    public: holding_public,
    manufacturer: holding_manufacturer,
    private: holding_private,
  } = body;

  const onSubmit = async (values: FormValuesShape) => {
    const { serial_number } = values;
    setIsLoading(true);
    update({
      private: {
        ...holding_private!,
        serial: serial_number,
      },
      public: {
        ...holding_public!,
        serial: serial_number
      },
      manufacturer: {
        ...holding_manufacturer!,
        serial: serial_number
      }
    });
    await commit();
    setIsLoading(false);
    toast.success(t('Holding registration completed'));
    navigate(`${ROUTE_NAME.PRODUCTS}/${body?.public?.docid}/details`);
  };

  const renderSerialNumberProofUploader = useCallback(() => {
    if (body.private?.serial_proof && body.private?.serial_proof?.length > 0) {
      setFileUploaded(true);
    }
    return (
      <OliveFileUploader
        label={t('Photo of serial number*')}
        identifiers={{
          category: UploadedFileCategories.SerialNumberProof,
          docType: { docid: holdingId, type: DocTypeShapeTypes.Holding },
          personaId: persona?.personaId ?? '',
        }}
        shouldAutoUpdateDocs={true}
        onUploadCompleted={(files) => {
          if (files.length > 0) setFileUploaded(true);
        }}
        currentFiles={body.private?.serial_proof}
        maxFiles={1}
        accepts={['image/*']}
      />
    );
  }, [body.private?.serial_proof]);

  const initialValues = {
    serial_number: body.private?.serial ?? '',
  } as FormValuesShape;

  return (
    <div className="wrapper">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          serial_number: Yup.string().required(
            t('Please enter a serial number')
          ),
        })}
      >
        {(props) => (
          <Form className="pt-6">
            <Input
              type="text"
              label={t('Serial number*')}
              name="serial_number"
              settings={{
                placeholder: t('Serial number'),
                hint: '*required',
                disabled: !!body.private?.serial,
              }}
            />
            {renderSerialNumberProofUploader()}
            <div className="mt-4">
              <Button
                className="w-full"
                kind="primary"
                disabled={
                  !fileUploaded || props.errors.serial_number !== undefined
                }
                type="submit"
              >
                {t('Complete registration')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
