import { useNavigate } from 'react-router-dom';
import { DesktopContainer } from '@rabbit/elements/shared-components';
import ProfileContainer from '../../templates/Account/ProfileContainer';

/* eslint-disable-next-line */
export interface AccountProps {}

export function AccountView(props: AccountProps) {
  const navigate = useNavigate();

  return (
    <DesktopContainer title="Profile" goBack={() => navigate(-1)}>
      <ProfileContainer />
    </DesktopContainer>
  );
}

export default AccountView;
