import {
  FBD_Consumer_Private,
  FBD_Holding_Manufacturer,
} from '@rabbit/data/types';

export function useConsumerQuestionnnaire() {
  //writes?

  //get questionnaire for holding
  //get all questionnaires
  //get consumer data

  async function getConsumerData(consumerId: string) {
    const consumer = await FBD_Consumer_Private.get(consumerId);
    if (!consumer) throw new Error('Unable to fetch consumer data!');
    return consumer;
  }

  /** Fetches the responses to the questionnaire for a single holding from their holdingId.  */
  async function getSingleQuestionnaire(holdingId: string) {
    const holding_manufacturer = await FBD_Holding_Manufacturer.get(holdingId);
    if (!holding_manufacturer) throw new Error('Unable to fetch holding data!');
    return {
      holding_manufacturer,
      questionnaire: holding_manufacturer.questionnaire,
    };
  }

  async function getAllQuestionnaires(consumerId: string) {
    const consumer = await getConsumerData(consumerId);

    const qDataArr = [];
    for (const holding_id of consumer.holdings || []) {
      const qData = await getSingleQuestionnaire(holding_id);
      qDataArr.push(qData);
    }
    return qDataArr;
  }

  return { getConsumerData, getSingleQuestionnaire, getAllQuestionnaires };
}
