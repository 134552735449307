import {
  DTHolding_Manufacturer,
  DTHolding_Private,
  DTHolding_Public,
  DTVendable,
  FBD_Consumer_Private,
  FBD_HoldingProxy,
  FBD_Vendable,
  SELF_REGISTERED_VENDABLE,
} from '@rabbit/data/types';
import { OurDateTime } from '@rabbit/utils/ts';

/** Creates a HoldingProxy document from full Holding documents.
 *  Used when creating the proxy at the same time as the holding, which should be
 *  almost always the case. Will make a similar one using just the holdingids if necessary -DC
 */
export async function Holding_CreateProxy_FromHoldingDocs(
  holding_public: DTHolding_Public,
  holding_private: DTHolding_Private,
  holding_manufacturer: DTHolding_Manufacturer
) {
  if (!holding_public || !holding_manufacturer || !holding_private)
    throw new Error('Creating a proxy requires holding documents');

  // Fetch the vendable data
  const vendableData: DTVendable | null =
    holding_public.vendable === SELF_REGISTERED_VENDABLE
      ? null
      : await FBD_Vendable.get(holding_public.vendable);

  // Fetch consumer data
  const consumerData = await FBD_Consumer_Private.get(holding_public.consumer);
  if (!consumerData) throw new Error('Unable to fetch consumer data');

  // Create the proxy
  let holdingProxy = FBD_HoldingProxy.empty();

  // Set the docId
  holdingProxy.docid = `${holding_public.docid}_${holding_public.vendable}`;

  const {
    vendable,
    consumer,
    purchase_time,
    active_cases,
    closed_cases,
    serial,
    deleted,
    self_registration,
  } = holding_public;

  const { register_time, questionnaire } = holding_manufacturer;

  const { created_time, purchase_price, purchase_location, purchase_country } =
    holding_private;

  let title, full, img, images, mpn, manual, category, brand, mfr;

  if (vendableData) {
    ({ title, full, img, images, mpn, manual, brand, mfr } = vendableData);
    category =
      vendableData?.freeform?.customerCategory ??
      vendableData.category?.[0] ??
      '-';
  }

  const { email, fullname } = consumerData;

  holdingProxy = {
    ...holdingProxy,
    consumer,
    consumer_email: email,
    consumer_name: fullname,
    vendable,
    purchase_time: purchase_time ?? 0,
    active_cases: active_cases ?? [],
    closed_cases: closed_cases ?? [],
    serial: serial ?? '',
    warranties: [],
    register_time:
      register_time > 0 ? register_time : OurDateTime.nowUTCTimestamp(),
    created_time:
      created_time > 0 ? created_time : OurDateTime.nowUTCTimestamp(),
    purchase_price: purchase_price ?? { amount: 0, currency: '-' },
    purchase_location: purchase_location ?? '',
    purchase_country: purchase_country ?? '',
    title: title ?? '',
    full: full ?? '',
    category: category ?? '',
    img: img && img?.length > 0 ? img : [],
    images: images && images?.length > 0 ? images : [],
    brand: brand ? brand : self_registration ? self_registration.brand : '-',
    mpn: mpn ?? '',
    manual: manual ?? [],
    questionnaire: questionnaire ?? {},
    deleted,
    self_registration: self_registration ?? null,
    mfr: mfr ?? '',
  };

  await FBD_HoldingProxy.set(holdingProxy);

  return holdingProxy.docid;
}
