import { useMyConsumerPersona } from '@rabbit/data/portal';
import { OliveHoldingRegistrationFormData } from '@rabbit/elements/shared-types';
import { useOliveAPI } from '../api/useOliveAPI';

export function useCreateHoldingFromVendable(vendableDocid: string) {
  const { registerConsumerHolding } = useOliveAPI();

  // Get the consumer persona
  const consumerPersona = useMyConsumerPersona();
  if (!consumerPersona.data.consumer_private) {
    throw new Error('Consumer persona not found');
  }

  async function createUserHolding(formData: OliveHoldingRegistrationFormData) {
    if (!vendableDocid) {
      throw new Error('Vendable docid is required');
    }

    const holdingId = await registerConsumerHolding({
      vendableDocid,
      formData,
    });
    return holdingId;
  }

  return createUserHolding;
}
