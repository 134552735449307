import {
  DesktopContainer,
  LoadingSpinner,
  WarrantyInfo,
} from '@rabbit/elements/shared-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConsumerHoldingEditor } from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { getWarrantyInfo, useAppInfo } from '@rabbit/olive/utils/helpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface HoldingSingleWarrantyInfoViewProps {}

export interface ItemProps {
  id?: string;
  title: string;
  description?: string;
  included?: string;
}

export interface InfoInterface {
  title: string;
  type: string;
  items?: ItemProps[];
  children?: any;
}

export function HoldingSingleWarrantyInfoView(
  props: HoldingSingleWarrantyInfoViewProps
) {
  const location = useLocation();
  const { t } = useTranslation();
  const holdingId = location.pathname.split('/')[2];
  const persona = useMyConsumerPersona();
  const { body } = useConsumerHoldingEditor(
    persona?.personaId ?? '',
    holdingId
  );

  if (!body) return <LoadingSpinner size="md" extraClasses="mt-5" />;

  const navigate = useNavigate();
  const appInfo = useAppInfo();

  const [warrantyInfo, setWarrantyInfo] = useState<InfoInterface[]>([]);
  const [warrantyTerms, setWarrantyTerms] = useState<InfoInterface[]>([]);

  useEffect(() => {
    const getWarranty = async () => {
      const warranty = await getWarrantyInfo(appInfo.tenantLink);
      if (warranty) {
        setWarrantyInfo(warranty.warranty_info);
        setWarrantyTerms(warranty.warranty_terms);
      }
    };
    getWarranty();
  }, []);

  return (
    <div className="flex flex-wrap items-start justify-center">
      <div className="w-full md:basis-1/2">
        <DesktopContainer
          title={
            appInfo.name === 'NuCover'
              ? 'Care Plan Summary'
              : 'Warranty Information'
          }
          goBack={() => navigate(-1)}
        >
          {warrantyInfo
            .filter(
              (data: InfoInterface) => data.title !== 'Consumer Guarantee'
            )
            .map((data: InfoInterface, index: number) => (
              <WarrantyInfo key={index} data={data} />
            ))}
        </DesktopContainer>
      </div>
      {warrantyTerms?.length !== 0 && (
        <div className="w-full md:basis-1/2">
          <DesktopContainer title={'Warranty terms'}>
            {warrantyTerms.map((data: InfoInterface, index: number) => (
              <WarrantyInfo key={index} data={data} />
            ))}
          </DesktopContainer>
        </div>
      )}
      <div className="pt-8 lg:px-8">
        {warrantyInfo
          .filter((data: InfoInterface) => data.title === 'Consumer Guarantee')
          .map((data: InfoInterface, index: number) => (
            <WarrantyInfo key={index} data={data} />
          ))}
      </div>
    </div>
  );
}

export default HoldingSingleWarrantyInfoView;
