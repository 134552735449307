import { LIST_COUNTRIES, useFileStorage } from '@rabbit/bizproc/react';
import {
  Button,
  DesktopContainer,
  Input,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import {
  DR_Holding_Manufacturer,
  DR_Holding_Private,
  DR_Holding_Proxy,
  DR_Holding_Public,
  useMyConsumerPersona,
  usePortalMultipleDocumentEditor,
} from '@rabbit/data/portal';
import {
  FBD_Retailer_Public,
  Money,
  SELF_REGISTERED_VENDABLE,
  SelfRegistration,
  UserUploadedDocument,
  Warranty,
} from '@rabbit/data/types';
import { fromUnixTime } from 'date-fns';
import { Formik, Form, FormikProps } from 'formik';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { OliveFileUploader } from '@rabbit/olive/components/organisms/upload-wrapper/OliveFileUploader';

interface FormValuesShape {
  product_name: string;
  product_brand: string;
  product_category: string;
  product_image: UserUploadedDocument | null;
  purchase_date: Date | number;
  amount_paid: Money;
  warranty_term: number;
  store: {
    country: string;
    docid: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  purchase_country: string;
  proof_of_purchase: UserUploadedDocument[];
}

const validationSchema = Yup.object().shape({
  product_name: Yup.string().trim().required('Please enter a product name'),
  product_brand: Yup.string().trim().required('Please enter a product brand'),
  product_category: Yup.string()
    .trim()
    .required('Please enter a product category'),
  product_image: Yup.mixed(),
  purchase_date: Yup.date(),
  amount_paid: Yup.mixed(),
  warranty_term: Yup.number(),
  store: Yup.mixed(),
  store_not_listed: Yup.boolean(),
  custom_store_name: Yup.string().trim(),
  purchase_country: Yup.string().trim(),
  proof_of_purchase: Yup.mixed(),
});

export default function SRVHoldingEditView() {
  const holdingId = window.location.pathname.split('/')[2];
  const { personaId } = useMyConsumerPersona() || {};
  const [loading, setLoading] = useState(false);
  const [locationLabel, setLocationLabel] = useState('Loading...');
  const navigate = useNavigate();
  const {
    uploadFiles,
    uploadedTempFiles,
    deleteUnusedTempFiles,
    deleteFile,
    clearFileFromState,
    updateSRVHoldingWithFiles,
    uploadTaskCat,
    clearAllTempFilesFromState,
  } = useFileStorage();
  const formikRef = useRef<FormikProps<FormValuesShape> | null>(null);
  const { body, isReady, update, commit } = usePortalMultipleDocumentEditor({
    holding_public: {
      type: DR_Holding_Public,
      docid: holdingId,
    },
    holding_private: {
      type: DR_Holding_Private,
      docid: holdingId,
    },
    holding_manufacturer: {
      type: DR_Holding_Manufacturer,
      docid: holdingId,
    },
  });

  const [warranty, setWarranty] = useState<Warranty | undefined>();

  useEffect(() => {
    (async () => {
      const proxy = await DR_Holding_Proxy.get(
        holdingId + '_' + SELF_REGISTERED_VENDABLE
      );
      const warranty = BL_Warranty.getLatestWarranty(proxy?.warranties);
      setWarranty(warranty);
    })().catch((err) => console.log(err));
  }, [holdingId]);

  const { holding_public, holding_private, holding_manufacturer } = body;
  const { purchase_time, self_registration } = holding_public || {};
  const {
    purchase_price,
    purchase_location,
    purchase_location_other,
    purchase_country,
    receipt,
  } = holding_private || {};

  const initialValues: FormValuesShape = {
    product_name: self_registration?.title ?? '',
    product_brand: self_registration?.brand ?? '',
    product_category: self_registration?.category_title ?? '',
    product_image: self_registration?.images?.[0] ?? null,
    purchase_date: purchase_time ? fromUnixTime(purchase_time) : 0,
    amount_paid: {
      amount: purchase_price?.amount ? Number(purchase_price?.amount) : 0,
      currency: purchase_price?.currency ?? 'AUD',
    },
    warranty_term: warranty?.duration.amount ?? 0,
    store: null,
    store_not_listed: !!purchase_location_other,
    custom_store_name: purchase_location_other ?? '',
    purchase_country: purchase_country ?? '',
    proof_of_purchase: receipt ?? [],
  };

  console.log('body', body);

  const onSubmit = async (values: any) => {
    setLoading(true);
    if (
      !holding_public ||
      !holding_private ||
      !holding_manufacturer ||
      !values
    ) {
      toast.error('Something went wrong, please try again later');
      return;
    }
    const newPurchasePrice: Money = {
      amount: values.amount_paid?.amount ?? purchase_price?.amount ?? 0,
      currency: values.amount_paid?.currency ?? purchase_price?.currency ?? '-',
    };

    const newSelfRegistration: SelfRegistration = {
      ...self_registration,
      title: values.product_name ?? self_registration?.title ?? '',
      brand: values.product_brand ?? self_registration?.brand ?? '',
      category_title:
        values.product_category ?? self_registration?.category_title ?? '',
    };

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? null
        : values.store?.docid ?? purchase_location ?? '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ?? purchase_location_other ?? ''
      : null;

    // todo: confirm if deep cloning is required
    if (isReady) {
      const new_holding_public = JSON.parse(JSON.stringify(holding_public));
      const new_holding_private = JSON.parse(JSON.stringify(holding_private));
      const new_holding_manufacturer = JSON.parse(
        JSON.stringify(holding_manufacturer)
      );
      try {
        await update({
          holding_public: {
            ...new_holding_public,
            purchase_time: values.purchase_date ?? purchase_time ?? 0,
            self_registration: newSelfRegistration,
          },
          holding_private: {
            ...new_holding_private,
            purchase_price: newPurchasePrice,
            purchase_location: newPurchaseLocation,
            purchase_location_other: newPurchaseLocationOther,
            purchase_country: values.purchase_country ?? purchase_country ?? '',
            receipt: receipt ?? [],
          },
          holding_manufacturer: {
            ...new_holding_manufacturer,
            purchase_proof: receipt ?? [],
            purchase_location: newPurchaseLocation,
            purchase_location_other: newPurchaseLocationOther,
            purchase_price: newPurchasePrice,
            purchase_country: values.purchase_country ?? purchase_country ?? '',
            purchase_time: values.purchase_date ?? purchase_time ?? 0,
          },
        });
        await commit();

        if (values.product_image) {
          if (self_registration?.images?.[0]) {
            const newFileSize = values.product_image?.metadata?.size;
            const newFileName = values.product_image?.ogFilename;
            self_registration.images.forEach((image) => {
              if (
                image.metadata?.size === newFileSize &&
                image.ogFilename === newFileName
              ) {
                // Image already exists, no need to upload
                return;
              }
            });
          }
          await updateSRVHoldingWithFiles(holdingId, [values.product_image]);
          clearAllTempFilesFromState();
        } else {
          await updateSRVHoldingWithFiles(holdingId, []);
        }

        toast.success('Successfully updated product information!');
        navigate(`/products/${holdingId}/details`);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const updateVendablePhoto = (value: string) => {
    void formikRef?.current?.setFieldValue('product_image', value);
  };

  useEffect(() => {
    const getRetailerLabel = async (locationId: string) => {
      if (!locationId) return undefined;
      const retailer = await FBD_Retailer_Public.get(locationId || '');
      return retailer?.name
        ? retailer?.name + ' - ' + retailer?.address?.town
        : undefined;
    };

    (async () => {
      if (purchase_location) {
        const locationLabel = await getRetailerLabel(purchase_location);
        setLocationLabel(locationLabel ?? 'Enter store name & location');
      } else {
        setLocationLabel('Enter store name & location');
      }
    })().catch((err) => console.log(err));
  }, [purchase_location]);

  useEffect(() => {
    return () => {
      void (async () => await deleteUnusedTempFiles())();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !holding_public ||
    !holding_private ||
    !holding_manufacturer ||
    !personaId
  )
    return <LoadingSpinner size="md" extraClasses="mt-5" />;

  if (!self_registration?.title) {
    const url = window.location.pathname.substring(
      0,
      window.location.pathname.lastIndexOf('/')
    );
    return <Navigate to={url + '/edit-info'} />;
  }

  return (
    <DesktopContainer
      title={'Edit information'}
      goBack={() => navigate(-1)}
      loading={loading}
    >
      <div className="flex flex-col gap-6 pb-8">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => await onSubmit(values)}
          innerRef={formikRef}
        >
          {({ errors, values }) => {
            return (
              <Form className="font-nunito flex flex-col gap-4">
                <Input
                  type="picture"
                  name="product_image"
                  settings={{
                    onRemove: () => updateVendablePhoto(undefined as any),
                    onChange: (value) => updateVendablePhoto(value),
                    personaId: personaId ?? '',
                    placeholder: values.product_image?.url ?? undefined,
                    uploadHandlers: {
                      category: 'srv_images',
                      uploadFiles,
                      uploadedTempFiles,
                      uploadTaskCat,
                      deleteFile,
                      clearFileFromState,
                    },
                  }}
                />
                <Input
                  label="Product name*"
                  type="text"
                  name="product_name"
                  settings={{
                    placeholder: 'Product name',
                    hint: '*required',
                  }}
                />
                <Input
                  label="Brand*"
                  type="text"
                  name="product_brand"
                  settings={{
                    placeholder: 'Brand name',
                    hint: '*required',
                  }}
                />
                <Input
                  type="text"
                  label="Category*"
                  name="product_category"
                  settings={{
                    id: 'product_category',
                    placeholder: 'Please select an option',
                    hint: '*required',
                  }}
                />
                <Input
                  label="Purchase date"
                  type="datepicker"
                  name="purchase_date"
                  settings={{
                    placeholder: 'Select',
                  }}
                />
                <Input
                  label="Amount paid"
                  type="currency"
                  name="amount_paid"
                  settings={{
                    placeholder: 'Amount',
                    currency:
                      values.amount_paid.currency ??
                      holding_private?.purchase_price?.currency ??
                      'AUD',
                  }}
                />
                <Input
                  type="warranty_term"
                  name="warranty_term"
                  label="Warranty term"
                  settings={{
                    id: 'warranty_term',
                    placeholder: '',
                  }}
                />
                <Input
                  label="Store"
                  type="autocomplete-location-retailer"
                  name="store"
                  settings={{
                    placeholder: locationLabel,
                    disabled: values.store_not_listed,
                  }}
                />
                <Input
                  type="checkbox"
                  name="store_not_listed"
                  settings={{
                    checkboxLabel: 'Store is not listed',
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
                {values.store_not_listed && (
                  <>
                    <Input
                      type="text"
                      name="custom_store_name"
                      settings={{
                        id: 'custom_store_name',
                        placeholder: 'Enter store name',
                      }}
                    />
                    <Input
                      type="select"
                      name="purchase_country"
                      settings={{
                        options: LIST_COUNTRIES,
                        placeholder:
                          'The country where the product was purchased',
                      }}
                    />
                  </>
                )}
                <OliveFileUploader
                  label="Proof of purchase"
                  identifiers={{
                    category: UploadedFileCategories.ConsumerProofPurchase,
                    docType: {
                      docid: holdingId,
                      type: DocTypeShapeTypes.Holding,
                    },
                    personaId: personaId ?? '',
                  }}
                  shouldAutoUpdateDocs={true}
                  accepts={['image/*', '.pdf']}
                />
                {/* <UploadFilesForm
                  label="Proof of purchase"
                  docId={holdingId ?? ''}
                  personaId={personaId ?? ''}
                  currentFiles={receipt ?? []}
                  accepts={['image/*', '.pdf']}
                  onFilesUploaded={onProofOfPurchaseUpdated}
                  uploadHandlers={{
                    uploadedFiles,
                    category: 'consumer_proof_purchase',
                    uploadFiles,
                    isUpdating,
                    uploadTaskCat,
                    uploadProgress,
                    deleteFile,
                    updateHoldingWithFiles,
                    clearFileFromHolding,

                  }}
                /> 
                <Heading kind="p">*required</Heading>
                */}
                <div className="flex flex-col gap-6">
                  <Button
                    kind="primary"
                    type="submit"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </DesktopContainer>
  );
}
