/* eslint-disable @nx/enforce-module-boundaries */
import { FileStorageContext } from '@rabbit/bizproc/react';
import {
  LoadingSpinner,
  UploaderFormikProvider,
  UploadFilesFormv2,
} from '@rabbit/elements/shared-components';
import { FBD_Holding_Private } from '@rabbit/data/types';
import {
  FileUploaderWrapperProps,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useContext, useEffect, useState } from 'react';
import { OliveFileUploadAutoUpdater } from './OliveFileUploadAutoUpdater';

// todo: current file fetchers for various categories.
// Uploader will display list of existing files, upload and attach to documents in the background
// while displaying the completed files in the list.
// On refresh the completed file will be wiped but since it's already attached to the documents
// we'll see it again.

// TODO: add way to send current files to parent?

export function OliveFileUploader(props: FileUploaderWrapperProps) {
  const {
    label,
    labelClasses,
    identifiers,
    currentFiles,
    accepts,
    onUploadCompleted,
    onDeleteFile,
    allowDelete,
    shouldAutoUpdateDocs,
    alterCaseFacts,
    name,
  } = props;
  const [currentFilesState, setCurrentFilesState] = useState(currentFiles);
  const fileStorageContext = useContext(FileStorageContext);
  const {
    uploadFiles,
    uploadQueueState,
    isUpdating,
    deleteFile,
    shouldRefetch,
    setShouldRefetch,
  } = fileStorageContext || {};

  // If we don't have any current files from the parent, we'll have to fetch them
  useEffect(() => {
    if ((!currentFiles || shouldRefetch) && identifiers.docType?.docid) {
      // todo make switch
      if (
        identifiers.category === UploadedFileCategories.ConsumerProofPurchase
      ) {
        (async () => {
          const res = await fetchProofOfPurchaseFiles(
            identifiers.docType?.docid ?? ''
          );
          setCurrentFilesState(res);

          if (setShouldRefetch) setShouldRefetch(false);
        })().catch((err) => console.log(err));
      }
      if (identifiers.category === UploadedFileCategories.SerialNumberProof) {
        (async () => {
          const res = await fetchSerialProofFiles(
            identifiers.docType?.docid ?? ''
          );
          setCurrentFilesState(res);

          if (setShouldRefetch) setShouldRefetch(false);
        })().catch((err) => console.log(err));
      }
      if (
        identifiers.category === UploadedFileCategories.ConsumerCaseEvidence
      ) {
        setCurrentFilesState(currentFiles ?? []);
      }
    } else {
      setCurrentFilesState(currentFiles ?? []);
    }
  }, [currentFiles, shouldRefetch]);

  if (
    !uploadFiles ||
    typeof uploadQueueState === 'undefined' ||
    !currentFilesState ||
    !deleteFile ||
    isUpdating === undefined
  )
    return <LoadingSpinner size="sm" />;

  const uploaderProps = {
    ...props,
    queue: uploadQueueState,
    currentFiles: currentFilesState,
    isUpdating,
    actions: {
      uploadFiles,
      deleteFile,
      alterCaseFacts,
    },
    canDeleteFile: allowDelete,
    onDeleteFile,
    onUploadCompleted,
  };

  return (
    <>
      {shouldAutoUpdateDocs && (
        <OliveFileUploadAutoUpdater fileCategory={identifiers.category} />
      )}
      {name ? (
        <UploaderFormikProvider {...{ ...uploaderProps, name }} />
      ) : (
        <UploadFilesFormv2 {...uploaderProps} />
      )}
    </>
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const fetchProofOfPurchaseFiles = async (holdingId: string) => {
  const holding_private = await FBD_Holding_Private.get(holdingId);
  return holding_private?.receipt ?? [];
};

const fetchSerialProofFiles = async (holdingId: string) => {
  const holding_private = await FBD_Holding_Private.get(holdingId);
  return holding_private?.serial_proof ?? [];
};
