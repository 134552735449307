import Layout from '../../templates/Layout/Layout';
import { PrivacyPolicyContentView } from '@rabbit/elements/shared-components';

/* eslint-disable-next-line */
export interface PrivacyPolicyViewProps {}

export function PrivacyPolicyView(props: PrivacyPolicyViewProps) {
  return (
    <Layout>
      <div className="font-caudex text-primary-900 mb-4 text-3xl">
        Privacy Policy
      </div>
      <PrivacyPolicyContentView />
    </Layout>
  );
}
export default PrivacyPolicyView;
