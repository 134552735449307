import { Button } from '@rabbit/elements/shared-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import imageConfused from '../assets/images/confused_fox.png';

export function AuthErrorView() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-grow flex-col items-center justify-center gap-5 px-4 text-center md:pt-0">
      <div className="">
        <img
          src={imageConfused}
          alt={'Page not found'}
          className="m-auto h-[186px]"
        ></img>
      </div>
      <div className="font-caudex pt-12 text-5xl font-bold md:pt-0">
        {t('Sign in error')}
      </div>
      <div className="flex flex-col gap-2 text-lg">
        <div className="font-caudex font-bold">
          {t(
            "We're very sorry. It looks like the link you tried to use is no longer valid."
          )}
        </div>
        <div className="font-caudex">
          {t('HINT: Are you sure you are using the latest login link?')}
        </div>
        <div className="font-nunito text-gray-500">
          {t('Please press the button below to go back to the homepage')}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Button kind="primary" onClick={() => navigate('/')}>
          {t('Back to homepage')}
        </Button>
      </div>
    </div>
  );
}

export default AuthErrorView;
