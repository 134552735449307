import { Heading } from '@rabbit/elements/shared-components';
import foxImage from '../../assets/images/fox_anvil.png';
import ratImage from '../../assets/images/rat_anvil.png';
import snakeHatImage from '../../assets/images/hat_snake.png';
import snakePiperImage from '../../assets/images/snake_piper.png';
import ComingSoon from '../../components/molecules/ComingSoon/ComingSoon';
import FloatImageCard from '../../components/molecules/FloatImageCard/FloatImageCard';

/* eslint-disable-next-line */
export interface ImpactViewProps {}

export function ImpactView(props: ImpactViewProps) {
  return (
    <div className="lg:mb-11">
      <div className="flex justify-center">
        <div className="flex flex-col items-center">
          <ComingSoon
            title="Great things are coming soon."
            message="Our team is working on innovative features to empower you in relation to extending  your product's useful life and the positive environmental impact that it will bring."
            imageAlt="Fox working on anvil"
            imageSrc={foxImage}
          />
        </div>
      </div>
      <div className="mt-8 flex w-full flex-col items-center rounded-t-[40px] bg-gray-50 px-8 py-14">
        <Heading kind="h2" className="mb-8">
          Who is iWarranty?
        </Heading>
        <div className="flex w-full flex-col gap-16">
          <FloatImageCard
            title="Our Vision"
            body="iWarranty’s vision and commitment is to reduce waste for a cleaner, greener planet."
          />
          <FloatImageCard
            title="Our Mission"
            body="We are on a mission to help manufacturers, retailers and customers reduce their carbon footprint by digitising warranty management and enabling accessible repairs via our app so that every product reaches its maximum lifespan. In simple terms, we want to ensure that every product is utilised to its full potential to mitigate negative environmental impacts and foster a sustainable future."
            imageSrc={snakeHatImage}
            imageAlt="Rat sitting on anvil"
            floatPosition="right"
          />
          <FloatImageCard
            title="Why do we need this change?"
            body="Globally, we are creating over 53 million tons of avoidable waste by disposing of products, most of which are repairable. It's about time we put a stop to this practice!
Unfortunately, that’s almost impossible if we continue replacing products with new ones due to the complexity and challenges of accessing in-and-out-of warranty repairs."
            imageSrc={ratImage}
            imageAlt="Happy snake with a hat"
            floatPosition="title"
          />
          <FloatImageCard
            title="The Solution"
            body="By going digital with warranty and repair management, we can tackle the avoidable waste problem by extending product lifespan. We are collaborating with renowned manufacturers and esteemed retailers to make warranty claims and repairs accessible for you from anywhere in the world with a few taps on your phone. Join us in our mission to create a cleaner, greener planet for a brighter tomorrow!"
            imageSrc={snakePiperImage}
            imageAlt="Standing snake smiling with a hat"
            floatPosition="body"
          />
        </div>
      </div>
      <p className="font-nunito m-auto mt-8 max-w-lg text-center text-xs font-medium">
        All RICHARD SCARRY® characters and trademarks, including BUSYTOWN™,
        LOWLY WORM™, HUCKLE CAT™ and the APPLECAR DESIGN™, are the intellectual
        property of The Richard Scarry Corporation AG. © 2021
      </p>
    </div>
  );
}
export default ImpactView;
