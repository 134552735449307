import { useState } from 'react';
import {
  ExpandableDescription,
  Heading,
} from '@rabbit/elements/shared-components';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

export interface ItemProps {
  id?: string;
  title: string;
  description?: string;
  included?: string;
}

export interface InfoProps {
  title: string;
  type: string;
  items?: ItemProps[];
  children?: any;
}

export interface WarrantyInfoProps {
  data: InfoProps;
}

export function WarrantyInfo(props: WarrantyInfoProps) {
  const { data } = props;
  const [selected, setSelected] = useState(null);

  const handleToggleDescription = (itemId: any) => {
    setSelected((prevSelected) => (prevSelected === itemId ? null : itemId));
  };

  return (
    <div className="mb-6 flex flex-col">
      <Heading kind={'h5'} className="text-[14px]">
        {data.title}
      </Heading>
      <div className="border-col mt-4 flex flex-col gap-3 rounded-xl border-[1px] border-gray-200 bg-gray-50 px-5 py-5">
        {data.items &&
          data.items.map((item, index) => (
            <div key={index} className="flex flex-col gap-2">
              <div
                className="flex cursor-pointer items-center justify-between gap-2"
                onClick={() => handleToggleDescription(index)}
              >
                <div className="flex flex-1 items-start gap-2">
                  {data.type === 'checks' && (
                    <div className="h-5 w-5 shrink-0">
                      {item.included === 'yes' ? (
                        <CheckCircleIcon className="fill-green-500 text-white" />
                      ) : (
                        <XCircleIcon className="fill-red-500 text-white" />
                      )}
                    </div>
                  )}
                  <div className="font-nunito text-base font-medium">
                    {item.title}
                  </div>
                </div>
                {item.description && (
                  <div className="h-4 w-4">
                    {selected === index ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </div>
                )}
              </div>
              <div className="flex w-full flex-col text-base">
                {selected === index && item.description && (
                  <ExpandableDescription description={item.description} />
                )}
              </div>
            </div>
          ))}
        {data.children && (
          <div
            className="text-base"
            dangerouslySetInnerHTML={{
              __html: data.children,
            }}
          ></div>
        )}
      </div>
    </div>
  );
}

export default WarrantyInfo;
