import { useEffect, useContext } from 'react';
import '../../config/dbarcode-reader-config'; // import side effects. The license, engineResourcePath, so on.
import { BarcodeReader } from 'dynamsoft-javascript-barcode';
import VideoDecode from './VideoDecode';
import { DTVendable } from '@rabbit/data/types';
import { UserContext } from '../../context/UserContext';
import { LoadingSpinner } from '@rabbit/elements/shared-components';

export interface ScanTemplateProps {
  setProductData: React.Dispatch<React.SetStateAction<DTVendable | null>>;
}

export function ScanTemplate({ setProductData }: ScanTemplateProps) {
  const contextValues = useContext(UserContext);
  const { consumerPersonaData, consumerPersonaId } = contextValues || {};

  const userStatus = {
    consumerPersonaId,
    hasUsedScanner: consumerPersonaData?.hasUsedScanner,
  };

  const initBarCode = async () => {
    try {
      await BarcodeReader.loadWasm();
    } catch (ex: any) {
      if (ex.message.indexOf('network connection error')) {
        const customMsg =
          'Network connection error. Please check your Internet connection.';
        console.log(customMsg);
      }
      throw ex;
    }
  };

  useEffect(() => {
    void initBarCode();
  }, []);

  if (contextValues === null) return <LoadingSpinner size="2xl" isFullScreen />;

  return (
    <VideoDecode userStatus={userStatus} setProductData={setProductData} />
  );
}

export default ScanTemplate;
