export interface BlogCardProps {
  imageUrl: string;
  label: string;
  text: string;
  onClick: () => void;
}

export function BlogCard({
  label,
  text,
  imageUrl = 'https://picsum.photos/121/121',
  onClick,
}: BlogCardProps) {
  return (
    <div
      className="inline-flex w-full flex-grow cursor-pointer items-center rounded-md bg-gray-50 pr-4"
      onClick={onClick}
    >
      <div
        style={{ backgroundImage: 'url(' + imageUrl + ')' }}
        className="mr-4 h-[121px] min-w-[121px] rounded-[10px_0_0_10px] bg-cover p-0"
      ></div>
      <div className="font-nunito flex flex-col-reverse items-start">
        <div className="mr-1.5 flex items-center">
          <div className="mr-1.5 h-3 w-3 rounded-full bg-gray-200"></div>
          <span className="text-sm font-normal">{label}</span>
        </div>
        <div className="mb-2.5">
          <p className="text-base font-bold">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
