export interface ProductInformationProps {
  imageUrl: string | null;
  title: string;
  subtitle: string | undefined;
}

export function ProductInformation({
  imageUrl,
  title,
  subtitle,
}: ProductInformationProps) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div
        style={{ backgroundImage: imageUrl ? 'url(' + imageUrl + ')' : '' }}
        className="h-[198px] w-[198px] overflow-hidden rounded-md bg-contain bg-center bg-no-repeat"
      ></div>
      <span className="font-nunito mt-8 text-base font-light capitalize">
        {subtitle}
      </span>
      <div className="font-nunito text-center text-xl font-medium lg:text-[24px]">
        {title}
      </div>
    </div>
  );
}

export default ProductInformation;
