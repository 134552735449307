import React, {
  useEffect,
  createRef,
  useMemo,
  useState,
  RefObject,
} from 'react';
import { useSwipeable } from 'react-swipeable';
import { Carousel } from 'flowbite';
import type {
  CarouselItem,
  CarouselOptions,
  CarouselInterface,
} from 'flowbite';
import {
  ChildWrapper,
  Indicator,
  carouselOptionsInit,
  ControllerButtons,
} from './StepCarousel.helpers';
import { useNavigate } from 'react-router-dom';
import Heading from '../../atoms/Heading/Heading';

export interface StepCarouselProps {
  children?: React.ReactNode;
  slideHeaders?: string[];
  /**
   * A list of messages, each associated with a slide according to its position in the array.
   */
  slideCopyList?: string[]
  /**
   * Display 'Continue' and 'Skip' buttons or not, and control their behaviour through an
   * array of [continueFn, skipFn]. The 'default' option will have the continue button
   * move to the next slide and the skip button navigate to '/'.
   */;
  btnControllers?: 'default' | [() => any, () => any];

  /**
   *  Optional function, sent in from parent, to be triggered when the Step Carousel flow is completed.
   */

  onComplete?: () => any;
}

export function StepCarousel({
  children,
  slideHeaders,
  slideCopyList,
  btnControllers,
  onComplete,
}: StepCarouselProps) {
  const navigate = useNavigate();
  const numItems = useMemo(() => React.Children.count(children), [children]);

  // State management
  const [carousel, setCarousel] = useState<CarouselInterface | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Create a ref for each child, but only once.
  const carouselItemRefs = useMemo(
    () => Array.from({ length: numItems }).map(() => createRef()),
    [numItems]
  );

  const carouselIndicatorRefs = useMemo(
    () => Array.from({ length: numItems }).map(() => createRef()),
    [numItems]
  );

  // Carousel controls
  const moveToNext = () => {
    if (currentSlide < numItems - 1) {
      carousel?.slideTo(currentSlide + 1);
      setCurrentSlide(currentSlide + 1);
    }
    if (currentSlide >= numItems - 1) {
      if (onComplete) onComplete();
      else navigate('/');
    }
  };

  const moveToPrev = () => {
    if (currentSlide > 0) {
      carousel?.slideTo(currentSlide - 1);
      setCurrentSlide(currentSlide - 1);
    }
  };

  const moveWithIndicator = (i: number) => {
    carousel?.slideTo(i);
    setCurrentSlide(i);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => moveToNext(),
    onSwipedRight: () => moveToPrev(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  // Preloading tailwind styles by declaring them
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadStyles =
    'inset-0 transition-transform transform z-10 z-20 translate-x-0 translate-x-full -translate-x-full';

  useEffect(() => {
    if (numItems > 0) {
      // Set carousel items and options
      const cItemsList = Array.from({ length: numItems }, (elem, i) => ({
        position: i,
        el: carouselItemRefs[i].current,
      }));

      const cIndicatorsList = Array.from({ length: numItems }, (elem, i) => ({
        position: i,
        el: carouselIndicatorRefs[i].current,
      }));

      const carouselOptions = {
        ...carouselOptionsInit,
        indicators: {
          ...carouselOptionsInit.indicators,
          items: cIndicatorsList,
        },
      };

      // Initialize Carousel
      if (!carousel) {
        setCarousel(
          new Carousel(
            cItemsList as CarouselItem[],
            carouselOptions as CarouselOptions
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numItems]);

  return (
    <>
      <div className="relative flex flex-col gap-8">
        {slideHeaders?.[currentSlide] && (
          <div className="mx-auto w-[160px] text-center">
            <Heading kind="h2">{slideHeaders[currentSlide]}</Heading>
          </div>
        )}
        <div className="mb-5 flex flex-col gap-10">
          {/* <!-- Carousel wrapper --> */}
          <div
            {...swipeHandlers}
            className="relative h-[40vh] overflow-hidden rounded-lg sm:h-[50vh] sm:max-h-[320px]"
          >
            {React.Children.map(children, (child, i) => {
              return (
                <ChildWrapper
                  ref={carouselItemRefs[i] as RefObject<HTMLDivElement>}
                  num={i}
                >
                  {child}
                </ChildWrapper>
              );
            })}
          </div>
          <div className="flex-center flex">
            {/* <!-- Slider indicators --> */}
            <div className="relative bottom-0 left-1/2 z-30 flex -translate-x-1/2 space-x-3">
              {React.Children.map(children, (child, i) => {
                return (
                  <Indicator
                    ref={
                      carouselIndicatorRefs[i] as RefObject<HTMLButtonElement>
                    }
                    num={i}
                    onClick={() => moveWithIndicator(i)}
                    wasSeen={i <= currentSlide}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10">
        {/* <!-- Optional copy div --> */}
        {slideCopyList?.[currentSlide] && (
          <div className="w-full">
            <p className="font-nunito text-center text-base font-normal text-black">
              {slideCopyList[currentSlide]}
            </p>
          </div>
        )}
        <div className="sm:rounded-0 absolute bottom-0 left-0 flex w-full flex-col gap-4 rounded-t-3xl bg-gray-100 px-12 pt-8 pb-10 sm:relative sm:bg-transparent sm:p-0">
          {/* <!-- Optional continue & skip buttons --> */}
          {btnControllers &&
            (btnControllers !== 'default' ? (
              <ControllerButtons
                continueFn={btnControllers[0]}
                skipFn={btnControllers[1]}
              />
            ) : (
              <ControllerButtons
                continueFn={() => {
                  moveToNext();
                }}
                skipFn={() => {
                  if (onComplete) onComplete();
                  else navigate('/');
                }}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default StepCarousel;
