import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import {
  DesktopContainer,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import AdditionalInfoContainer from 'apps/olive/src/templates/Account/AdditionalInfoContainer';

function AdditionalInfoView() {
  const { consumerPersonaData, consumerPersonaId } =
    useContext(UserContext) ?? {};
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  if (
    consumerPersonaData === null ||
    !consumerPersonaData ||
    !consumerPersonaId
  )
    return <LoadingSpinner size="md" isFullScreen />;

  return (
    <DesktopContainer
      title="Additional Information"
      goBack={() => navigate(-1)}
      loading={isLoading}
    >
      <AdditionalInfoContainer
        id={consumerPersonaId}
        data={consumerPersonaData}
        setIsLoading={setIsLoading}
      />
    </DesktopContainer>
  );
}

export default AdditionalInfoView;
