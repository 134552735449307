import { fromUnixTime } from 'date-fns';
import format from 'date-fns/format';
import { Button, Heading } from '@rabbit/elements/shared-components';
import { UserUploadedDocument } from '@rabbit/data/types';

export interface NLICCompletedProps {
  additional_info: string;
  claimId: string;
  consumer_claim_evidence: UserUploadedDocument[];
  consumer_proof_purchase: UserUploadedDocument[];
  date_of_claim: Date;
  email: string;
  issue: string;
  purchase_date: number;
}
export function NLICCompleted({ data }: { data: NLICCompletedProps }) {
  const vendableName = window.localStorage.getItem('NLIC_VendableName');

  return (
    <div>
      <Heading kind={'h3'}>Thank you for your claim!</Heading>
      <Heading kind={'h3'} className="mt-8">
        Claim summary
      </Heading>
      <div className="font-nunito mt-8 flex flex-col items-start gap-4 bg-gray-50 p-4">
        <p>
          <strong>Date of claim:</strong>{' '}
          {format(data?.date_of_claim ?? new Date(), 'dd/MM/yyyy')}
        </p>
        <p>
          <strong>Product:</strong> {vendableName ?? ''}
        </p>
        <p>
          <strong>Purchase date:</strong>{' '}
          {format(
            data?.purchase_date && data?.purchase_date > 0
              ? fromUnixTime(data?.purchase_date)
              : new Date(),
            'dd/MM/yyyy'
          )}
        </p>
        <p>
          <strong>Issue:</strong> {data?.issue ?? ''}
        </p>
      </div>
      <div className="font-nunito mt-8 font-normal">
        <p>We've sent you an email confirming your claim to:</p>
        {data?.email && (
          <p>
            <strong>{data?.email}</strong>
          </p>
        )}
      </div>
      <Button
        kind="primary"
        onClick={() => {
          window.localStorage.setItem(
            'NLIC_RedirectTo',
            `/repairs/${data?.claimId ?? ''}`
          );
          window.location.reload();
        }}
        className="mt-8 w-full"
      >
        View claim status
      </Button>
    </div>
  );
}
