import { Heading, Button } from '@rabbit/elements/shared-components';

import foxImage from '../../../assets/images/fox_anvil.png';

interface WIPViewProps {
  imageSrc?: string;
  imageAlt?: string;
  title: string;
  message?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
}
export default function WIPView({
  imageSrc,
  imageAlt,
  title,
  message,
  buttonText,
  buttonOnClick,
}: WIPViewProps) {
  return (
    <div className="flex-column flex flex-col items-center sm:w-[480px]">
      <img
        src={imageSrc ? imageSrc : foxImage}
        alt={imageAlt ? imageAlt : 'Fox working on anvil'}
        className="h-[190px] object-contain"
      />
      <div className="flex flex-col pt-1 text-center">
        <Heading kind="h1">{title}</Heading>
        {message && (
          <Heading
            kind="p"
            className="font-nunito pt-2 font-normal text-gray-500"
          >
            {message}
          </Heading>
        )}
      </div>
      {buttonText && (
        <div className="mt-8 w-full">
          <Button
            kind="primary"
            size="md"
            onClick={buttonOnClick}
            className="w-full"
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
}
