import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import {
  StepCarousel,
  CarouselImg,
  AuthContainer,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import onboarding1 from '../assets/images/onboarding.png';
import onboarding2 from '../assets/images/onboarding-2.png';
import onboarding3 from '../assets/images/onboarding-3.png';
import {
  DR_Consumer_Private,
  usePortalDocumentEditor,
} from '@rabbit/data/portal';
import ROUTE_NAME from '../utils/url-constants';
import { DRDocType } from '@rabbit/firebase/react';
import { DTConsumer_Private } from '@rabbit/data/types';
import { useAppInfo } from '../utils/helpers';

const messages = [
  'No more fine print, we summarize the important parts for you.',
  'Upload your products and keep track of your warranties and receipts in one place.',
  'Easy access to repairs, even if your product is no longer covered.',
];
const headers = [
  'Peace of mind',
  'Warranties at your fingertips',
  'Simple repairs',
];

/* eslint-disable-next-line */
export interface OnboardingViewProps {}

export function OnboardingView(props: OnboardingViewProps) {
  const contextValues = useContext(UserContext);
  const { consumerPersonaData, consumerPersonaId } = contextValues || {};
  const { body, update, commit, isReady } = usePortalDocumentEditor(
    DR_Consumer_Private as DRDocType<DTConsumer_Private>,
    consumerPersonaId || ''
  );
  const appInfo = useAppInfo();
  if (contextValues === null) return <LoadingSpinner size="2xl" isFullScreen />;

  if (consumerPersonaData?.isOnboarded)
    return <Navigate to={ROUTE_NAME.DASHBOARD} />;

  const onCompleteOnBoarding = async () => {
    if (!body || !isReady) return;
    try {
      update({
        ...body,
        isOnboarded: true,
      });
      await commit();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <AuthContainer loading={false} appInfo={appInfo}>
      <StepCarousel
        slideHeaders={headers}
        slideCopyList={messages}
        btnControllers="default"
        onComplete={() => onCompleteOnBoarding()}
      >
        <CarouselImg src={onboarding1} alt={messages[0]} />
        <CarouselImg src={onboarding2} alt={messages[1]} />
        <CarouselImg src={onboarding3} alt={messages[2]} />
      </StepCarousel>
    </AuthContainer>
  );
}

export default OnboardingView;
