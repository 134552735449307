import { Heading } from '@rabbit/elements/shared-components';
import ImgSmailSent from '../../assets/images/email_sent.svg';

const styles = {
  label: 'block text-gray-700 text-sm font-bold pt-2 pb-1',
  field:
    'bg-gray-50 border-gray-300 text-gray-500 rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full h-[52px] px-4 py-[14px] placeholder-gray-400',
  button:
    ' bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600',
  errorMsg: 'text-red-500 text-sm',
};

export function EmailSent(props: any) {
  const { onChange, data } = props;
  return (
    <div className="wrapper">
      <div className="font-nunito flex flex-col gap-4 font-medium">
        <div className="flex justify-center">
          <img src={ImgSmailSent} alt="Email sent" />
        </div>
        <Heading kind="h4">Check your email for your login link</Heading>
        <p className="text-md pb-7">
          We emailed your link to: <br />
          <strong>{data.email}</strong>
        </p>
        <p>
          Didn’t receive your link?{' '}
          <a
            target="_self"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              onChange();
            }}
          >
            Send again
          </a>
        </p>
      </div>
    </div>
  );
}

export default EmailSent;
