import Layout from '../../templates/Layout/Layout';
import { TermsAndConditionsContentView } from '@rabbit/elements/shared-components';

/* eslint-disable-next-line */
export interface TermsAndConditionsViewProps {}

export function TermsAndConditionsView(props: TermsAndConditionsViewProps) {
  return (
    <Layout>
      <div className="font-caudex text-primary-900 mb-4 text-3xl">
        Terms and Conditions
      </div>
      <TermsAndConditionsContentView />
    </Layout>
  );
}
export default TermsAndConditionsView;
