import { SearchImage } from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../../utils/url-constants';
import { DTVendable } from '@rabbit/data/types';
import { useNavigate } from 'react-router-dom';

export interface SearchGalleryProps {
  data?: DTVendable[];
  onChangeStep?: (step_in: number, data: any) => void;
}

export function SearchGallery({ data, onChangeStep }: SearchGalleryProps) {
  const navigate = useNavigate();
  return data ? (
    <div className="-mx-8 flex flex-wrap">
      {data.map((item: any, index: number) => (
        <div
          key={index}
          className={
            !onChangeStep
              ? 'font-nunito mb-8 w-1/2 rounded-lg px-8 md:w-1/3 lg:w-1/5'
              : 'font-nunito mb-8 w-1/2 rounded-lg px-8 md:w-1/3'
          }
        >
          <SearchImage
            imageUrl={item.img[0]}
            size={onChangeStep ? 'NLIC' : 'md'}
            subtitle={item.brand}
            title={item.title}
            onClick={() =>
              onChangeStep
                ? onChangeStep(3, { docid: item.docid, item })
                : navigate(`${ROUTE_NAME.PRODUCTS_PREVIEW}/${item.docid}`)
            }
          />
        </div>
      ))}
    </div>
  ) : null;
}

export default SearchGallery;
