import { XCircleIcon } from '@heroicons/react/24/outline';

export interface PillTagProps {
  item: string;
  index: number;
  onClick: (index: number, value: string) => void;
}

export function PillTag({ item, index, onClick }: PillTagProps) {
  return (
    <div
      key={index}
      className="bg-primary-900 font-nunito flex h-[34px] w-fit items-center justify-center gap-2 rounded-lg p-3 text-white"
    >
      <p className="text-sm font-medium text-white">{item}</p>
      <XCircleIcon
        className="h-[17px] w-[17px] cursor-pointer"
        onClick={() => onClick(index, item)}
      />
    </div>
  );
}

export default PillTag;
