import {
  ChevronLeftIcon,
  ComputerDesktopIcon,
  InformationCircleIcon,
  ClipboardIcon,
} from '@heroicons/react/24/solid';
import {
  ShadowContainer,
  Heading,
  StepsProgress,
  Input,
  Button,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { Formik, FormikProps, Form } from 'formik';
import { SRStepProps } from '../ServiceRequestView';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { useCreateSRVHolding, useFileStorage } from '@rabbit/bizproc/react';
import { UserUploadedDocument } from '@rabbit/data/types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  product_name: Yup.string().trim().required('Please enter a product name'),
  product_brand: Yup.string().trim().required('Please enter a product brand'),
  product_category: Yup.string()
    .trim()
    .required('Please enter a product category'),
  product_image: Yup.mixed(),
});

interface FormValuesShape {
  product_name: string;
  product_brand: string;
  product_category: string;
  product_image: UserUploadedDocument | null;
}

const initialValues: FormValuesShape = {
  product_name: '',
  product_brand: '',
  product_category: '',
  product_image: null,
};

export default function ServiceRequestAddProductManually({
  setStep,
}: SRStepProps) {
  const { personaId } = useMyConsumerPersona() || {};
  const [submiting, setSubmitting] = useState(false);
  const {
    uploadFiles,
    uploadedTempFiles,
    deleteUnusedTempFiles,
    deleteFile,
    uploadTaskCat,
    clearFileFromState,
    clearAllTempFilesFromState,
  } = useFileStorage();
  const { createUserSRVHolding } = useCreateSRVHolding();
  const persona = useMyConsumerPersona();
  const formikRef = useRef<FormikProps<FormValuesShape> | null>(null);

  useEffect(() => {
    return () => {
      void (async () => await deleteUnusedTempFiles())();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    try {
      if (!persona?.personaId) throw new Error('Persona not found');
      const hId = await createUserSRVHolding(values);
      toast.success('Product created successfully.');
      clearAllTempFilesFromState();
      // Add srv true to let additional info step know its Self registered vendable
      setStep({
        current: 1.3,
        data: {
          holdingId: hId,
          srv: true,
          holding: { title: values?.product_name },
        },
      });
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again later');
    } finally {
      setSubmitting(false);
    }
  };

  if (!persona?.personaId) return <LoadingSpinner size="sm" isFullScreen />;

  return (
    <ShadowContainer loading={submiting}>
      <div className="relative h-full px-8 py-6">
        <div className="relative">
          <ChevronLeftIcon
            className="absolute mt-1 h-6 w-6 cursor-pointer"
            onClick={() => setStep({ current: 1.1 })}
          />
          <Heading kind="h3" className="text-center">
            New product
          </Heading>
        </div>
        <div className="mt-6 text-gray-600">
          <StepsProgress
            steps={[
              { Icon: ComputerDesktopIcon, completed: true },
              { Icon: InformationCircleIcon, completed: false },
              { Icon: ClipboardIcon, completed: false },
            ]}
            inline={true}
          />
        </div>
        <div className="mt-6 mb-6 flex flex-col gap-2">
          <Heading kind="h4" className="w-full">
            Let’s register your product
          </Heading>
          <Heading kind="p">
            Please fill out all of the information to continue.
          </Heading>
        </div>
        <div className="flex flex-col gap-6 pb-2">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values }) => {
              return (
                <Form className="font-nunito flex flex-col gap-4">
                  <Input
                    type="picture"
                    name="product_image"
                    settings={{
                      personaId: personaId ?? '',
                      placeholder: values.product_image?.url ?? undefined,
                      uploadHandlers: {
                        category: 'srv_images',
                        uploadFiles,
                        uploadedTempFiles,
                        uploadTaskCat,
                        deleteFile,
                        clearFileFromState,
                      },
                    }}
                  />
                  <Input
                    label="Product name*"
                    type="text"
                    name="product_name"
                    settings={{
                      placeholder: 'Product name',
                      hint: '*required',
                    }}
                  />
                  <Input
                    label="Brand*"
                    type="text"
                    name="product_brand"
                    settings={{
                      placeholder: 'Brand name',
                      hint: '*required',
                    }}
                  />
                  <Input
                    type="text"
                    label="Category*"
                    name="product_category"
                    settings={{
                      id: 'product_category',
                      placeholder: 'Please select an option',
                      hint: '*required',
                    }}
                  />
                  <div className="mt-2 flex flex-col gap-4">
                    <Button kind="primary" children="Register" type="submit" />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </ShadowContainer>
  );
}
