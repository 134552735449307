import ROUTE_NAME from 'apps/olive/src/utils/url-constants';
import logoiWarranty from '../../../assets/images/logo/logo-iwarranty-white.svg';
import { t } from 'i18next';

/* eslint-disable-next-line */
export interface FooterProps {}

export function Footer(props: FooterProps) {
  return (
    <footer className="hidden bg-[#171B26] px-2 pt-8 pb-24 text-left text-sm text-[#EBEBEB] md:py-8 md:text-center lg:block">
      <div className="flex flex-col gap-2">
        <div className="flex justify-start md:justify-center">
          <img src={logoiWarranty} alt="iWarranty" width={'110px'} />
        </div>
        <div>{t('Copyright © 2024 iWarranty. All rights reserved.')}</div>
        <div className="flex justify-start gap-2 md:justify-center">
          <a
            href={ROUTE_NAME.TERMS_AND_CONDITIONS}
            target="_blank"
            className="text-[#EBEBEB]"
          >
            {t('Terms and Conditions')}
          </a>
          <a
            href={ROUTE_NAME.PRIVACY_POLICY}
            target="_blank"
            className="text-[#EBEBEB]"
          >
            {t('Privacy Policy')}
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
