import {
  DR_Identity,
  DR_Identity_Private,
  useIdentity,
  useMyConsumerPersona,
  usePortalMultipleDocumentEditor,
} from '@rabbit/data/portal';
import { useFirebaseAuthentication } from '@rabbit/data/portal';
import { DR_Consumer_Private } from '@rabbit/data/portal';

/**
 * Used on Olive. This hook is used to provide functions for updating a consumer's profile.
 */
export function useManageConsumerProfile() {
  const identity = useIdentity();
  const persona = useMyConsumerPersona();
  const {
    // updateUserEmail,
    updateUserProfile,
  } = useFirebaseAuthentication();

  const { body, status, isReady, commit, update } =
    usePortalMultipleDocumentEditor({
      consumer_private: {
        type: DR_Consumer_Private,
        docid: persona?.personaId || '',
      },
      identity: {
        type: DR_Identity,
        docid: identity.uid || '',
      },
      identity_private: {
        type: DR_Identity_Private,
        docid: identity.uid || '',
      },
    });

  if (!persona) throw new Error('A valid consumer persona is required');

  //This is probably broken now and will need reviewing soon -DC
  const updateProfileData = async (formData: {
    fullname: string;
    splitname: { first?: string; last?: string };
    phone?: string;
    preferred_contact?: string[];
  }) => {
    if (!persona?.personaId)
      throw new Error('A valid consumer persona is required');

    const { fullname, splitname, phone, preferred_contact } = formData;

    //Update firebase auth profile
    await updateUserProfile(
      formData.fullname || body?.consumer_private?.fullname || ''
    );

    const prevConsumer = body.consumer_private;
    // Should we be editing identity at all? -DC
    const prevIdentity = body.identity;
    const prevIdentityPrivate = body.identity_private;

    if (!prevConsumer || !prevIdentity || !prevIdentityPrivate)
      throw new Error('Invalid data');

    const newSplitname = {
      first: splitname.first ?? prevConsumer?.splitname?.first ?? '',
      last: splitname.last ?? prevConsumer?.splitname?.last ?? '',
    };
    // Update personas
    if (isReady) {
      try {
        await update({
          consumer_private: {
            ...prevConsumer as any,
            fullname: fullname ?? prevConsumer.fullname ?? '',
            splitname: newSplitname,
            phone: phone ?? prevConsumer.phone ?? '',
            preferred_contact:
              preferred_contact ?? prevConsumer.preferred_contact ?? [],
          },
          identity: { ...prevIdentity, displayname: fullname },
          identity_private: { ...prevIdentityPrivate, displayname: fullname },
        });
        await commit();
      } catch (err) {
        throw new Error(`Error updating profile: ${err}`);
      }
    }
  };

  return {
    body,
    status,
    update,
    commit,
    isReady,
    // updateUserEmail,
    // updateUserPassword,
    updateProfileData,
  };
}
