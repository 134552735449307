import {
  FBD_Holding_Public,
  FBD_Holding_Manufacturer,
  FBD_Holding_Private,
  UserUploadedDocument,
  SELF_REGISTERED_VENDABLE,
} from '@rabbit/data/types';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import {
  Data_Holding_AttachToConsumer_SaveConsumer,
  Holding_CreateProxy_FromHoldingDocs,
} from '@rabbit/bizproc/client';
import { useFileStorage } from '../general/useFileStorage';
import { OurDateTime } from '@rabbit/utils/ts';

interface OliveSRVHoldingCreationFormShape {
  product_name: string;
  product_brand: string;
  product_category: string;
  product_image: UserUploadedDocument | null;
}

/** Creates Self Registered Vendable (SRV) Holdings for Olive users. */
export function useCreateSRVHolding() {
  // Get the consumer persona
  const consumerPersona = useMyConsumerPersona();
  const { updateSRVHoldingWithFiles } = useFileStorage();

  const createUserSRVHolding = async (
    creationFormData: OliveSRVHoldingCreationFormShape
  ) => {
    if (!consumerPersona.personaId || !consumerPersona.data.consumer_private)
      throw new Error('A valid consumer persona is required');

    const { product_name, product_brand, product_category, product_image } =
      creationFormData;

    if (!product_name || !product_brand || !product_category)
      throw new Error('Missing required form data');

    // Let's start creating the SRV holding:
    // Initialize the three holding types, and set their docids
    const holding = FBD_Holding_Public.empty();
    const holding_private = FBD_Holding_Private.empty();
    const holding_manufacturer = FBD_Holding_Manufacturer.empty();

    const holding_docid = FBD_Holding_Private.keygen(
      holding_private,
      FBD_Holding_Private
    );

    holding.docid =
      holding_private.docid =
      holding_manufacturer.docid =
        holding_docid;

    // Since this is a self registered holding, we set the vendable field to "SRV", to both
    // distinguish it from regular holdings and make it play nice with holding proxy generation
    holding.vendable =
      holding_private.vendable =
      holding_manufacturer.vendable =
        SELF_REGISTERED_VENDABLE;

    holding.consumer =
      holding_private.consumer =
      holding_manufacturer.consumer =
        consumerPersona.data.consumer_private.docid;

    holding.self_registration = {
      title: product_name,
      brand: product_brand,
      category_title: product_category,
      show_missing_info_cta: true,
    };

    holding_private.created_time = holding_manufacturer.register_time =
      OurDateTime.nowUTCTimestamp();

    await Promise.all([
      FBD_Holding_Public.set(holding),
      FBD_Holding_Private.set(holding_private),
      FBD_Holding_Manufacturer.set(holding_manufacturer),
    ]).catch((err) => {
      console.log(err);
    });

    // Add consumer links to holding and vice versa
    await Data_Holding_AttachToConsumer_SaveConsumer(
      holding,
      consumerPersona.data.consumer_private,
      true
    );

    // If a picture was uploaded, add it to the holding
    if (product_image) {
      await updateSRVHoldingWithFiles(holding_docid, [product_image]);
    }

    // Create holding proxy
    await Holding_CreateProxy_FromHoldingDocs(
      holding,
      holding_private,
      holding_manufacturer
    );

    return holding.docid;
  };

  return { createUserSRVHolding };
}
