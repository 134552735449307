import { Button, Heading, Input } from '@rabbit/elements/shared-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  DTHolding_Manufacturer,
  DTVendable,
  ProductQuestionnaireResponses,
} from '@rabbit/data/types';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import {
  HOLDING_QUESTIONNAIRE,
  useConsumerHoldingEditor,
  useConsumerQuestionnnaire,
} from '@rabbit/bizproc/react';
import ROUTE_NAME from '../../utils/url-constants';
import { useNavigate } from 'react-router-dom';

interface RegisterHoldingProductQuestionnaireStepProps {
  onChange: (value: number) => void;
  product: DTVendable;
  holdingId: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const initialValues: ProductQuestionnaireResponses = {
  purchase_inspiration: '',
  product_to_be_used: '',
  product_to_be_used_other: '',
  purchase_reason: '',
  purchase_reason_other: '',
  product_main_use: '',
  product_main_use_other: '',
  property_shade: '',
  shelta_aware: '',
  purchase_satisfaction: '',
};

const validationSchema = Yup.object().shape({
  purchase_inspiration: Yup.string().trim(),
  product_to_be_used: Yup.string().trim(),
  product_to_be_used_other: Yup.string().trim(),
  purchase_reason: Yup.string().trim(),
  purchase_reason_other: Yup.string().trim(),
  product_main_use: Yup.string().trim(),
  product_main_use_other: Yup.string().trim(),
  property_shade: Yup.string().trim(),
  shelta_aware: Yup.string().trim(),
  purchase_satisfaction: Yup.string().trim(),
});

export function RegisterHoldingProductQuestionnaireStep({
  onChange,
  holdingId,
  product,
  setIsLoading,
}: RegisterHoldingProductQuestionnaireStepProps) {
  const navigate = useNavigate();
  const [isSubmiting, setSubmiting] = useState(false);
  const persona = useMyConsumerPersona();
  const { getSingleQuestionnaire } = useConsumerQuestionnnaire();
  const [questionnaire, setQuestionnaire] = useState<
    | {
        questionnaire: ProductQuestionnaireResponses | undefined;
        holding_manufacturer: DTHolding_Manufacturer;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    getSingleQuestionnaire(holdingId)
      .then((res) => {
        setQuestionnaire(res);
        console.log('res', res);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const { body, isReady, update, commit } = useConsumerHoldingEditor(
    persona?.data?.consumer_private?.docid ?? '',
    holdingId
  );

  const onSubmit = async (values: ProductQuestionnaireResponses) => {
    setSubmiting(true);
    setIsLoading(true);

    if (!body || !isReady || !body?.manufacturer) return;
    try {
      update({
        ...body,
        manufacturer: {
          ...body.manufacturer,
          questionnaire: values,
        },
      });

      await commit();
      toast.success('Details saved successfully!');

      navigate(
        ROUTE_NAME.PRODUCTS + '/' + body?.public?.docid + '/' + 'details'
      );
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
      setSubmiting(false);
    }
    setSubmiting(false);
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col gap-4">
      <Heading kind={'h3'}>Product information</Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values }) => (
          <Form className="font-nunito flex flex-col gap-4">
            {HOLDING_QUESTIONNAIRE.map((question, index) => {
              if (
                question.series_limitation &&
                !question.series_limitation.includes(
                  product.freeform?.customerCategory
                )
              )
                return null;
              return (
                <div key={index}>
                  <div className="mb-2 font-semibold">{question.label}</div>
                  <div className="font-nunito flex flex-col gap-1">
                    {question.options &&
                      question.options.map((option, v) => {
                        return (
                          <Input
                            key={question.name + v}
                            type="radio"
                            name={question.name}
                            settings={{ radioLabel: option, values: values }}
                          />
                        );
                      })}
                  </div>
                  {values[
                    question?.name as keyof ProductQuestionnaireResponses
                  ] === 'Other' && (
                    <div className="mt-2">
                      <Input
                        type="text"
                        name={question.name + '_other'}
                        settings={{}}
                      />
                    </div>
                  )}
                </div>
              );
            })}
            <div>
              <div className="mb-2 font-semibold"></div>
              <Input
                type="textarea"
                name="purchase_other_details"
                settings={{}}
                label="Is there anything you wish to share about this purchase?"
              />
            </div>
            <Button
              kind="primary"
              type="submit"
              disabled={Object.keys(errors).length > 0 || isSubmiting}
              loading={isSubmiting}
            >
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RegisterHoldingProductQuestionnaireStep;
