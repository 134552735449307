export interface ExpandableDescriptionProps {
  description: string;
}

export function ExpandableDescription({
  description,
}: ExpandableDescriptionProps) {
  return (
    <span
      className="font-nunito text-sm font-normal italic"
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    ></span>
  );
}

export default ExpandableDescription;
