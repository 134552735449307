import { ChevronButton, Heading } from '@rabbit/elements/shared-components';

export interface ChevronAndTitleProps {
  title: string;
  onClick?: () => any;
}

export function ChevronAndTitle({ title, onClick }: ChevronAndTitleProps) {
  return (
    <div className="relative mb-8 flex w-full flex-row items-center">
      {onClick && (
        <ChevronButton
          className="absolute flex h-full cursor-pointer flex-wrap content-center"
          kind="back"
          onClick={onClick}
        />
      )}
      <div className="mx-auto">
        <Heading kind="h2">{title}</Heading>
      </div>
    </div>
  );
}

export default ChevronAndTitle;
