import {
  AccountListItemToggle,
  Heading,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import {
  DR_Consumer_Private,
  usePortalDocumentEditor,
} from '@rabbit/data/portal';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { DRDocType } from '@rabbit/firebase/react';
import { DTConsumer_Private } from '@rabbit/data/types';

interface MarketingPreferencesContainer {
  id: string;
  data: boolean;
}

function MarketingPreferencesContainer({
  id,
  data,
}: MarketingPreferencesContainer) {
  const [toggleState, setToggleState] = useState<boolean>(data);
  const [loading, setLoading] = useState(false);
  const { body, update, commit, isReady } = usePortalDocumentEditor(
    DR_Consumer_Private as DRDocType<DTConsumer_Private>,
    id
  );

  const saveProfile = async () => {
    if (!body || !isReady) return;
    setLoading(true);
    try {
      update({
        ...body,
        marketing: !toggleState,
      });
      await commit();
      //toast.success('Marketing Preferences updated.');
      setToggleState(!toggleState);
    } catch (error) {
      console.log('error', error);
    } finally {
      window.location.reload();
    }
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 z-10 bg-white/80">
          <LoadingSpinner size="md" isFullScreen />
        </div>
      )}
      <div className="mt-[38px] rounded-lg border border-gray-200 bg-gray-50 ">
        <AccountListItemToggle
          title="Promotional Emails"
          state={toggleState}
          onChange={saveProfile}
        />
      </div>
      <div className="pt-[36.5px]">
        <Heading children="Service messages" kind="h3" />
        <p className="font-nunito pt-[10px] text-base">
          These settings are necessary for the app to function and cant be
          turned off in our system.
        </p>
      </div>
      <div className="mt-[16px] rounded-lg border border-gray-200 bg-gray-50 ">
        <AccountListItemToggle
          title="Service messages"
          state={true}
          disabled={true}
          onChange={() => {}}
        />
      </div>
    </>
  );
}

export default MarketingPreferencesContainer;
