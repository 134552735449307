import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export interface AccountListItemProps {
  title: string;
  children: any;
  navigateTo: string;
  openNewWindow: boolean;
}

export function AccountListItem({
  title,
  children,
  navigateTo,
  openNewWindow = false,
}: AccountListItemProps) {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        'flex w-full cursor-pointer items-center justify-between',
        {
          '!cursor-not-allowed opacity-40': title === 'Delete account',
        }
      )}
      onClick={
        openNewWindow
          ? () => window.open(`${navigateTo}`, '_blank')
          : () => navigate(navigateTo)
      }
    >
      <div className="font-nunito flex w-full items-center gap-6 p-[21px_0px_20px_13px]">
        <div className="h-[15px] w-[14px]">{children}</div>
        <p className="font-nunito text-base">{title}</p>
      </div>
      <div className="ml-[6px] pr-[17px]">
        <ChevronRightIcon className="h-3 w-3 cursor-pointer" />
      </div>
    </div>
  );
}

export default AccountListItem;
