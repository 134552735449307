import {
  Button,
  ChevronAndTitle,
  LoadingSpinner,
  ProductInformation,
  WarrantyInfo,
} from '@rabbit/elements/shared-components';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import ROUTE_NAME from '../../../utils/url-constants';
import { SearchVendablesByDocid } from '@rabbit/search/cherchons';
import { DTVendable } from '@rabbit/data/types';
import { getWarrantyInfo, useAppInfo } from '@rabbit/olive/utils/helpers';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '@rabbit/config/context';

export interface VendableInfoContainerProps {
  docid: string;
  onChangeStep?: (step_in: number, data?: any) => void;
  holdingId?: string | null;
}

export interface ItemProps {
  id?: string;
  title: string;
  description?: string;
  included?: string;
}

export interface InfoInterface {
  title: string;
  type: string;
  items?: ItemProps[];
  children?: any;
}

export function VendableInfoContainer({
  docid,
  onChangeStep,
  holdingId,
}: VendableInfoContainerProps) {
  const appInfo = useAppInfo();
  const [showWarrantyDetails, setShowWarrantyDetails] = useState(false);
  const [product, setProduct] = useState<DTVendable>();
  const [warrantyInfo, setWarrantyInfo] = useState<InfoInterface[]>([]);
  const [warrantyTerms, setWarrantyTerms] = useState<InfoInterface[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);
  const searchParams = new URLSearchParams(window.location.search);
  const params = useParams();

  useEffect(() => {
    getProductDetails(docid).catch((err) => console.log(err));
  }, [docid]);

  const getProductDetails = async (docid: string) => {
    const facetFilters = [];
    facetFilters.push({
      name: 'mfr',
      value: `M:${t('tenantLink')}`,
    });

    const options = {
      hitsPerPage: 25,
      page: 0,
      facetFilters,
    };
    const data = await SearchVendablesByDocid(docid, options);
    setProduct(data.items[0]);
  };

  const dynamicStyles = {
    cardBase: 'rounded-lg md:max-h-[566px]',
    cardLoggedIn: 'basis-1/2 md:shadow-xl',
    cardNoLogin: 'w-full',
  };

  useEffect(() => {
    if (window) window.localStorage.removeItem('LRP_VendableId');
    const getWarranty = async () => {
      const warranty = await getWarrantyInfo(t('tenantLink'));
      if (warranty) {
        setWarrantyInfo(warranty.warranty_info);
        setWarrantyTerms(warranty.warranty_terms);
      }
    };
    void getWarranty();
  }, [t('tenantLink')]);

  return !product ? (
    <LoadingSpinner size="sm" />
  ) : (
    <>
      {!onChangeStep && (
        <ChevronAndTitle
          title={
            searchParams.get('holdingId')
              ? t('Complete registration')
              : t('Product information')
          }
          onClick={() => navigate(-1)}
        />
      )}
      <div className="flex-row md:flex md:gap-5">
        <div
          className={classNames(dynamicStyles.cardBase, {
            [dynamicStyles.cardLoggedIn]: !onChangeStep,
            [dynamicStyles.cardNoLogin]: onChangeStep,
          })}
        >
          <div className="py-8 md:px-8">
            <div className="xxs:hidden mb-8 text-center md:block">
              {!onChangeStep && (
                <div className="font-caudex text-xl font-bold md:text-2xl">
                  Your product
                </div>
              )}
            </div>
            <ProductInformation
              imageUrl={product.img ? product.img[0] : null}
              title={product.title}
              subtitle={product.freeform?.customerCategory}
            />
            <div className="mt-11 flex flex-col">
              <Button
                children={
                  params.holdingId
                    ? t('View product')
                    : searchParams.get('holdingId')
                    ? t('Complete registration')
                    : !onChangeStep
                    ? t(`Register product`)
                    : t('Initiate claim')
                }
                kind={'primary'}
                onClick={() => {
                  if (onChangeStep) {
                    window.localStorage.setItem(
                      'NLIC_VendableId',
                      product.docid
                    );
                    window.localStorage.setItem(
                      'NLIC_VendableName',
                      product.title
                    );
                    onChangeStep(4);
                  } else {
                    searchParams.get('holdingId')
                      ? navigate(
                          ROUTE_NAME.PRODUCTS_PREVIEW +
                            '/' +
                            product.docid +
                            '/register?holdingId=' +
                            searchParams.get('holdingId')
                        )
                      : params.holdingId
                      ? navigate(
                          ROUTE_NAME.PRODUCTS +
                            '/' +
                            params.holdingId +
                            '/details'
                        )
                      : navigate(
                          ROUTE_NAME.PRODUCTS_PREVIEW +
                            '/' +
                            product.docid +
                            '/register'
                        );
                  }
                }}
              />
              {!onChangeStep && (
                <div className="mt-5 flex md:hidden ">
                  <Button
                    children={'Warranty information'}
                    kind={'outline'}
                    onClick={() => setShowWarrantyDetails(!showWarrantyDetails)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {!onChangeStep && (
          <div
            className={classNames(
              `hidden basis-1/2 overflow-y-auto rounded-lg md:!block md:max-h-[566px] md:shadow-xl`,
              {
                [`!block`]: showWarrantyDetails === true,
              }
            )}
          >
            {warrantyInfo?.length > 0 && (
              <div className="md:px-8 md:py-8">
                <div className="hidden text-center md:block">
                  <div className="font-caudex mb-6 text-xl font-bold md:text-2xl">
                    {appInfo.name === 'NuCover'
                      ? 'Care Plan Summary'
                      : 'Warranty information'}
                  </div>
                </div>
                {warrantyInfo &&
                  warrantyInfo.map((data: InfoInterface, index: number) => {
                    return <WarrantyInfo key={index} data={data} />;
                  })}
              </div>
            )}
            {warrantyTerms?.length > 0 && (
              <div className="md:px-8 md:py-8">
                <div className="hidden text-center md:block">
                  <div className="font-caudex mb-6 text-xl font-bold md:text-2xl">
                    Warranty terms
                  </div>
                </div>
                {warrantyTerms &&
                  warrantyTerms.map((data: InfoInterface, index: number) => {
                    return <WarrantyInfo key={index} data={data} />;
                  })}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
export default VendableInfoContainer;
