import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import VendableInfoContainer from '../../../components/molecules/VendableInfoContainer/VendableInfoContainer';

interface VendablePreviewViewProps {
  vendableId?: string;
  onChangeStep?: (step_in: number, data?: any) => void;
}

export function VendablePreviewView({
  vendableId,
  onChangeStep,
}: VendablePreviewViewProps) {
  const { id } = useParams();
  const [search] = useSearchParams();

  if (vendableId && search.get('holdingId')) {
    return (
      <VendableInfoContainer
        onChangeStep={onChangeStep}
        docid={vendableId}
        holdingId={search.get('holdingId')}
      />
    );
  }
  // Not logged in
  if (vendableId)
    return (
      <VendableInfoContainer onChangeStep={onChangeStep} docid={vendableId} />
    );

  // Logged in
  if (id) return <VendableInfoContainer docid={id} />;
  return null;
}

export default VendablePreviewView;
