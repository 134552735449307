import {
  Modal,
  ModalProps,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { useEffect, useState } from 'react';
import { DTConsumer_Private } from '@rabbit/data/types';
import {
  DR_Consumer_Private,
  usePortalDocumentEditor,
} from '@rabbit/data/portal';
import { Navigate } from 'react-router-dom';
import { DRDocType } from '@rabbit/firebase/react';
import { useAppInfo } from '@rabbit/olive/utils/helpers';
import { useTranslation } from 'react-i18next';

export interface ModalTermsConditionsProps {
  consumerPersonaData: DTConsumer_Private;
  consumerPersonaId: string;
}

export function ModalTermsConditions(props: ModalTermsConditionsProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const { consumerPersonaData, consumerPersonaId } = props;

  const TERMS_LAST_UPDATE_DATE = new Date('2022-09-01').getTime(); //Last update date Unix timestamp

  const { body, update, commit, isReady } = usePortalDocumentEditor(
    DR_Consumer_Private as DRDocType<DTConsumer_Private>,
    consumerPersonaId
  );

  const [display, setDisplay] = useState(
    consumerPersonaData.acceptedTerms < TERMS_LAST_UPDATE_DATE
  ); //should display or not this modal
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const [modalSettings, setModalSettings] = useState<ModalProps>(
    {} as ModalProps
  );

  // If user is being redirected to claim after NLIC. Extremely hacky, I know.
  const NLIC_RedirectTo = window.localStorage.getItem('NLIC_RedirectTo');

  const renderTenantTermsConditions = () => {
    if (t('CFG_WARRANTY_INFO_URL') !== 'CFG_WARRANTY_INFO_URL')
      return (
        '<br/><br/>By confirming, you also agree with <strong>' +
        appInfo.name +
        '</strong> <a href=' +
        t('CFG_WARRANTY_INFO_URL') +
        ' target="_blank">Terms and Conditions</a>.'
      );
    else return '';
  };

  const modalSettingsOptions = {
    warning: {
      kind: 'pop-up' as const,
      settings: {
        text:
          'In order to use iWarranty, please read and confirm that you accept our terms and conditions.' +
          renderTenantTermsConditions(),
        primaryButtonText: 'Confirm',
        outlineButtonText: 'View Terms',
        handlePrimaryClick: () => onAcceptTerms(),
        handleOutlineClick: () => onShowTerms(),
      },
    },
    terms: {
      kind: 'tc' as const,
      settings: {
        title: 'Terms and conditions',
        text: '', //hardcoded in TextModal.tsx
        primaryButtonText: 'I accept',
        handleClose: () => onShowWarning(),
        handlePrimaryClick: () => onAcceptTerms(),
      },
      className: 'w-full max-w-[729px] bg-white',
    },
  };

  const updateTerms = async () => {
    if (!body || !isReady) return;
    setLoading(true);
    document.body.style.overflow = 'auto';
    try {
      update({
        ...body,
        acceptedTerms: Math.floor(new Date().getTime()), //Today's date Unix timestamp
      });
      await commit();
      setLoading(false);
      setDisplay(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const onAcceptTerms = () => {
    setAccepted(true);
  };

  const onShowWarning = () => {
    setModalSettings(modalSettingsOptions.warning);
  };

  const onShowTerms = () => {
    setModalSettings(modalSettingsOptions.terms);
  };

  useEffect(() => {
    if (accepted) void updateTerms();
  }, [accepted]);

  useEffect(() => {
    onShowWarning();
  }, []);

  const renderTermsAndConditions = () => {
    document.body.style.overflow = 'hidden';
    return (
      <Modal
        kind={modalSettings?.kind}
        settings={modalSettings.settings}
        className={modalSettings.className}
      />
    );
  };

  return display ? (
    loading ? (
      <div className="fixed left-0 top-0 z-50 m-auto flex h-full w-full flex-wrap content-center justify-center bg-white/90">
        <LoadingSpinner size="md" isFullScreen />
      </div>
    ) : (
      renderTermsAndConditions()
    )
  ) : NLIC_RedirectTo ? (
    <Navigate to={NLIC_RedirectTo} />
  ) : null;
}

export default ModalTermsConditions;
