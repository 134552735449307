import { ButtonIcon } from '@rabbit/elements/shared-components';

export interface EmptyListCTAProps {
  copy: string;
  imgSrc: string;
  imgAlt: string;
  buttonLabel?: string;
  buttonAction?: () => any;
}

export function EmptyListCTA({
  copy,
  imgSrc,
  imgAlt,
  buttonLabel,
  buttonAction,
}: EmptyListCTAProps) {
  return (
    <div className="flex flex-col content-center">
      <img src={imgSrc} alt={imgAlt} className="mx-auto h-[174px] object-fit" />
      <div className="font-nunito mb-3 text-center text-base text-gray-400">
        {copy}
      </div>
      {buttonAction && buttonLabel && (
        <ButtonIcon label={buttonLabel} onClick={buttonAction} />
      )}
    </div>
  );
}

export default EmptyListCTA;
