import {
  AccountListItem,
  Button,
  Modal,
  Heading,
} from '@rabbit/elements/shared-components';
import {
  UserIcon,
  LockClosedIcon,
  BookOpenIcon,
  HomeIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import ROUTE_NAME from '../../utils/url-constants';
import { useContext, useState } from 'react';
import { UserContext } from '../../context/UserContext';

interface UserInfo {
  displayName: string;
  email: string;
}

interface ProfileContainer {
  userInfo: UserInfo;
}

/* eslint-disable-next-line */
export interface ProfileContainerProps {}

const userPreferencesPages = [
  {
    title: 'Personal information',
    icon: <UserIcon />,
    link: `${ROUTE_NAME.ACCOUNT_EDIT}`,
  },
  {
    title: 'Addresses',
    icon: <HomeIcon />,
    link: `${ROUTE_NAME.ACCOUNT_ADDRESSES}`,
  },
  {
    title: 'Additional info',
    icon: <InformationCircleIcon />,
    link: `${ROUTE_NAME.ACCOUNT_ADDITIONAL_INFO}`,
  },
  {
    title: 'Privacy',
    icon: <LockClosedIcon />,
    link: `${ROUTE_NAME.ACCOUNT_PRIVACY}`,
  },
];

const legalPages = [
  {
    title: 'Privacy policy',
    icon: <BookOpenIcon />,
    link: `${ROUTE_NAME.PRIVACY_POLICY}`,
  },
  {
    title: 'Terms & conditions',
    icon: <BookOpenIcon />,
    link: `${ROUTE_NAME.TERMS_AND_CONDITIONS}`,
  },
];

function ProfileContainer(props: ProfileContainerProps) {
  const [showModal, setShowModal] = useState(false);
  const { consumerPersonaData, handleSignout } = useContext(UserContext) ?? {};

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to logout from this account. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No',
      handleClose: () => setShowModal(false),
      handlePrimaryClick: handleSignout,
      handleOutlineClick: () => setShowModal(false),
    },
  };

  return (
    <div>
      {showModal && (
        <Modal kind={modalSettings.kind} settings={modalSettings.settings} />
      )}
      <Heading kind="h2" children={`${consumerPersonaData?.fullname}`} />
      <p className="font-nunito pt-4 text-base">{consumerPersonaData?.email}</p>
      <div className="pt-5">
        <Heading kind="h3" children="Account settings" />
        <div className="mt-[10px] rounded-lg border border-gray-200 bg-gray-50">
          {userPreferencesPages.map(({ title, icon, link }) => (
            <AccountListItem
              key={title}
              title={title}
              children={icon}
              navigateTo={link}
              openNewWindow={false}
            />
          ))}
        </div>
      </div>
      <div className="pt-5">
        <Heading kind="h3" children="Legal" />
        <div className="mt-[10px] rounded-lg border border-gray-200 bg-gray-50">
          {legalPages.map(({ title, icon, link }) => (
            <AccountListItem
              key={title}
              title={title}
              children={icon}
              navigateTo={link}
              openNewWindow={true}
            />
          ))}
        </div>
      </div>
      <div className="flex pt-5">
        <Button
          children="Logout"
          kind="primary"
          onClick={() => setShowModal(true)}
        />
      </div>
    </div>
  );
}

export default ProfileContainer;
