import { Button, Heading } from '@rabbit/elements/shared-components';
import {
  ArrowUpTrayIcon,
  DocumentTextIcon,
  SparklesIcon,
} from '@heroicons/react/24/solid';
import { useAppInfo } from '../../utils/helpers';

export function NLICStart(props: any) {
  const { onChange } = props;
  const appInfo = useAppInfo();

  function renderStep(text: string, icon: JSX.Element) {
    return (
      <div className="mb-5 flex flex-row items-center">
        <div className="bg-gray-100 p-2.5">{icon}</div>
        <p className="pl-[13px] text-base">{text}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-grow flex-col gap-4">
      <div className="mt-24">
        <img src={appInfo.logo} alt={appInfo.name} className="mb-2 h-12"></img>
        <div className="mb-5 flex flex-col">
          <Heading kind="h2">Register your warranty claim</Heading>
          <Heading kind="h2" color="text-primary-900">
            with {appInfo.name}
          </Heading>
        </div>
      </div>
      <div className="font-nunito mb-5 font-semibold">
        {renderStep(
          'Select your product',
          <DocumentTextIcon className="h-5 w-5" />
        )}
        {renderStep(
          'Upload proof of purchase',
          <ArrowUpTrayIcon className="h-5 w-5" />
        )}
        {renderStep('Submit your claim', <SparklesIcon className="h-5 w-5" />)}
      </div>
      <Button kind="primary" onClick={() => onChange(2)}>
        Continue
      </Button>
    </div>
  );
}

export default NLICStart;
