
import { FBF_CloudCallable } from '@rabbit/firebase/adapter';

export const nestApiGetSingleHoldingProxy = FBF_CloudCallable<
  { holdingProxyLink: string},
  any
>('api/holdingProxies/holdingProxy/:holdingProxyLink|GET');


export const nestApiGetAllHoldingProxies = FBF_CloudCallable<
  any,
  any
>('api/holdingProxies/all/|GET');


