import { ChevronAndTitle } from '@rabbit/elements/shared-components';
import VendableSearchContainer from '../../../templates/VendableSearchContainer/VendableSearchContainer';
import { useNavigate } from 'react-router-dom';

export function VendableSearchView() {
  const navigate = useNavigate();
  return (
    <>
      <ChevronAndTitle title={'Search'} onClick={() => navigate(-1)} />
      <VendableSearchContainer />
    </>
  );
}

export default VendableSearchView;
