/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable prefer-const */
import { BarcodeScanner } from 'dynamsoft-javascript-barcode';
import React, { useEffect, useRef, useState } from 'react';
import './VideoDecode.css';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { CameraIcon } from '@heroicons/react/24/solid';
import {
  LoadingSpinner,
  Modal,
  ModalProps,
} from '@rabbit/elements/shared-components';
import { useNavigate } from 'react-router-dom';
import { DTVendable } from '@rabbit/data/types';
import { SearchVendablesByUpcCode } from '@rabbit/search/cherchons';
import ROUTE_NAME from '../../utils/url-constants';
import { UserScannerStatusUpdater } from '../../utils/db-action-components';
import { checkCodeScan } from '../../utils/helpers';

// TODO: set proper types for those defined as "Any"
// TODO: Turn all styles in VideoDecode.css into tailwind styles

interface CameraShape {
  deviceId: string;
  label: string;
  _checked: boolean;
}

interface ActiveCameraShape {
  position: number;
  camera: CameraShape;
}

export interface VideoDecodeProps {
  setProductData: React.Dispatch<React.SetStateAction<DTVendable | null>>;
  userStatus: {
    consumerPersonaId: string | null | undefined;
    hasUsedScanner: boolean | undefined;
  };
}

function VideoDecode({ setProductData, userStatus }: VideoDecodeProps) {
  const navigate = useNavigate();

  const [launchStatusUpdater, setLaunchStatusUpdater] = useState(false);
  let scanner = useRef<any>();
  const scannerUIRef = useRef<HTMLDivElement>(null);

  // Modal state, possible types of modals and their settings
  const [showModal, setShowModal] = useState(false);
  const [modalSettings, setModalSettings] = useState<ModalProps>(
    {} as ModalProps
  );

  const modalSettingsOptions: any = {
    start: {
      kind: 'info' as const,
      settings: {
        title: 'Scanning your product',
        text: 'Point your camera at the product barcode or QR code to scan it and search for a match.',
        primaryButtonText: 'Got it',
        handleClose: () => setShowModal(false),
        handlePrimaryClick: async () => await handleFirstTimeUse(),
      },
    },
    searching: {
      kind: 'search' as const,
      settings: {
        text: 'Searching for your product...',
        primaryButtonText: 'Cancel',
        handleClose: () => setShowModal(false),
        handlePrimaryClick: () => setShowModal(false),
      },
    },
    noMatches: {
      kind: 'search' as const,
      settings: {
        text: "We couldn't find your product. Please try again or add your product manually.",
        primaryButtonText: 'Try again',
        outlineButtonText: 'Add manually',
        handleClose: () => window.location.reload(),
        handlePrimaryClick: () => window.location.reload(),
        handleOutlineClick: () => navigate(ROUTE_NAME.PRODUCTS_SEARCH),
      },
    },
    networkError: {
      kind: 'pop-up' as const,
      settings: {
        text: 'Network connection error. Please check your Internet connection and try again.',
        primaryButtonText: 'Continue',
        handleClose: () => navigate('/'),
        handlePrimaryClick: () => window.location.reload(),
      },
    },
    scanningError: {
      kind: 'pop-up' as const,
      settings: {
        text: 'There was an error while scanning. Please try again, making sure the barcode or QR code is in the centre of the frame.',
        primaryButtonText: 'Try again',
        outlineButtonText: 'No, cancel',
        handleClose: () => navigate('/'),
        handlePrimaryClick: () => setShowModal(false),
        handleOutlineClick: () => navigate('/'),
      },
    },
    genericError: {
      kind: 'pop-up' as const,
      settings: {
        text: 'Something went wrong, please try again.',
        primaryButtonText: 'Refresh',
        outlineButtonText: 'Go back',
        handleClose: () => navigate('/'),
        handlePrimaryClick: () => window.location.reload(),
        handleOutlineClick: () => navigate('/'),
      },
    },
  };

  const handleFirstTimeUse = async () => {
    setLaunchStatusUpdater(true);
    setShowModal(false);
  };

  // Camera switching and associated state
  const cameraSwitcherRef = useRef<HTMLDivElement>(null);
  const [activeCamera, setActiveCamera] = useState({} as ActiveCameraShape);
  const [cameraList, setCameraList] = useState([] as CameraShape[]);

  const handleChangeCamera = async () => {
    try {
      if (cameraList.length >= 2) {
        if (activeCamera.position < cameraList.length - 1) {
          scanner.current.setCurrentCamera(
            cameraList[activeCamera.position + 1]
          );

          setActiveCamera({
            position: activeCamera.position + 1,
            camera: cameraList[activeCamera.position + 1],
          });
        } else {
          scanner.current.setCurrentCamera(cameraList[0]);
          setActiveCamera({
            position: 0,
            camera: cameraList[0],
          });
        }
      }
    } catch (ex: any) {
      setModalSettings(modalSettingsOptions.genericError);
      setShowModal(true);
    }
  };

  // Handle searching for product info after detecting barcode

  const handleFindVendable = async (barcode: string) => {
    setModalSettings(modalSettingsOptions.searching);
    setShowModal(true);
    console.log('barcode', barcode);
    try {
      const searchResults = await SearchVendablesByUpcCode(barcode);
      if (searchResults.numitems > 0) {
        setProductData(searchResults.items[0]);
      } else {
        setModalSettings(modalSettingsOptions.noMatches);
      }
    } catch (err) {
      console.log(err);
      setModalSettings(modalSettingsOptions.genericError);
    }
  };

  // Scanner style settings
  const styleScanner = async () => {
    // Set scan region
    // TODO: get screen size and change region accordingly
    let runtimeSettings = await scanner.current.getRuntimeSettings();
    runtimeSettings.region = {
      regionLeft: 20,
      regionTop: 25,
      regionRight: 80,
      regionBottom: 75,
      regionMeasuredByPercentage: 1,
    };
    await scanner.current.updateRuntimeSettings(runtimeSettings);

    // Set video scanner fit to parent element
    scanner.current.setVideoFit('cover');

    // Change outline colour for scanning region & the opacity of the region not being scanned
    scanner.current.regionMaskFillStyle = 'rgba(0, 0, 0, 0.1)';
    scanner.current.regionMaskStrokeStyle = 'white';

    // Initial camera setup. We're setting the last camera in the array as the default here
    // as it seems to always be the front-facing one
    let cameras = await scanner.current.getAllCameras();
    await scanner.current.setCurrentCamera(cameras[cameras.length - 1]);
    setCameraList(cameras);
    setActiveCamera({
      position: cameras.length - 1,
      camera: cameras[cameras.length - 1],
    });
    if (cameraSwitcherRef.current) {
      if (cameras.length <= 1) {
        cameraSwitcherRef.current.style.display = 'none';
      }
    }

    // Styling found barcode highlighting
    scanner.current.barcodeFillStyle = '#ABC7C2';
    scanner.current.barcodeLineWidth = 2;
    scanner.current.barcodeStrokeStyle = '#035948';
  };

  // Init scanner on component mount
  useEffect(() => {
    let pScanner: Promise<BarcodeScanner> | null = null;

    async function initScanner() {
      try {
        scanner.current = await (pScanner = BarcodeScanner.createInstance());
        // Should judge if scanner is destroyed after 'await' in React 18 'StrictMode'.
        if (scanner.current.isContextDestroyed()) return;
        await scanner.current.setUIElement(scannerUIRef.current!);

        // Should judge if scanner is destroyed after 'await' in React 18 'StrictMode'.
        if (scanner.current.isContextDestroyed()) return;

        // Handle result finding
        scanner.current.onFrameRead = (results: any) => {
          for (let result of results) {
            console.log(result.barcodeText);
          }
        };
        scanner.current.onUniqueRead = async (txt: string, result: any) => {
          const codeResult = checkCodeScan(txt);
          if (codeResult) await handleFindVendable('9312807' + codeResult);
          else await handleFindVendable(txt);
        };

        // Style the scanner and then open it
        await styleScanner();
        await scanner.current.open();

        // Check capabilities and if possible, set autofocus and allow tap to focus
        const capabilities = await scanner.current.getCapabilities();

        if (capabilities.setFocus) await scanner.current.setFocus('continuous');
        // I have no idea if this works, but it should according to the docs.
        if (scanner.current.enableTapToFocus)
          await scanner.current.enableTapToFocus();

        scanner.current.autoFocus = true;

        if (!userStatus.hasUsedScanner) {
          setModalSettings(modalSettingsOptions.start);
          setShowModal(true);
        }
      } catch (ex: any) {
        if (ex.message.indexOf('network connection error')) {
          setModalSettings(modalSettingsOptions.networkError);
          setShowModal(true);
        }
        throw ex;
      }
    }

    void initScanner();

    return () => {
      async function unmountScanner() {
        if (pScanner) {
          (await pScanner).destroyContext();
          console.log('BarcodeScanner Component Unmount');
        }
      }
      void unmountScanner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // JSX
  return (
    <div className="absolute inset-0">
      {
        /* A somewhat convoluted way to go about this, but apparently a necessary one - update wouldn't work on Safari otherwise*/
        launchStatusUpdater && userStatus.consumerPersonaId && (
          <UserScannerStatusUpdater personaId={userStatus.consumerPersonaId} />
        )
      }
      <div className="m-auto h-screen max-h-[-webkit-fill-available] overflow-hidden lg:w-1/2 lg:py-4">
        <div ref={scannerUIRef} className="component-barcode-scanner">
          {showModal && (
            <Modal
              kind={modalSettings.kind}
              settings={modalSettings.settings}
            />
          )}
          <div className="flex h-full w-full content-center justify-center">
            <LoadingSpinner size="xl" extraClasses="m-auto" />
          </div>
          <svg className="dce-bg-camera" viewBox="0 0 2048 1792">
            <path d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"></path>
          </svg>
          <div className="dce-video-container"></div>
          <div className="dce-scanarea">
            <div className="dce-scanlight"></div>
          </div>
          <div
            ref={cameraSwitcherRef}
            onClick={() => handleChangeCamera()}
            className="ripple bg-primary-900 absolute bottom-10 right-6 cursor-pointer rounded-full p-2 text-white"
          >
            <CameraIcon className="h-6 w-6" />
          </div>
          <div
            className="absolute top-10 right-6 cursor-pointer p-2"
            onClick={() => navigate('/')}
          >
            <XCircleIcon className="h-10 w-10" />
          </div>
        </div>
      </div>
    </div>
  );
}

// Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
export default React.memo(VideoDecode, () => true);
