import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import {
  DesktopContainer,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import PersonalInfoContainer from '../../../templates/Account/PersonalInfoContainer';

function PersonalInfoView() {
  const { consumerPersonaData, consumerPersonaId } =
    useContext(UserContext) ?? {};
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  if (
    consumerPersonaData === null ||
    !consumerPersonaData ||
    !consumerPersonaId
  )
    return <LoadingSpinner size="md" isFullScreen />;

  return (
    <DesktopContainer
      title="Personal information"
      goBack={() => navigate(-1)}
      loading={isLoading}
    >
      <PersonalInfoContainer
        id={consumerPersonaId}
        data={consumerPersonaData}
        setIsLoading={setIsLoading}
      />
    </DesktopContainer>
  );
}

export default PersonalInfoView;
