import { TrashIcon, MegaphoneIcon } from '@heroicons/react/24/solid';
import { AccountListItem } from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../utils/url-constants';

function PrivacyContainer() {
  return (
    <div className="mt-[38px] rounded-lg border bg-gray-50">
      <AccountListItem
        title="Marketing preferences"
        children={<MegaphoneIcon />}
        navigateTo={`${ROUTE_NAME.ACCOUNT}/marketing`}
        openNewWindow={false}
      />
      <AccountListItem
        title="Delete account"
        children={<TrashIcon />}
        navigateTo=""
        openNewWindow={false}
      />
    </div>
  );
}

export default PrivacyContainer;
